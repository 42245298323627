import * as React from 'react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';

// import ui
import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';
import ProductService from '../../../../../services/products';

// import component
import PicturesWall from '../components/uploadFile';

// import interfaces
import { IProductResourceShort, IProductTypeResourceShort } from '../../../../../props/products';

// import config
import GeneralConfig from '../../../../../config';
import ProductImageService from '../../../../../services/products/images';
import PackagingService from '../../../../../services/packagings';
import { IPackagingResourceShort } from '../../../../../props/packagings';
import ProductTypeService from '../../../../../services/products/type';
import { IFlavourResourceShort } from '../../../../../props/flavours';
import FlavourService from '../../../../../services/flavours';
import { DataType, ProductDataType } from './panelAddProduct';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import MessageBarComponent from '../../../../../components/messageBar';
import Flavours from '../components/flavours';

interface IUpdateProductPanelProps {
  closePanel: (r?: boolean) => void;
  product: IProductResourceShort;
}

interface IUpdateProductPanelState {
  product: IProductResourceShort;
  data?: ProductDataType;
  errorData?: ProductDataType;
  files: UploadFile<any>[];
  packagings: IPackagingResourceShort[];
  loaded: boolean;
  selectedKeys: string[];
  productTypes: IProductTypeResourceShort[];
  productTypeId?: string;
  messageBar?: IRenderMessageBarProps;
  dataFlavours: DataType[];
}

export default class UpdateProductPanel extends React.Component<
  IUpdateProductPanelProps,
  IUpdateProductPanelState
> {
  constructor(props: IUpdateProductPanelProps) {
    super(props);

    this.state = {
      files: [],
      product: props.product,
      data: {
        name: props.product.name,
        description: props.product.description,
        price: props.product.price,
        minQuantity: props.product.minQuantity,
        maxQuantity: props.product.maxQuantity,
      },
      packagings: [],
      loaded: false,
      selectedKeys: props.product.packagings.map((m) => m.packaging.id),
      productTypes: [],
      productTypeId: props.product.typeId,
      dataFlavours: props.product.flavours.map((pf) => ({
        flavour: pf.flavour,
        qty: pf.maxQuantity,
        key: pf.id,
        replaceable: pf.replaceable === 1,
      })),
    };
  }

  componentDidMount() {
    this.getPackagings();
  }

  private getPackagings = async () => {
    try {
      const packagings = await PackagingService.retrieve();
      this.setState({ packagings }, this.getProductTypes);
    } catch (error) {}
  };

  private getProductTypes = async () => {
    try {
      const productTypes = await ProductTypeService.retrieve();
      this.setState({ productTypes, loaded: true });
    } catch (error) {}
  };

  public render() {
    const {
      data,
      errorData,
      product,
      packagings,
      selectedKeys,
      productTypes,
      productTypeId,
      loaded,
      messageBar,
      dataFlavours,
    } = this.state;
    return (
      <Panel
        headerText='Update Product'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <Stack styles={{ root: { marginTop: 10 } }}>
          <Label>Gambar Product</Label>
          <PicturesWall
            fileList={product.images.map((f) => ({
              uid: f.id,
              name: f.name,
              status: 'done',
              url: GeneralConfig.assetsHostname + '/' + f.url,
            }))}
            onUpdate={(files) => this.setState({ files })}
            onRemoveFile={this.onRemoveFile}
            onAddFile={this.onAddFile}
          />
        </Stack>
        <TextField
          label='Nama Produk'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={this.onChangeName}
          disabled={!loaded}
        />
        <TextField
          value={data?.description}
          errorMessage={errorData?.description}
          label='Detail / Catatan Tambahan'
          onChange={this.onChengeDescription}
          disabled={!loaded}
        />
        <Dropdown
          placeholder='Select an option'
          label='Tipe Produk'
          options={productTypes.map((p) => ({ key: p.id, text: p.name }))}
          selectedKey={productTypeId}
          onChange={this.onChangeDropdownType}
          disabled={!loaded || productTypes.length === 0}
        />
        <TextField
          value={data?.price}
          errorMessage={errorData?.price}
          label='Harga'
          onChange={this.onChengePrice}
          disabled={!loaded}
        />
        <TextField
          value={data?.minQuantity}
          errorMessage={errorData?.minQuantity}
          label='Min. Pilihan Rasa'
          onChange={this.onChangeMinQty}
          disabled={!loaded}
        />
        <TextField
          value={data?.maxQuantity}
          errorMessage={errorData?.maxQuantity}
          label='Maks. Pilihan Rasa'
          onChange={this.onChangeMaxQty}
          disabled={!loaded}
        />
        <Dropdown
          placeholder='Pilih packaging ...'
          label='Packaging yang bisa dipakai'
          options={packagings.map((p) => ({ key: p.id, text: p.name }))}
          multiSelect
          selectedKeys={selectedKeys}
          onChange={this.onChangeDropdown}
          disabled={!loaded || packagings.length === 0}
        />
        <Flavours
          onUpdateDataFlavour={this.onUpdateDataFlavour}
          maxQuantity={Number(data?.maxQuantity || '0')}
          flavoursData={dataFlavours}
        />
      </Panel>
    );
  }

  private onUpdateDataFlavour = (dataFlavours: DataType[]) => {
    this.setState({ dataFlavours });
  };

  private onChangeDropdownType = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    if (option) {
      this.setState({ productTypeId: option.key as string });
    }
  };

  private onChangeDropdown = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    const { selectedKeys } = this.state;
    this.setState({
      selectedKeys: option?.selected
        ? [...selectedKeys, option.key as string]
        : selectedKeys.filter((k) => k !== option?.key),
    });
  };

  private onAddFile = async (file: RcFile) => {
    let fd = new FormData();
    fd.append('file', file);
    try {
      await ProductImageService.create(this.props.product.id, fd);
    } catch (error) {}
  };

  private onRemoveFile = async (file: UploadFile<any>) => {
    try {
      await ProductImageService.delete(this.props.product.id, file.uid);
    } catch (error) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(error),
        },
      });
    }
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, files, errorData, selectedKeys, productTypeId, dataFlavours } = this.state;
    if (
      data?.name &&
      !errorData?.name &&
      !errorData?.price &&
      !errorData?.description &&
      data?.price &&
      Number(data.minQuantity || '0') >= dataFlavours.filter((df) => df.flavour !== undefined).length
    ) {
      const fd = new FormData();
      fd.append('name', data.name);
      fd.append('price', data.price);
      data.description && fd.append('description', data.description);
      data.minQuantity && fd.append('minQuantity', data.minQuantity);
      data.maxQuantity && fd.append('maxQuantity', data.maxQuantity);
      dataFlavours
        .filter((f) => f.flavour !== undefined)
        .map((f) =>
          fd.append(
            'flavours[]',
            JSON.stringify({ id: f.flavour?.id, qty: f.qty, replaceable: f.replaceable ? 1 : 0 })
          )
        );
      productTypeId && fd.append('typeId', productTypeId);
      selectedKeys.map((k) => fd.append('packagingsId[]', k));
      try {
        await ProductService.update(fd, this.props.product.id);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChangeMinQty = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit', 'number'], { maxChars: 6 }).message;
    this.setState({
      data: { ...this.state.data, minQuantity: v },
      errorData: { ...this.state.errorData, minQuantity: err },
    });
  };

  private onChangeMaxQty = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit', 'number'], { maxChars: 6 }).message;
    this.setState({
      data: { ...this.state.data, maxQuantity: v },
      errorData: { ...this.state.errorData, maxQuantity: err },
    });
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 1000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };

  private onChengePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    if (/^\d+$/.test(v || '')) {
      const err = validationService.combination(v, ['limit', 'number'], { maxChars: 10 }).message;
      this.setState({
        data: { ...this.state.data, price: v },
        errorData: { ...this.state.errorData, price: err },
      });
    }
  };
}
