import Request from './../request';
import { IClosureDateImagesResourceShort } from '../../props/dates';

// import interfaces

const ClosureDateImageService = {
  create: async (closuredateId: string, data: FormData): Promise<IClosureDateImagesResourceShort[]> => {
    try {
      const results = await Request.post(
        `/api/closureDates/${closuredateId}/images`,
        data,
        'multipart/form-data'
      );
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (closuredateId: string, id: string): Promise<IClosureDateImagesResourceShort> => {
    try {
      const results = await Request.delete(`/api/closureDates/${closuredateId}/images/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default ClosureDateImageService;
