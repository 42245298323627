import React from 'react';
import styles from './style.module.scss';
import { NavLink } from 'react-router-dom';

// import fabric ui components
import { Text, Stack, IBreadcrumbItem } from '@fluentui/react';
import BreadCrumb from './../breadcrumb';

interface IHeaderProps {
    title: string;
    breadcrumbs?: IBreadcrumbItem[];
}

interface IHeaderState {
    title: string;
    personaCalloutOpened: boolean;
}

export default class Header extends React.Component<IHeaderProps, IHeaderState> {
    constructor(props: IHeaderProps) {
        super(props);
        this.state = {
            title: this.props.title,
            personaCalloutOpened: false
        }
    }

    public render = () => {
        const {title, breadcrumbs} = this.props;
        return (
            <Stack className={styles.header}>
                <Text as={"h3"} variant={"xxLarge"} className={styles.title}>{this.state.title}</Text>
                {breadcrumbs && breadcrumbs.length > 0 ? <BreadCrumb items={breadcrumbs} /> : null}
            </Stack>
        );
    }
}