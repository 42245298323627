import { DefaultButton, Label, Separator, Stack, Text } from '@fluentui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import GeneralConfig from '../../../config';
import { EventType } from '../../../props/events';

import styles from './styles.module.scss';

type EventDetailsProps = {
  children?: React.ReactNode;
  event: EventType;
  onDelete: (event: EventType) => void;
  onUpdate: (event: EventType) => void;
  onUpdateImage: (event: EventType) => void;
  onPrintBarcodes: (event: EventType) => void;
};

const EventDetails: React.FC<EventDetailsProps> = (props) => {
  const [event, setEvent] = useState<EventType>(props.event);

  useEffect(() => {
    setEvent(props.event);
    return () => {};
  }, [props.event]);

  return (
    <Stack horizontal className={styles.detailsCard} tokens={{ childrenGap: 20 }}>
      <Stack styles={{ root: { width: '70%' } }}>
        <Stack.Item styles={{ root: { width: '50%' } }}>
          <Label>Nama Event</Label>
          <Text>{event.name}</Text>
        </Stack.Item>
        <Stack.Item styles={{ root: { width: '50%' } }}>
          <Label>Kode</Label>
          <Text>{event.code}</Text>
        </Stack.Item>
        <Stack.Item styles={{ root: { width: '50%' } }}>
          <Label>Tanggal</Label>
          <Text>{moment(event.date).format('DD/MM/YYYY')}</Text>
        </Stack.Item>
      </Stack>
      <Separator vertical />
      <Stack styles={{ root: { width: '30%' } }} tokens={{ childrenGap: 20 }}>
        <Stack>
          <Label>Logo</Label>
          <Stack tokens={{ childrenGap: 10 }}>
            {event.logos.map((image) => (
              <Stack key={image.id}>
                <img
                  style={{ maxWidth: 200 }}
                  src={GeneralConfig.assetsHostname + '/' + image.url}
                  alt='gambar'
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack>
          <Label>Template Kartu</Label>
          <Stack tokens={{ childrenGap: 10 }}>
            {event.cardTemplates.map((image) => (
              <Stack key={image.id}>
                <img
                  style={{ maxWidth: 200 }}
                  src={GeneralConfig.assetsHostname + '/' + image.url}
                  alt='gambar'
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
        <DefaultButton
          iconProps={{ iconName: 'Edit' }}
          text={'Ubah Detail'}
          onClick={() => {
            props.onUpdate(event);
          }}
        />
        <DefaultButton
          iconProps={{ iconName: 'Image' }}
          text={'Upload Template Kartu'}
          onClick={() => {
            props.onUpdateImage(event);
          }}
        />
        <DefaultButton
          iconProps={{ iconName: 'Barcode' }}
          text={'Print Barcode'}
          onClick={() => {
            props.onPrintBarcodes(event);
          }}
        />
        <DefaultButton
          iconProps={{ iconName: 'Delete' }}
          text={'Hapus'}
          onClick={() => {
            props.onDelete(event);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default EventDetails;
