import * as React from 'react';

import {
  DatePicker,
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import validationService from '../../../../../services/validation';
import DeliveryMethodService from '../../../../../services/deliveries';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import MessageBarComponent from '../../../../../components/messageBar';

// import services

interface IAddDeliveryMethodPanelProps {
  closePanel: (r?: boolean) => void;
  orderNumber: number;
}

export type DataInputDeliveryMethodType = {
  name?: string;
  price?: string;
  startTime?: Date;
  endTime?: Date;
};

interface IAddDeliveryMethodPanelState {
  data?: DataInputDeliveryMethodType;
  errorData?: DataInputDeliveryMethodType;
  messageBar?: IRenderMessageBarProps;
}

export default class AddDeliveryMethodPanel extends React.Component<
  IAddDeliveryMethodPanelProps,
  IAddDeliveryMethodPanelState
> {
  constructor(props: IAddDeliveryMethodPanelProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { data, errorData, messageBar } = this.state;
    return (
      <Panel
        headerText='Add Product Type'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <TextField
          label='Name'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={this.onChangeName}
        />
        <TextField
          label='Price'
          value={data?.price}
          errorMessage={errorData?.price}
          onChange={this.onChangePrice}
        />
        <DatePicker label='Start Time' value={data?.startTime} onSelectDate={this.onChangeStartTime} />
        <DatePicker label='End Time' value={data?.endTime} onSelectDate={this.onChangeEndTime} />
      </Panel>
    );
  }

  private onChangeStartTime = (date: Date | null | undefined) => {
    if (date) {
      this.setState({ data: { ...this.state.data, startTime: date } });
    }
  };

  private onChangeEndTime = (date: Date | null | undefined) => {
    if (date) {
      this.setState({ data: { ...this.state.data, endTime: date } });
    }
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, errorData } = this.state;

    if (data?.name && !errorData?.name && data.startTime && data.endTime && data.price) {
      const fd = new FormData();
      fd.append('name', data.name);
      fd.append('price', data.price);
      fd.append('startTime', data.startTime.toISOString());
      fd.append('endTime', data.endTime.toISOString());
      fd.append('orderNumber', String(this.props.orderNumber));
      try {
        await DeliveryMethodService.create(fd);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChangePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit', 'number'], { maxChars: 6 }).message;
    this.setState({
      data: { ...this.state.data, price: v },
      errorData: { ...this.state.errorData, price: err },
    });
  };
}
