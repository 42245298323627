import Request from './../request';
import { IProductImagesResourceShort, IProductTypeResourceShort } from '../../props/products';

// import interfaces

const ProductTypeService = {
  retrieve: async (): Promise<IProductTypeResourceShort[]> => {
    try {
      const results = await Request.get(`/api/productTypes`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IProductTypeResourceShort> => {
    try {
      const results = await Request.get(`/api/productTypes/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IProductTypeResourceShort[]> => {
    try {
      const results = await Request.post(`/api/productTypes`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, id: string): Promise<IProductTypeResourceShort[]> => {
    try {
      const results = await Request.post(`/api/productTypes/${id}?_method=PUT`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<IProductTypeResourceShort[]> => {
    try {
      const results = await Request.delete(`/api/productTypes/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

const ProductTypeImageService = {
  create: async (productTypeId: string, data: FormData): Promise<IProductImagesResourceShort[]> => {
    try {
      const results = await Request.post(
        `/api/productTypes/${productTypeId}/images`,
        data,
        'multipart/form-data'
      );
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (productTypeId: string, id: string): Promise<IProductImagesResourceShort> => {
    try {
      const results = await Request.delete(`/api/productTypes/${productTypeId}/images/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export { ProductTypeImageService };

export default ProductTypeService;
