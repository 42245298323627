import React, { Component } from 'react';

// import ui
import { CommandBarButton, SelectionMode, ShimmeredDetailsList, Stack, Text } from '@fluentui/react';
import moment from 'moment';

// import interface
import { IOrderResourceShort } from '../../../../../props/orders';
import { ProviderStore } from '../../../../../context';

type Props = {
  orders: IOrderResourceShort[];
  context: ProviderStore;
};

type State = {};

export default class ComplateOrder extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ShimmeredDetailsList
        selectionMode={SelectionMode.none}
        items={[]}
        enableShimmer={false}
        columns={this.columns()}
      />
    );
  }

  private columns = () => {
    return [
      {
        key: 'no',
        name: 'No',
        maxWidth: 100,
        minWidth: 100,
        isMultiline: true,
        onRender: (item: IOrderResourceShort, ix: any) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{(ix || 0) + 1}</Text>
            <CommandBarButton
              menuProps={{
                items: [
                  {
                    key: 'update',
                    text: 'Update',
                    iconProps: { iconName: 'SingleColumnEdit' },
                    onClick: () => {},
                  },
                  {
                    key: 'delete',
                    text: 'Delete',
                    iconProps: { iconName: 'Delete' },
                    onClick: () => {},
                  },
                ],
              }}
            />
          </Stack>
        ),
      },
      {
        key: 'paymentDate',
        name: 'Tanggal Pembayaran',
        maxWidth: 100,
        minWidth: 100,
        isMultiline: true,
        onRender: (item: IOrderResourceShort, ix: any) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{moment(item.paymentDate).format('DD/MM/YYYY')}</Text>
          </Stack>
        ),
      },
      {
        key: 'paymentStatus',
        name: 'PaymentStatus',
        maxWidth: 100,
        minWidth: 100,
        isMultiline: true,
        onRender: (item: IOrderResourceShort, ix: any) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{item.paymentStatus}</Text>
          </Stack>
        ),
      },
    ];
  };
}
