import * as React from 'react';

import { Upload, Button, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// import ui
import {
  DatePicker,
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';
import { IProductTypeResourceShort } from '../../../../../props/products/index';

// import services
import validationService from '../../../../../services/validation';
import FlavourService from '../../../../../services/flavours';
import ProductTypeService from '../../../../../services/products/type';
import GeneralService from '../../../../../services/general';

// import component
import MessageBarComponent from '../../../../../components/messageBar';
import LoadingComponent from '../../../../../components/loading';
import { IFlavourTagResourceShort } from '../../../../../props/flavours';
import PicturesWall from '../../../../../components/picturesWall';
import ClosureDateServices from '../../../../../services/closureDate';

interface IAddClosureDatePanelProps {
  closePanel: (rrefresh?: boolean, messageBar?: IRenderMessageBarProps) => void;
}

export type ClosureDateDataType = {
  description?: string;
  startDate?: Date;
  endDate?: Date;
};

interface IAddClosureDatePanelState {
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  data?: ClosureDateDataType;
  errorData?: {
    name?: string;
    description?: string;
  };
  calloutTarget?: number;
  fileList: UploadFile[];
}

export default class AddClosureDatePanel extends React.Component<
  IAddClosureDatePanelProps,
  IAddClosureDatePanelState
> {
  constructor(props: IAddClosureDatePanelProps) {
    super(props);

    this.state = {
      data: {},
      loaded: true,
      fileList: [],
    };
  }

  public render() {
    const { data, loaded, messageBar, errorData, calloutTarget, fileList } = this.state;
    return (
      <Panel
        headerText='Tambah Tanggal'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <Stack tokens={{ childrenGap: 10 }}>
          {messageBar ? <MessageBarComponent {...messageBar} /> : null}
          {!loaded ? (
            <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
          ) : null}
          {loaded ? (
            <>
              <Stack>
                <Upload
                  action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                  listType='picture'
                  maxCount={1}
                  onChange={this.handleChange}
                  fileList={fileList}
                >
                  <Button icon={<UploadOutlined />}>Upload Gambar</Button>
                </Upload>
              </Stack>
              <TextField
                value={data?.description}
                errorMessage={errorData?.description}
                multiline
                rows={3}
                autoAdjustHeight
                resizable={false}
                label='Penjelasan / Catatan Tambahan'
                onChange={this.onChengeDescription}
              />
              <DatePicker label='Mulai' value={data?.startDate} onSelectDate={this.onSelectStartDate} />
              <DatePicker label='Selesai' value={data?.endDate} onSelectDate={this.onSelectEndtDate} />
            </>
          ) : null}
        </Stack>
      </Panel>
    );
  }

  private handleChange = ({ fileList }: UploadChangeParam<UploadFile<any>>) => {
    this.setState({ fileList });
  };

  private onSelectStartDate = (date: Date | null | undefined) =>
    this.setState({ data: { ...this.state.data, startDate: date || undefined } });

  private onSelectEndtDate = (date: Date | null | undefined) =>
    this.setState({ data: { ...this.state.data, endDate: date || undefined } });

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, fileList } = this.state;
    const fd = new FormData();
    fileList.forEach((f: any) => fd.append('files[]', f.originFileObj));

    if (data && data.endDate && data.startDate) {
      data.description && fd.append('description', data.description);
      fd.append('startDate', data.startDate.toISOString());
      fd.append('endDate', data.endDate.toISOString());
      try {
        await ClosureDateServices.create(fd);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 2000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };
}
