import * as React from 'react';

// import ui
import {
  Callout,
  DefaultButton,
  Dropdown,
  Icon,
  IconButton,
  IDropdownOption,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import { UploadFile } from 'antd/lib/upload/interface';

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';
import { IProductTypeResourceShort } from '../../../../../props/products/index';

// import services
import validationService from '../../../../../services/validation';
import FlavourService from '../../../../../services/flavours';
import ProductTypeService from '../../../../../services/products/type';
import GeneralService from '../../../../../services/general';

// import component
import PicturesWall from '../components/uploadFile';
import MessageBarComponent from '../../../../../components/messageBar';
import LoadingComponent from '../../../../../components/loading';
import FlavourTags from '../components/tags';
import ColorOptions from '../../../../../data/colors';
import { IFlavourTagResourceShort } from '../../../../../props/flavours';

interface IAddFlavourPanelProps {
  closePanel: (rrefresh?: boolean, messageBar?: IRenderMessageBarProps) => void;
  orderNumber: number;
}

export type FlavourDataType = {
  name?: string;
  productTypes?: IProductTypeResourceShort[];
  description?: string;
  available?: boolean;
  premium?: boolean;
  vegan?: boolean;
  seasonal?: boolean;
  additionalPrice?: string;
};

interface IAddFlavourPanelState {
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  choices: {
    productTypes: IProductTypeResourceShort[];
  };
  data?: FlavourDataType;
  errorData?: FlavourDataType;
  files: UploadFile<any>[];
  calloutTarget?: number;
  tags?: IFlavourTagResourceShort[];
  submiting: boolean;
}

export default class AddFlavourPanel extends React.Component<IAddFlavourPanelProps, IAddFlavourPanelState> {
  constructor(props: IAddFlavourPanelProps) {
    super(props);

    this.state = {
      files: [],
      choices: { productTypes: [] },
      data: {
        available: true,
      },
      submiting: false,
    };
  }

  public componentDidMount = async () => {
    this.getProductTypes();
  };

  private getProductTypes = async () => {
    try {
      const productTypes = await ProductTypeService.retrieve();
      this.setState({
        choices: { productTypes },
        loaded: true,
      });
    } catch (e) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(e),
        },
      });
    }
  };

  public render() {
    const { data, loaded, choices, messageBar, errorData, calloutTarget } = this.state;
    return (
      <Panel
        headerText='Tambah Rasa'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <Stack tokens={{ childrenGap: 10 }}>
          {messageBar ? <MessageBarComponent {...messageBar} /> : null}
          {!loaded ? (
            <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
          ) : null}
          {loaded ? (
            <>
              <Stack>
                <Label>Gambar Rasa</Label>
                <PicturesWall fileList={[]} onUpdate={(files) => this.setState({ files })} />
              </Stack>
              <TextField
                label='Nama'
                value={data?.name}
                errorMessage={errorData?.name}
                onChange={this.onChangeName}
                required
              />
              <TextField
                value={data?.description}
                errorMessage={errorData?.description}
                multiline
                rows={3}
                autoAdjustHeight
                resizable={false}
                label='Penjelasan / Catatan Tambahan'
                onChange={this.onChengeDescription}
              />
              <TextField
                value={data?.additionalPrice}
                errorMessage={errorData?.additionalPrice}
                label='Harga Tambahan'
                onChange={this.onChangeAdditionalPrice}
                prefix='Rp'
              />
              <Dropdown
                multiSelect
                label={'Tipe Produk'}
                options={choices.productTypes.map((t) => {
                  return { key: t.id, text: t.name };
                })}
                selectedKeys={data?.productTypes ? data.productTypes.map((t) => t.id) : undefined}
                onChange={this.onProductTypeChanged}
              />
              <Stack>
                <Label>Tags</Label>
                <FlavourTags
                  tags={[]}
                  onUpdate={(tags: IFlavourTagResourceShort[]) => this.setState({ tags })}
                />
              </Stack>
              <Toggle
                label='Available'
                checked={data?.available}
                onChange={(e, c) => this.setState({ data: { ...data, available: c } })}
              />
              <Toggle
                label='Premium'
                checked={data?.premium}
                onChange={(e, c) => this.setState({ data: { ...data, premium: c } })}
              />
              <Toggle
                label='Vegan'
                checked={data?.vegan}
                onChange={(e, c) => this.setState({ data: { ...data, vegan: c } })}
              />
              <Toggle
                label='Seasonal'
                checked={data?.seasonal}
                onChange={(e, c) => this.setState({ data: { ...data, seasonal: c } })}
              />
            </>
          ) : null}
        </Stack>
      </Panel>
    );
  }

  private onRenderFooter = () => {
    const { submiting } = this.state;
    if (submiting) {
      return <LoadingComponent label={'Submiting'} />;
    } else {
      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <PrimaryButton text='Submit' onClick={this.onSubmit} />
          <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        </Stack>
      );
    }
  };

  private onSubmit = async () => {
    const { data, files, tags } = this.state;
    if (data?.name && files.length !== 0) {
      this.setState({ submiting: true });
      const fd = new FormData();
      files.forEach((f: any) => fd.append('files[]', f.originFileObj));
      fd.append('name', data.name);
      data.description && fd.append('description', data.description);
      data.additionalPrice && fd.append('additionalPrice', data.additionalPrice);
      data.available && fd.append('available', data.available && '1');
      data.premium && fd.append('premium', data.premium && '1');
      data.vegan && fd.append('vegan', data.vegan && '1');
      data.seasonal && fd.append('seasonal', data.seasonal && '1');
      (data.productTypes || []).forEach((productType) => {
        fd.append('productTypesId[]', productType.id);
      });
      tags && tags.map((t) => fd.append('tags[]', JSON.stringify(t)));
      fd.append('orderNumber', String(this.props.orderNumber));
      try {
        await FlavourService.create(fd);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
          submiting: false,
        });
      }
    } else {
      this.setState({
        messageBar: {
          type: MessageBarType.warning,
          text: 'Kolom nama tidak boleh kosong',
        },
      });
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChangeAdditionalPrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'number'], { maxChars: 11 }).message;
    this.setState({
      data: { ...this.state.data, additionalPrice: v },
      errorData: { ...this.state.errorData, additionalPrice: err },
    });
  };

  private onProductTypeChanged = (e: React.FormEvent<HTMLDivElement>, o: IDropdownOption | undefined) => {
    let productTypes = this.state.data?.productTypes || [];
    if (o) {
      const idx = productTypes.findIndex((t) => t.id.toLowerCase() === (o.key as string).toLowerCase());
      const v = this.state.choices.productTypes.find(
        (t) => t.id.toLowerCase() === (o.key as string).toLowerCase()
      );
      if (idx > -1) {
        productTypes.splice(idx, 1);
      } else if (v) {
        productTypes.push(v);
      }

      this.setState({ data: { ...this.state.data, productTypes } });
    }
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 1000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };
}
