import * as React from 'react';

import {
  DatePicker,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import validationService from '../../../../../services/validation';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import MessageBarComponent from '../../../../../components/messageBar';
import { DiscountType, FDiscountType, IVoucherResourceShort } from '../../../../../props/vouchers';
import VoucherService from '../../../../../services/vouchers';

// import services

interface UpdatedVoucherPanelProps {
  closePanel: (r?: boolean) => void;
  voucher: IVoucherResourceShort;
}

export type DataInputVoucherType = {
  code?: string;
  discountAmount?: string;
  discountType?: DiscountType;
  discountedItem?: FDiscountType;
};

interface UpdatedVoucherPanelState {
  data?: DataInputVoucherType;
  errorData?: DataInputVoucherType;
  messageBar?: IRenderMessageBarProps;
}

export default class UpdateVoucherPanel extends React.Component<
  UpdatedVoucherPanelProps,
  UpdatedVoucherPanelState
> {
  constructor(props: UpdatedVoucherPanelProps) {
    super(props);

    this.state = {
      data: {
        code: props.voucher.code,
        discountAmount: props.voucher.discountAmount,
        discountType: props.voucher.discountType,
        discountedItem: props.voucher.discountedItem,
      },
    };
  }

  public render() {
    const { data, messageBar } = this.state;
    return (
      <Panel
        headerText='Tambah Voucher'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <Dropdown
          selectedKey={data?.discountType}
          options={[
            { key: 'percentage', text: 'Persentase' },
            { key: 'nominal', text: 'Nominal' },
          ]}
          label='Tipe'
          onChange={this.onChangeDiscountType}
          required
        />
        <TextField
          prefix={data?.discountType === 'nominal' ? 'RP' : undefined}
          suffix={data?.discountType === 'percentage' ? '%' : undefined}
          label='Total'
          value={data?.discountAmount}
          onChange={this.onChangeAmount}
        />
        <TextField label='Kode' value={data?.code} onChange={this.onChangeCode} />
        <Dropdown
          selectedKey={data?.discountedItem}
          options={[
            { key: 'product', text: 'Produk' },
            { key: 'postage', text: 'Ongkos kirim' },
          ]}
          label='Diskon Untuk'
          required
          onChange={this.onChangeDiscountedType}
        />
      </Panel>
    );
  }

  private onChangeDiscountType = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => this.setState({ data: { ...this.state.data, discountType: option?.key as any } });

  private onChangeDiscountedType = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => this.setState({ data: { ...this.state.data, discountedItem: option?.key as any } });

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, errorData } = this.state;
    const fd = new FormData();
    data?.code && fd.append('code', data.code);
    data?.discountAmount && fd.append('discountAmount', data.discountAmount);
    data?.discountType && fd.append('discountType', data.discountType);
    data?.discountedItem && fd.append('discountedItem', data.discountedItem);
    try {
      await VoucherService.update(fd, this.props.voucher.id);
      this.props.closePanel(true);
    } catch (error) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(error),
        },
      });
    }
  };

  private onChangeCode = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 20 }).message;
    this.setState({
      data: { ...this.state.data, code: v },
      errorData: { ...this.state.errorData, code: err },
    });
  };

  private onChangeAmount = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit', 'number'], { maxChars: 11 }).message;
    this.setState({
      data: { ...this.state.data, discountAmount: v },
      errorData: { ...this.state.errorData, discountAmount: err },
    });
  };
}
