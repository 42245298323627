import Request from './../request';
import { IDeliveryMethodResourceShort } from '../../props/deliveries';

// import interfaces

const DeliveryMethodService = {
  retrieve: async (): Promise<IDeliveryMethodResourceShort[]> => {
    try {
      const results = await Request.get(`/api/deliveryMethods`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IDeliveryMethodResourceShort[]> => {
    try {
      const results = await Request.post(`/api/deliveryMethods`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, id: string): Promise<IDeliveryMethodResourceShort[]> => {
    try {
      const results = await Request.post(
        `/api/deliveryMethods/${id}?_method=PUT`,
        data,
        'multipart/form-data'
      );
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<IDeliveryMethodResourceShort[]> => {
    try {
      const results = await Request.delete(`/api/deliveryMethods/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  reorder: async (data: FormData) => {
    try {
      const result = await Request.post(`/api/reorderDeliveryMethods`, data, 'multipart/form-data');
      return result;
    } catch (error) {
      throw error;
    }
  },
};

export default DeliveryMethodService;
