import { CommandBarButton, SelectionMode, ShimmeredDetailsList, Stack, Text } from '@fluentui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GeneralConfig from '../../../../config';
import { EventFlavourType } from '../../../../props/events';

type EventFlavourListProps = {
  children?: React.ReactNode;
  flavourEvents: EventFlavourType[];
  loaded: boolean;
  onDeleteItem: (eventFlavour: EventFlavourType) => Promise<void>;
  onUpdateItem: (eventFlavour: EventFlavourType) => void;
};

const EventFlavourList: React.FC<EventFlavourListProps> = (props) => {
  const flavourEvents = props.flavourEvents;
  const [loaded, setLoaded] = useState<boolean>(props.loaded);

  useEffect(() => {
    setLoaded(props.loaded);
    return () => {};
  }, [props.loaded]);

  return (
    <ShimmeredDetailsList
      selectionMode={SelectionMode.none}
      items={flavourEvents}
      enableShimmer={!loaded}
      columns={[
        {
          key: 'no',
          name: 'No',
          maxWidth: 20,
          minWidth: 20,
          isMultiline: true,
          onRender: (item: EventFlavourType, ix) => (
            <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
              <Text>{(ix || 0) + 1}</Text>
            </Stack>
          ),
        },
        {
          key: 'images',
          name: 'Images',
          maxWidth: 300,
          minWidth: 300,
          isMultiline: true,
          onRender: (item: EventFlavourType, ix: any) => (
            <Stack
              horizontal
              styles={{ root: { width: '100%' } }}
              verticalAlign='center'
              tokens={{ childrenGap: 10 }}
              wrap
            >
              {item.images.map((i) => (
                <img
                  key={i.id}
                  src={GeneralConfig.assetsHostname + '/' + i.url}
                  style={{ height: 60, width: 60 }}
                />
              ))}
            </Stack>
          ),
        },
        {
          key: 'name',
          name: 'Name',
          maxWidth: 200,
          minWidth: 200,
          isMultiline: true,
          onRender: (item: EventFlavourType) => (
            <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
              <Text>{item.name}</Text>
              <CommandBarButton
                menuProps={{
                  items: [
                    {
                      key: 'update',
                      text: 'Update',
                      iconProps: { iconName: 'SingleColumnEdit' },
                      onClick: () => {
                        props.onUpdateItem(item);
                      },
                    },
                    {
                      key: 'delete',
                      text: 'Delete',
                      iconProps: { iconName: 'Delete' },
                      onClick: () => {
                        props.onDeleteItem(item);
                      },
                    },
                  ],
                }}
              />
            </Stack>
          ),
        },
      ]}
    />
  );
};

export default EventFlavourList;
