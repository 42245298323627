import * as React from 'react';

import {
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import UserService from '../../../../../services/auth/user';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import validationService from '../../../../../services/validation';

interface IAddUserPanelProps {
  closePanel: () => void;
}

interface IAddUserPanelState {
  data?: { username?: string; password?: string; name?: string; email?: string; address?: string };
  errorData?: { username?: string; password?: string; name?: string; email?: string; address?: string };
  messageBar?: IRenderMessageBarProps;
}

export default class AddUserPanel extends React.Component<IAddUserPanelProps, IAddUserPanelState> {
  constructor(props: IAddUserPanelProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { data, errorData } = this.state;
    return (
      <Panel
        headerText='Add User'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <TextField
          value={data?.username}
          onChange={(e, v) => {
            const validation = validationService.combination(v || '', ['required', 'limit'], {
              maxChars: 255,
            }).message;
            this.setState({
              data: { ...data, username: v },
              errorData: { ...errorData, username: validation },
            });
          }}
          label='Username'
          errorMessage={errorData?.username}
        />
        <TextField
          value={data?.password}
          onChange={(e, v) => {
            const validation = validationService.combination(v || '', ['required', 'limit'], {
              maxChars: 255,
            }).message;
            this.setState({
              data: { ...data, password: v },
              errorData: { ...errorData, password: validation },
            });
          }}
          label='Password'
          type='password'
          errorMessage={errorData?.password}
        />
        <TextField
          label='Name'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={(e, nv) => {
            const validation = validationService.combination(nv || '', ['required', 'limit'], {
              maxChars: 255,
            }).message;
            this.setState({
              data: { ...data, name: nv },
              errorData: { ...errorData, name: validation },
            });
          }}
        />
        <TextField
          label='Email'
          value={data?.email}
          errorMessage={errorData?.email}
          onChange={(e, v) => {
            const validation = validationService.combination(v || '', ['required', 'limit', 'email'], {
              maxChars: 255,
            }).message;
            this.setState({
              data: { ...data, email: v },
              errorData: { ...errorData, email: validation },
            });
          }}
        />
        <TextField
          label='Address'
          value={data?.address}
          multiline
          rows={3}
          resizable={false}
          errorMessage={errorData?.address}
          onChange={(e, v) => {
            const validation = validationService.combination(v || '', ['limit'], {
              maxChars: 255,
            }).message;
            this.setState({
              data: { ...data, address: v },
              errorData: { ...errorData, address: validation },
            });
          }}
        />
      </Panel>
    );
  }

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data } = this.state;
    if (
      data &&
      data.username &&
      data.username.trim() !== '' &&
      data.password &&
      data.password.trim() !== '' &&
      data.name &&
      data.name.trim() !== '' &&
      data.email &&
      data.email.trim() !== ''
    ) {
      const fd = new FormData();
      fd.append('username', data.username);
      fd.append('password', data.password);
      fd.append('name', data.name);
      fd.append('email', data.email);
      data.address && fd.append('address', data.address);
      try {
        await UserService.create(fd);
        this.props.closePanel();
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };
}
