import Request from './../request';
import { IPackagingImagesResourceShort } from '../../props/packagings';

// import interfaces

const PackagingImageService = {
  create: async (flavouId: string, data: FormData): Promise<IPackagingImagesResourceShort[]> => {
    try {
      const results = await Request.post(`/api/packagings/${flavouId}/images`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (flavouId: string, id: string): Promise<IPackagingImagesResourceShort> => {
    try {
      const results = await Request.delete(`/api/packagings/${flavouId}/images/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default PackagingImageService;
