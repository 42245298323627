import React, { useEffect, useState } from 'react';
import { useBarcode } from 'next-barcode';
import { Stack } from '@fluentui/react';

import styles from './styles.module.scss';

type BarcodeProps = {
  children?: React.ReactNode;
  value: string;
  options?: Options;
  barcodeWidth: string;
  barcodeHeight: string;
  top: string;
  left: string;
};

interface Options {
  format?: string;
  width?: number;
  height?: number;
  displayValue?: boolean;
  text?: string;
  fontOptions?: string;
  font?: string;
  textAlign?: string;
  textPosition?: string;
  textMargin?: number;
  fontSize?: number;
  background?: string;
  lineColor?: string;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  flat?: boolean;
  valid?: (valid: boolean) => void;
}

const Barcode: React.FC<BarcodeProps> = (props) => {

  useEffect(() => {
    return () => {}
  }, [props]);
  
  const { inputRef } = useBarcode({
    value: props.value,
    options: props.options,
  });

  return <Stack className={styles.codeContainer} styles={{root: {width: props.barcodeWidth, height: props.barcodeHeight}}}>
    <svg ref={inputRef} />
  </Stack>;
};

export default Barcode;
