import { CommandBar, PrimaryButton, SearchBox, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import EventList from '../../../../components/events/list';
import { EventType } from '../../../../props/events';
import eventServices from '../../../../services/events';
import AddEventPanel from './surfaces/addEvent';
import UpdateEventPanel from './surfaces/updateEvent';

type EventPageProps = {};

const EventPage: React.FC<EventPageProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [events, setEvents] = useState<EventType[]>([]);
  const [addEventPanel, setAddEventPanel] = useState<JSX.Element>();
  const [updateEventPanel, setUpdateEventPanel] = useState<JSX.Element>();

  useEffect(() => {
    getEvents();

    return () => {};
  }, []);

  const getEvents = async () => {
    setLoaded(false);
    try {
      const events = await eventServices.retrieve();
      setEvents(events);
      setLoaded(true);
    } catch (error) {}
  };

  const getItems = () => {
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Tambah Event'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() => {
                setAddEventPanel(
                  <AddEventPanel
                    closePanel={(refresh?: boolean | undefined) => {
                      if (refresh) {
                        getEvents();
                      }
                      setAddEventPanel(undefined);
                    }}
                  />
                );
              }}
            />
          );
        },
      },
    ];
  };

  const onDeleteItem = async (event: EventType) => {
    if (window.confirm(`Hapus ${event.name}`))
      try {
        await eventServices.delete(event.code);
        getEvents();
      } catch (error) {}
  };

  const onUpdateItem = (event: EventType) => {
    setUpdateEventPanel(
      <UpdateEventPanel
        closePanel={(refresh?: boolean | undefined) => {
          if (refresh) {
            getEvents();
          }
          setUpdateEventPanel(undefined);
        }}
        event={event}
      />
    );
  };

  return (
    <Stack>
      {addEventPanel}
      {updateEventPanel}
      <CommandBar items={getItems()} />
      <EventList events={events} loaded={loaded} onDeleteItem={onDeleteItem} onUpdateItem={onUpdateItem} />
    </Stack>
  );
};

export default EventPage;
