import { CommandBar, PrimaryButton, SearchBox, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import EventTransactionList from '../../../../components/events/transactions/list';
import Header from '../../../../components/header';
import { EventTransactionProps } from '../../../../props/events';
import eventTransactionService from '../../../../services/events/transactions';
import RequestApi from '../../../../services/request';

type EventTransactionPageProps = {
  eventTransactions?: EventTransactionProps[];
};

const EventTransactionPage: React.FC<EventTransactionPageProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(props.eventTransactions ? true : false);
  const [eventTransactions, setEventTransactions] = useState<EventTransactionProps[]>(
    props.eventTransactions ? props.eventTransactions : []
  );

  useEffect(() => {
    if (props.eventTransactions === undefined) {
      getEventTransactions();
    }

    return () => {};
  }, []);

  const getEventTransactions = async () => {
    setLoaded(false);
    try {
      const eventTransactions = await eventTransactionService.retrieve('eventId');
      setEventTransactions(eventTransactions);
      setLoaded(true);
    } catch (error) {}
  };

  const getItems = () => {
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
    ];
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      {!props.eventTransactions && (
        <Header
          title={'Daftar Transaction'}
          breadcrumbs={[
            { key: 'home', text: 'Beranda', href: '/' },
            { key: 'events', text: 'Event', href: '/events' },
            { key: 'flavours', text: `Transactions`, href: `/eventTransactions` },
          ]}
        />
      )}
      <Stack>
        <CommandBar items={getItems()} />
        <EventTransactionList
          transactions={eventTransactions}
          loaded={loaded}
          onDeleteItem={() => {}}
          onUpdateItem={() => {}}
        />
      </Stack>
    </Stack>
  );
};

export default EventTransactionPage;
