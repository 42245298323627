import { Checkbox, PrimaryButton, Spinner, Stack, Text, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Barcode from '../../../../components/barcode';
import Header from '../../../../components/header';
import LoadingComponent from '../../../../components/loading';
import { EventCodeType } from '../../../../props/events';
import eventServices from '../../../../services/events';
import GeneralService from '../../../../services/general';
import urls from './../../../../configs/urls.json';
import styles from './styles.module.scss';
import PrintBarcodePanel from '../../../../components/printBarcode';

type PrintBarcodesPageProps = {
  children?: React.ReactNode;
};

type DataType = {
  name: string;
  items: EventCodeType[];
  checked: boolean;
};

const PrintBarcodesPage: React.FC<PrintBarcodesPageProps> = (props) => {
  const eventId = window.location.pathname.split('/')[2];

  const [data, setData] = useState<DataType[]>([]);
  // const [showCodes, setShowCodes] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [templateUrl, setTemplateUrl] = useState<string | undefined>();
  const [cardWidth, setCardWidth] = useState<string>('200');
  const [barcodeWidth, setBarcodeWidth] = useState<string>('100');
  const [barcodeHeight, setBarcodeHeight] = useState<string>('');
  const [barcodeXPos, setBarcodeXPos] = useState<string>('');
  const [barcodeYPos, setBarcodeYPos] = useState<string>('');
  const [panelShowHideCodes, setPanelShowHideCodes] = useState<JSX.Element>();

  useEffect(() => {
    getCodes();
    return () => {};
  }, []);

  const getCodes = async () => {
    setLoaded(false);
    if (eventId) {
      try {
        const codes = await eventServices.getCodes(eventId || '');
        const event = await eventServices.get(eventId);
        const formattedData = separateDataByName(codes);

        const data = Object.entries(formattedData).map((d) => ({ name: d[0], items: d[1], checked: true }));
        setData(data);
        if (event.cardTemplates[0]) {
          setTemplateUrl(GeneralService.getFileUrl(event.cardTemplates[0].url));
        }
        setLoaded(true);
      } catch (error) {}
    }
  };

  const onChangeCheckbox = (
    index: number,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    const items = [...data];
    items[index].checked = checked ?? false;
    setData(items);
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      {panelShowHideCodes}
      <Header
        title={'Barcode'}
        breadcrumbs={[
          { key: 'home', text: 'Beranda', href: '/' },
          { key: 'eventId', text: 'Barcode', href: urls.home.printBarcodes.index },
        ]}
      />
      {loaded && (
        <Stack horizontal>
          <PrimaryButton
            text='Lihat / Sembunyikan'
            onClick={() => {
              setPanelShowHideCodes(
                <PrintBarcodePanel
                  closePanel={function (r?: boolean | undefined): void {
                    setPanelShowHideCodes(undefined);
                  }}
                  data={data}
                  onUpdateItems={(im) => setData(im)}
                />
              );
            }}
          />
        </Stack>
      )}
      {!loaded && <LoadingComponent label={'Mengambil data'} />}
      {loaded && (
        <Stack horizontal tokens={{ childrenGap: 20 }} className={'no-print'}>
          <TextField label={'Card width'} value={cardWidth} onChange={(e, v) => setCardWidth(v || '')} />
          <TextField
            label={'Barcode width'}
            value={barcodeWidth}
            onChange={(e, v) => setBarcodeWidth(v || '')}
          />
          <TextField
            label={'Barcode height'}
            value={barcodeHeight}
            onChange={(e, v) => setBarcodeHeight(v || '')}
          />
          <TextField
            label={'Barcode left position'}
            value={barcodeXPos}
            onChange={(e, v) => setBarcodeXPos(v || '')}
          />
          <TextField
            label={'Barcode top position'}
            value={barcodeYPos}
            onChange={(e, v) => setBarcodeYPos(v || '')}
          />
        </Stack>
      )}

      {loaded && (
        <Stack tokens={{ childrenGap: 20 }}>
          <Text variant={'large'}>Generated Barcodes</Text>
          {/* {data && (
            <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
              {data.map((item, ix) => (
                <Stack key={ix} className={styles.tag}>
                  <Checkbox
                    label={`${item.name} (${item.items.length} codes)`}
                    checked={item.checked}
                    onChange={(ev, checked) => onChangeCheckbox(ix, ev, checked)}
                  />
                </Stack>
              ))}
            </Stack>
          )} */}
          <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
            {data
              .filter((f) => f.checked)
              .map((item) =>
                item.items.map((code) => (
                  <Stack key={code.id} styles={{ root: { position: 'relative' } }}>
                    {templateUrl ? <img src={templateUrl} style={{ width: `${cardWidth}px` }} /> : null}
                    <Stack
                      key={code.id}
                      styles={{
                        root: { position: 'absolute', left: `${barcodeXPos}px`, top: `${barcodeYPos}px` },
                      }}
                    >
                      <Barcode
                        value={code.name}
                        barcodeWidth={`${barcodeWidth}px`}
                        barcodeHeight={`${barcodeHeight}px`}
                        left={`${barcodeXPos}px`}
                        top={`${barcodeYPos}px`}
                        options={{ background: 'transparent', displayValue: false }}
                      />
                    </Stack>
                  </Stack>
                ))
              )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default PrintBarcodesPage;

function separateDataByName(items: EventCodeType[]): { [key: string]: EventCodeType[] } {
  const separatedData: { [key: string]: EventCodeType[] } = {};

  for (const item of items) {
    const { name } = item;

    if (!separatedData[name]) {
      separatedData[name] = [];
    }
    separatedData[name].push(item);
  }
  return separatedData;
}
