import * as React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';

// import ui
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, Text } from '@fluentui/react';

// import interfaces

// import services

// import component
import LoadingComponent from '../../../../../components/loading';
import GeneralConfig from '../../../../../config';

import styles from './styles.module.scss';
import { IDeliveryMethodResourceShort } from '../../../../../props/deliveries';
import DeliveryMethodService from '../../../../../services/deliveries';

interface ISortDeliveyMethodsPanelProps {
  closePanel: (refresh?: boolean) => void;
}

interface ISortDeliveyMethodsPanelState {
  submiting: boolean;
  loaded: boolean;
  deliveryMethods: IDeliveryMethodResourceShort[];
  updateDeliveyMethods: IDeliveryMethodResourceShort[];
}

export default class SortDeliveyMethodsPanel extends React.Component<
  ISortDeliveyMethodsPanelProps,
  ISortDeliveyMethodsPanelState
> {
  constructor(props: ISortDeliveyMethodsPanelProps) {
    super(props);

    this.state = {
      submiting: false,
      loaded: false,
      deliveryMethods: [],
      updateDeliveyMethods: [],
    };
  }

  componentDidMount() {
    this.getDeliveyMethods();
  }

  private getDeliveyMethods = async () => {
    this.setState({ loaded: false });
    try {
      const deliveryMethods = await DeliveryMethodService.retrieve();
      this.setState({ loaded: true, deliveryMethods });
    } catch (error) {
      this.setState({ loaded: false });
    }
  };

  public render() {
    const { loaded, submiting, deliveryMethods } = this.state;
    return (
      <Panel
        headerText='Ubah Urutan DeliveryMethod'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {loaded && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <section {...provided.droppableProps} ref={provided.innerRef}>
                  {deliveryMethods.map((f, index) => (
                    <Draggable key={f.id} draggableId={f.id} index={index}>
                      {(provided, snapshot) => (
                        <section
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            position: 'static',
                            background: '#ffffff',
                          }}
                          className={styles.columnItem}
                        >
                          <Stack
                            key={f.id}
                            className={styles.item}
                            horizontal
                            verticalAlign='center'
                            tokens={{ childrenGap: 10 }}
                          >
                            <Text>{f.name}</Text>
                          </Stack>
                        </section>
                      )}
                    </Draggable>
                  ))}
                </section>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Panel>
    );
  }

  private onSubmit = async () => {
    const { updateDeliveyMethods } = this.state;

    const data = updateDeliveyMethods.map((u) => ({ ...u, orderNumber: String(u.orderNumber) }));
    const fd = new FormData();
    this.setState({ submiting: true });
    try {
      data.map((d) => fd.append('deliveryMethods[]', JSON.stringify(d)));
      await DeliveryMethodService.reorder(fd);
      this.setState({ submiting: false });
      this.props.closePanel(true);
    } catch (error) {
      this.setState({ submiting: false });
    }
  };

  private onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { deliveryMethods: items } = this.state;
    let newNumber: number = 0;

    const lastIndex = items.length - 1;
    if (result.destination) {
      const to = result.destination.index;
      const from = result.source.index;

      if (Number(items[from].orderNumber) > Number(items[to].orderNumber)) {
        if (to - 1 !== lastIndex) {
          if (items[to - 1]) {
            newNumber = (Number(items[to].orderNumber) + Number(items[to - 1].orderNumber)) / 2;
          } else {
            newNumber = Number(items[to].orderNumber) / 2;
          }
        } else {
          newNumber = Number(items[to].orderNumber) - 1;
        }
      } else {
        if (items[to + 1]) {
          newNumber = (Number(items[to].orderNumber) + Number(items[to + 1].orderNumber)) / 2;
        } else {
          newNumber = Number(items[to].orderNumber) + 1;
        }
      }

      Number.isNaN(newNumber) && (newNumber = Math.floor(Math.random() * 10) + 1);

      this.updateColumns(items, from, to, newNumber);

      this.updateOrderNumber(newNumber, items[from]);
    }
  };

  private updateOrderNumber = async (orderNumber: number, deliveryMethod: IDeliveryMethodResourceShort) => {
    const { updateDeliveyMethods } = this.state;
    const findDeliveyMethods = updateDeliveyMethods.findIndex((f) => f.id === deliveryMethod.id);
    if (updateDeliveyMethods[findDeliveyMethods]) {
      updateDeliveyMethods[findDeliveyMethods].orderNumber = String(orderNumber);
    } else {
      updateDeliveyMethods.push(deliveryMethod);
    }

    this.setState({ updateDeliveyMethods });
  };

  private onRenderFooter = () => {
    if (!this.state.submiting) {
      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          {this.state.updateDeliveyMethods.length !== 0 && (
            <PrimaryButton text='Save' onClick={this.onSubmit} />
          )}
          <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        </Stack>
      );
    } else {
      return <LoadingComponent label={'Mengubah Urutan DeliveryMethod'} />;
    }
  };

  private updateColumns = (
    deliveryMethods: IDeliveryMethodResourceShort[],
    from: number,
    to: number,
    newOrderNumber: number
  ) => {
    deliveryMethods[from].orderNumber = String(newOrderNumber);
    this.setState({ deliveryMethods: this._reorder(deliveryMethods, from, to) });
  };

  private _reorder = (list: IDeliveryMethodResourceShort[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
}
