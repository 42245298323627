import { Component } from 'react';

// import styles
import styles from '../styles.module.scss';

// import interfaces
import { IUserResourceShortProps } from '../../../../../props/user';
import { IconButton, Label, MessageBar, MessageBarType, PrimaryButton, Stack, Text } from '@fluentui/react';
import PanelUpdateEmail from '../surfaces/updateEmail';
import UserService from '../../../../../services/auth/user';
import { IMessageBarContentProps } from '../../../../../props/general';

type Props = {
  user: IUserResourceShortProps;
};

type State = {
  user: IUserResourceShortProps;
  panelUpdateEmail?: JSX.Element;
  otpId?: string;
  messageBar?: IMessageBarContentProps;
};

export default class UpdateEmail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: props.user,
    };
  }

  render() {
    const { user, panelUpdateEmail, otpId } = this.state;
    return (
      <Stack className={styles.card} tokens={{ childrenGap: 10 }}>
        {this.state.messageBar ? (
          <Stack styles={{ root: { marginTop: 20 } }}>
            <MessageBar
              messageBarType={this.state.messageBar.type}
              isMultiline={true}
              onDismiss={() => {
                this.setState({ messageBar: undefined });
              }}
            >
              {this.state.messageBar.content}
            </MessageBar>
          </Stack>
        ) : null}
        {panelUpdateEmail}
        <Stack horizontal wrap verticalAlign='center' horizontalAlign='space-between'>
          <Label>Email:</Label>
          <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign='center'>
            <Text>{user.details?.email || '-'}</Text>
            <IconButton
              iconProps={{ iconName: 'Edit' }}
              onClick={() =>
                this.setState({
                  panelUpdateEmail: (
                    <PanelUpdateEmail
                      onDismiss={() => {
                        this.setState({ panelUpdateEmail: undefined });
                      }}
                    />
                  ),
                })
              }
            />
          </Stack>
        </Stack>
        {user.details?.email && user.details?.emailVerifiedAt === null && (
          <Stack>
            <PrimaryButton text='Vertifikasi Email' onClick={this.onSubmit} />
          </Stack>
        )}
        {otpId && (
          <PanelUpdateEmail
            otpId={otpId}
            onDismiss={(user?: IUserResourceShortProps) => {
              if (user) {
                this.setState({ user });
              }
              this.setState({ otpId: undefined });
            }}
          />
        )}
      </Stack>
    );
  }

  private onSubmit = async () => {
    const { user } = this.state;
    const fd = new FormData();
    if (user.details?.email) {
      fd.append('email', user.details?.email);
      try {
        const res = await UserService.updateEmail(fd);
        this.setState({ otpId: res.otpId });
      } catch (error) {
        this.setState({
          messageBar: {
            content: <Text variant={'small'}>{(error as any).message}</Text>,
            type: MessageBarType.error,
          },
        });
      }
    }
  };
}
