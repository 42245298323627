import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  DefaultButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services
import ProductService from '../../../../services/products';

// import interfaces
import { IProductResourceShort } from '../../../../props/products';

// import component
import AddProductPanel from './surfaces/panelAddProduct';
import UpdateProductPanel from './surfaces/panelUpdateProduct';
import GeneralConfig from '../../../../config';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';
import SortProductPanel from './surfaces/panelSortProduct';

interface IProductsPageProps {
  context: ProviderStore;
}

interface IProductsPageState {
  products: IProductResourceShort[];
  loaded: boolean;
  addProductDialog?: JSX.Element;
  updateProductDialog?: JSX.Element;
  sortProductPanel?: JSX.Element;
}

export default class ProductsPage extends React.Component<IProductsPageProps, IProductsPageState> {
  constructor(props: IProductsPageProps) {
    super(props);

    this.state = {
      products: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  private getProducts = async () => {
    this.setState({ loaded: false });
    try {
      const products = await ProductService.retrieve();
      this.setState({ products, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { products, loaded, addProductDialog, updateProductDialog, sortProductPanel } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addProductDialog}
        {updateProductDialog}
        {sortProductPanel}
        <Text variant={'xLarge'}>Products</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={products}
          enableShimmer={!loaded}
          columns={this.columns()}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={
                !loaded ||
                !checkPermission(this.props.context.globalState.myPermissionKeys, ['create_product'])
              }
              iconProps={{ iconName: 'Add' }}
              text='Tambah Produk'
              styles={{ root: { marginLeft: 10 } }}
              onClick={this.showDialog}
            />
          );
        },
      },
      {
        key: 'sort_products',
        text: 'Sort Products',
        onRender: () => {
          return (
            <DefaultButton
              disabled={!loaded}
              iconProps={{ iconName: 'ArrowDownUp' }}
              text='Ubah Urutan Product'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() => {
                this.setState({
                  sortProductPanel: (
                    <SortProductPanel
                      closePanel={(r) => {
                        this.setState({ sortProductPanel: undefined });
                        if (r) {
                          this.getProducts();
                        }
                      }}
                    />
                  ),
                });
              }}
            />
          );
        },
      },
    ];
  };

  private showDialog = () => {
    const { products } = this.state;
    const orderNumber =
      products.length !== 0 ? Math.max(...products.map((p) => Number(p.orderNumber))) + 1 : 1;
    this.setState({
      addProductDialog: (
        <AddProductPanel
          closePanel={(r) => {
            this.setState({ addProductDialog: undefined });
            if (r) {
              this.getProducts();
            }
          }}
          orderNumber={orderNumber}
        />
      ),
    });
  };

  private deleteProduct = async (item: IProductResourceShort) => {
    if (window.confirm('Delete Product')) {
      try {
        await ProductService.delete(item.id);
        this.getProducts();
      } catch (error) {}
    }
  };

  private columns = () => {
    return [
      {
        key: 'no',
        name: 'No',
        maxWidth: 20,
        minWidth: 20,
        isMultiline: true,
        onRender: (item: IProductResourceShort, ix: any) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{(ix || 0) + 1}</Text>
          </Stack>
        ),
      },
      {
        key: 'image',
        name: 'Image',
        maxWidth: 300,
        minWidth: 300,
        isMultiline: true,
        onRender: (item: IProductResourceShort, ix: any) => (
          <Stack
            horizontal
            styles={{ root: { width: '100%' } }}
            verticalAlign='center'
            tokens={{ childrenGap: 10 }}
            wrap
          >
            {item.images.map((i) => (
              <img
                key={i.id}
                src={GeneralConfig.assetsHostname + '/' + i.url}
                style={{ height: 60, width: 60 }}
              />
            ))}
          </Stack>
        ),
      },
      {
        key: 'name',
        name: 'Name',
        maxWidth: 200,
        minWidth: 200,
        isMultiline: true,
        onRender: (item: IProductResourceShort) => (
          <Stack
            horizontal
            styles={{ root: { width: '100%' } }}
            verticalAlign='center'
            horizontalAlign='space-between'
          >
            <Text>{item.name}</Text>
            <CommandBarButton
              menuProps={{
                items: [
                  {
                    key: 'update',
                    text: 'Update',
                    iconProps: { iconName: 'SingleColumnEdit' },
                    onClick: () => {
                      this.setState({
                        updateProductDialog: (
                          <UpdateProductPanel
                            closePanel={(r) => {
                              this.setState({ updateProductDialog: undefined });
                              if (r) {
                                this.getProducts();
                              }
                            }}
                            product={item}
                          />
                        ),
                      });
                    },
                    disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                      'update_product',
                    ]),
                  },
                  {
                    key: 'delete',
                    text: 'Delete',
                    iconProps: { iconName: 'Delete' },
                    onClick: () => {
                      this.deleteProduct(item);
                    },
                    disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                      'delete_product',
                    ]),
                  },
                ],
              }}
            />
          </Stack>
        ),
      },
      {
        key: 'minQuantity',
        name: 'Min Quantity',
        maxWidth: 200,
        minWidth: 200,
        isMultiline: true,
        onRender: (item: IProductResourceShort) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{item.minQuantity}</Text>
          </Stack>
        ),
      },
      {
        key: 'maxQuantity',
        name: 'Max Quantity',
        maxWidth: 200,
        minWidth: 200,
        isMultiline: true,
        onRender: (item: IProductResourceShort) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{item.maxQuantity}</Text>
          </Stack>
        ),
      },
      {
        key: 'description',
        name: 'Description',
        maxWidth: 200,
        minWidth: 200,
        isMultiline: true,
        onRender: (item: IProductResourceShort) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{item.description}</Text>
          </Stack>
        ),
      },
      {
        key: 'type',
        name: 'Type',
        maxWidth: 200,
        minWidth: 200,
        isMultiline: true,
        onRender: (item: IProductResourceShort) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{item.type?.name}</Text>
          </Stack>
        ),
      },
    ];
  };
}
