const ColorOptions = [
  { key: '#a19f9d', text: '#a19f9d', name: 'neutral' },
  { key: '#8a8886', text: '#8a8886', name: 'neutralDark' },
  { key: '#605e5c', text: '#605e5c', name: 'neutralDarker' },
  { key: '#107c10', text: '#107c10', name: 'green' },
  { key: '#004b1c', text: '#004b1c', name: 'greenDark' },
  { key: '#00B294', text: '#00B294', name: 'tealLight' },
  { key: '#008272', text: '#008272', name: 'teal' },
  { key: '#004b50', text: '#004b50', name: 'tealDark' },
  { key: '#0078d4', text: '#0078d4', name: 'blue' },
  { key: '#00188f', text: '#00188f', name: 'blueDark' },
  { key: '#002050', text: '#002050', name: 'blueDarker' },
  { key: '#5c2d91', text: '#5c2d91', name: 'purple' },
  { key: '#32145a', text: '#32145a', name: 'purpleDark' },
  { key: '#e3008c', text: '#e3008c', name: 'magentaLight' },
  { key: '#b4009e', text: '#b4009e', name: 'magenta' },
  { key: '#5c005c', text: '#5c005c', name: 'magentaDark' },
  { key: '#d13438', text: '#d13438', name: 'red' },
  { key: '#a4262c', text: '#a4262c', name: 'redDark' },
  { key: '#ea4300', text: '#ea4300', name: 'orangeLight' },
  { key: '#d83b01', text: '#d83b01', name: 'orange' },
  { key: '#ffb900', text: '#ffb900', name: 'yellow' },
  { key: '#d29200', text: '#d29200', name: 'yellowDark' },
];

export default ColorOptions;
