import { initializeIcons, registerIcons, loadTheme } from '@fluentui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthPage from './pages/auth/subPages';
import 'antd/dist/antd.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import component
import HomePage from './pages/home';

import './styles/global.scss';
import './styles/fluentuioverride.scss';
import Provider, { Consumer } from './context';
import {
  faArrowDownArrowUp,
  faCartShopping,
  faChevronLeft,
  faHouse,
  faInfo,
  faKey,
  faMapPin,
  faMemoCircleInfo,
  faMinusCircle,
  faPenSquare,
  faPlusCircle,
  faRotate,
  faStar,
  faTrash,
  faUser,
  faCheck,
} from '@fortawesome/pro-light-svg-icons';
import PortalPage from './pages/portal';
import { useEffect } from 'react';
import { getNotificationPermission } from './utils/firebaseApp';

initializeIcons();

registerIcons({
  icons: {
    ShoppingCart: <FontAwesomeIcon icon={faCartShopping} />,
    ChevronLeft: <FontAwesomeIcon icon={faChevronLeft} />,
    PlusCircle: <FontAwesomeIcon icon={faPlusCircle} />,
    MinusCircle: <FontAwesomeIcon icon={faMinusCircle} />,
    StarIcon: <FontAwesomeIcon icon={faStar} />,
    Info: <FontAwesomeIcon icon={faInfo} />,
    Edit: <FontAwesomeIcon icon={faPenSquare} />,
    HomeIcon: <FontAwesomeIcon icon={faHouse} />,
    PinMap: <FontAwesomeIcon icon={faMapPin} />,
    KeyIcon: <FontAwesomeIcon icon={faKey} />,
    User: <FontAwesomeIcon icon={faUser} />,
    TrashIcon: <FontAwesomeIcon icon={faTrash} />,
    Details: <FontAwesomeIcon icon={faMemoCircleInfo} />,
    Rotate: <FontAwesomeIcon icon={faRotate} />,
    ArrowDownUp: <FontAwesomeIcon icon={faArrowDownArrowUp} />,
    Check: <FontAwesomeIcon icon={faCheck} />,
  },
});

// update theme
loadTheme({
  palette: {
    themePrimary: '#3e5526',
    themeLighterAlt: '#f6f8f3',
    themeLighter: '#dae4d0',
    themeLight: '#bbccaa',
    themeTertiary: '#809866',
    themeSecondary: '#506936',
    themeDarkAlt: '#384c22',
    themeDark: '#2f401d',
    themeDarker: '#232f15',
    neutralLighterAlt: '#f8f5ec',
    neutralLighter: '#f4f1e8',
    neutralLight: '#eae8de',
    neutralQuaternaryAlt: '#dad8cf',
    neutralQuaternary: '#d0cec6',
    neutralTertiaryAlt: '#c8c6be',
    neutralTertiary: '#bab8b7',
    neutralSecondary: '#a3a2a0',
    neutralPrimaryAlt: '#8d8b8a',
    neutralPrimary: '#323130',
    neutralDark: '#605e5d',
    black: '#494847',
    white: '#fff',
  },
});

// declare global {
//   interface Window {
//     Pusher: any;
//   }
// }

// window.Pusher = Pusher;

// export const echo = new Echo({
//   broadcaster: 'pusher',
//   key: '829d093bed46d7d7dd09',
//   wsHost: 'http://localhost',
//   wsPort: '6001',
//   wssPort: '6001',
//   disableStats: true,
//   encrypted: true,
//   forceTLS: true,
//   enabledTransports: ['ws', 'wss'],
// });

// echo.channel('my-channel').listen('MyEvent', (data: any) => {
//   console.log(data);
// });

type TypeAppProps = {
  children?: React.ReactNode;
};

const App: React.FC<TypeAppProps> = (props) => {
  useEffect(() => {
    getNotificationPermission();

    return () => { };
  }, []);

  return (
    <Provider>
      <Router>
        <Routes>
          <Route path='/*' element={<Consumer>{(context) => <HomePage context={context} />}</Consumer>} />
          <Route path='/auth/*' element={<AuthPage />} />
          <Route path='/portal/*' element={<PortalPage />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
