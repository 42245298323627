import * as React from 'react';

import {
  DatePicker,
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';
import GeneralService from '../../../../../services/general';

// import component
import { UploadFile } from 'antd/lib/upload/interface';
import MessageBarComponent from '../../../../../components/messageBar';
import PicturesWall from '../../../../../components/picturesWall';

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';
import ExpensesService from '../../../../../services/expenses';
import moment from 'moment';

interface IAddExpensesPanelProps {
  closePanel: (r?: boolean) => void;
}

export type ProductDataType = {
  name?: string;
  description?: string;
  amount?: string;
  date?: Date;
};

interface IAddExpensesPanelState {
  data?: ProductDataType;
  errorData?: ProductDataType;
  files: UploadFile<any>[];
  loaded: boolean;
  messageBar?: IRenderMessageBarProps;
}

export default class AddExpensesPanel extends React.Component<
  IAddExpensesPanelProps,
  IAddExpensesPanelState
> {
  constructor(props: IAddExpensesPanelProps) {
    super(props);

    this.state = {
      files: [],
      loaded: true,
    };
  }

  public render() {
    const { data, errorData, loaded, messageBar } = this.state;
    return (
      <Panel
        headerText='Tambah Expenses'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack>
            <Label>Gambar</Label>
            <PicturesWall fileList={[]} onUpdate={(files) => this.setState({ files })} />
          </Stack>
          <TextField
            label='Nama'
            value={data?.name}
            errorMessage={errorData?.name}
            onChange={this.onChangeName}
            disabled={!loaded}
          />
          <TextField
            value={data?.description}
            errorMessage={errorData?.description}
            label='Detail / Catatan Tambahan'
            onChange={this.onChengeDescription}
            disabled={!loaded}
          />
          <TextField
            value={data?.amount}
            errorMessage={errorData?.amount}
            label='Total'
            prefix={'Rp'}
            onChange={this.onChengePrice}
            disabled={!loaded}
          />
          <DatePicker
            label='Tanggal'
            value={data?.date}
            onSelectDate={this.onChangeDate}
            formatDate={(d) => moment(d).format('DD/MM/YYYY')}
          />
        </Stack>
      </Panel>
    );
  }

  private onChangeDate = (date: Date | null | undefined) => {
    if (date) {
      this.setState({ data: { ...this.state.data, date: date } });
    }
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, files, errorData } = this.state;
    if (
      data?.name &&
      data?.name.trim() !== '' &&
      !errorData?.name &&
      !errorData?.amount &&
      !errorData?.description &&
      data.amount &&
      data.amount.trim() !== '' &&
      data.date
    ) {
      const fd = new FormData();
      fd.append('name', data.name);
      fd.append('amount', data.amount);
      fd.append('date', data.date.toISOString());
      data.description && fd.append('description', data.description);
      files.map((f) => f.originFileObj && fd.append('files[]', f?.originFileObj));
      try {
        await ExpensesService.create(fd);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 1000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };

  private onChengePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'number'], { maxChars: 11 }).message;
    this.setState({
      data: { ...this.state.data, amount: v },
      errorData: { ...this.state.errorData, amount: err },
    });
  };
}
