import * as React from 'react';

import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  MessageBarType,
  Label,
} from '@fluentui/react';
import validationService from '../../../../../services/validation';
import ProductTypeService from '../../../../../services/products/type';
import { IProductTypeResourceShort } from '../../../../../props/products';
import LoadingComponent from '../../../../../components/loading';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import MessageBarComponent from '../../../../../components/messageBar';
import PicturesWall from '../components/uploadFile';
import { UploadFile } from 'antd/lib/upload/interface';

// import services

interface IAddProductTypePanelProps {
  closePanel: (refresh?: boolean, messageBar?: IRenderMessageBarProps) => void;
}

interface IAddProductTypePanelState {
  submitting?: boolean;
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  data: Partial<IProductTypeResourceShort>;
  errorData?: {
    name?: string;
  };
  files: UploadFile<any>[];
}

export default class AddProductTypePanel extends React.Component<
  IAddProductTypePanelProps,
  IAddProductTypePanelState
> {
  constructor(props: IAddProductTypePanelProps) {
    super(props);

    this.state = {
      loaded: true,
      data: {
        name: '',
      },
      files: [],
    };
  }

  public render() {
    const { data, messageBar, loaded, errorData, submitting } = this.state;
    return (
      <Panel
        headerText='Tambah Tipe Produk'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <Stack tokens={{ childrenGap: 10 }}>
          {messageBar ? <MessageBarComponent {...messageBar} /> : null}
          {!loaded ? (
            <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
          ) : null}
          {loaded ? (
            <>
              <Stack>
                <Label>Gambar Tipe Produk</Label>
                <PicturesWall fileList={[]} onUpdate={(files) => this.setState({ files })} />
              </Stack>
              <TextField
                required
                disabled={submitting}
                label='Name'
                value={data?.name}
                errorMessage={errorData?.name}
                onChange={this.onChangeName}
              />
            </>
          ) : null}
        </Stack>
      </Panel>
    );
  }

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        {!this.state.submitting ? (
          <>
            <PrimaryButton
              text='Tambahkan'
              disabled={this.isSubmitButtonDisabled()}
              onClick={this.onSubmit}
            />
            <DefaultButton text='Batal' onClick={() => this.props.closePanel()} />
          </>
        ) : null}
        {this.state.submitting ? (
          <LoadingComponent
            label='Menambahkan tipe produk ...'
            labelPosition='right'
            spinnerPosition='baseline'
          />
        ) : null}
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, files } = this.state;
    const fd = new FormData();
    if (data.name && files.length !== 0) {
      fd.append('name', data.name || '');
      files.map((f) => f.originFileObj && fd.append('files[]', f?.originFileObj));
      try {
        this.setState({ submitting: true });
        await ProductTypeService.create(fd);
        this.props.closePanel(true, {
          type: MessageBarType.success,
          text: `Tipe produk ${data.name || ''} berhasil ditambahkan.`,
        });
      } catch (e) {
        this.setState({
          submitting: false,
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(e),
          },
        });
      }
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 125 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private isSubmitButtonDisabled = () => {
    const { data, errorData } = this.state;
    if (data.name?.trim() === '' || (errorData && errorData.name)) {
      return true;
    }

    return false;
  };
}
