import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  Text,
  SpinnerSize,
  DefaultButton,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { EventType, EventTransactionProps } from '../../../props/events';
import eventServices from '../../../services/events';
import eventTransactionService from '../../../services/events/transactions';
import styles from './styles.module.scss';
import useFirebaseMessaging from '../../../utils/firebaseApp';

type EventPortalPageProps = {};

const EventPortalPage: React.FC<EventPortalPageProps> = (props) => {
  const { message } = useFirebaseMessaging();
  const [initialising, setInitialising] = React.useState<boolean>(false);
  const [refreshing, setRefreshing] = React.useState<boolean>(true);
  const [event, setEvent] = React.useState<EventType>();
  const [transactions, setTransactions] = React.useState<EventTransactionProps[]>([]);
  const [eventCode, setEventCode] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [processing, setProcessing] = React.useState<boolean>(false);

  useEffect(() => {
    if (message !== undefined) {
      if (event) {
        if (message.data?.key === 'new_transaction') resetTransaction(event);
      }
      console.log(message);
    }
    return () => { };
  }, [message]);

  const resetTransaction = async (evt: EventType) => {
    setRefreshing(true);
    try {
      const results = await eventTransactionService.retrieve(evt.code, 'status=requested');
      setTransactions(results);
      setRefreshing(false);
    } catch (e) {
      setInitialising(false);
      setEvent(undefined);
      setErrorMessage('Error while retrieving transacations. Please check your connection and try again.');
    }
  };

  const init = async () => {
    console.log("init");
    setInitialising(true);
    setErrorMessage(undefined);

    try {
      const result = await eventServices.get(eventCode);
      if (result) {
        resetTransaction(result);
      }

      setInterval(()=> {
        resetTransaction(result);
      }, 5000);
      setInitialising(false);
      setEvent(result);
    } catch (e) {
      setInitialising(false);
      setErrorMessage('Please check event code.');
    }
  };

  const completeTransaction = async (transaction: EventTransactionProps) => {
    if (event) {
      try {
        setProcessing(true);
        await eventTransactionService.complete(event?.code || '', transaction.id);
        await resetTransaction(event);
        setProcessing(false);
      } catch (e) {
        setProcessing(false);
        setErrorMessage('Failed to process transaction. Please try again.');
      }
    }
  };

  const rejectTransaction = async (transaction: EventTransactionProps) => {
    if (event) {
      if (
        window.confirm(
          `Are you sure to reject ${`${transaction.quantity}x ${transaction.flavour.name}`} from ${transaction.id
          }?`
        )
      ) {
        try {
          setProcessing(true);
          await eventTransactionService.reject(event?.code || '', transaction.id);
          await resetTransaction(event);
          setProcessing(false);
        } catch (e) {
          setProcessing(false);
          setErrorMessage('Failed to process transaction. Please try again.');
        }
      }
    }
  };

  const renderInitForm = () => {
    return (
      <Stack className={styles.initForm} tokens={{ childrenGap: 10 }}>
        {errorMessage ? (
          <MessageBar messageBarType={MessageBarType.error} styles={{ root: { width: '300px' } }}>
            <Text variant={'small'}>{errorMessage}</Text>
          </MessageBar>
        ) : null}
        <TextField
          label='Event Code'
          required
          value={eventCode}
          onChange={(e, v) => setEventCode(v || '')}
          styles={{ root: { width: '300px' } }}
        />
        <PrimaryButton label='Submit' text='Submit' onClick={init} styles={{ root: { width: '300px' } }} />
      </Stack>
    );
  };

  const renderInitLoading = () => {
    return (
      <Stack className={styles.initLoading}>
        <Spinner label={'Working on it ...'} labelPosition={'bottom'} />
      </Stack>
    );
  };

  const renderEvent = () => {
    return (
      <Stack className={styles.event} tokens={{ childrenGap: 20 }}>
        <Text variant={'xxLargePlus'}>{event?.name}</Text>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack className={styles.card} grow={1}>
            <Stack
              horizontal
              tokens={{ childrenGap: 20 }}
              horizontalAlign={'space-between'}
              verticalAlign={'center'}
              styles={{ root: { paddingRight: 5 } }}
            >
              <Text className={styles.title}>Active Transaction</Text>
              {refreshing ? (
                <Spinner labelPosition='right' size={SpinnerSize.small} label={'refreshing ...'} />
              ) : null}
            </Stack>
            <Stack className={styles.content}>
              {transactions.map((transaction) => {
                return (
                  <Stack
                    key={transaction.id}
                    horizontal
                    horizontalAlign='space-between'
                    className={styles.transaction}
                  >
                    <Stack>
                      <Text variant='large' style={{ fontWeight: 600 }}>
                        {transaction.quantity}x {transaction.flavour.name}
                      </Text>
                      <Text variant='small'>{transaction.flavour.description}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      {processing ? <Spinner label='Processing ...' labelPosition='right' /> : null}
                      {/* {!processing ? (
                        <>
                          {transaction.status === 'requested' &&
                            <>
                              <PrimaryButton
                                label={'Complete'}
                                text={'Complete'}
                                onClick={() => completeTransaction(transaction)}
                              />
                              <DefaultButton
                                label={'Reject'}
                                text={'Reject'}
                                onClick={() => rejectTransaction(transaction)}
                              />
                            </>
                          }
                          {transaction.status === 'completed' && <Text>Completed</Text>}
                          {transaction.status === 'rejected' && <Text>Rejected</Text>}
                        </>
                      ) : null} */}
                      {!processing ? (
                        <>
                          <PrimaryButton
                            label={'Complete'}
                            text={'Complete'}
                            onClick={() => completeTransaction(transaction)}
                          />
                          <DefaultButton
                            label={'Reject'}
                            text={'Reject'}
                            onClick={() => rejectTransaction(transaction)}
                          />
                        </>
                      ) : null}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
          <Stack className={styles.card} styles={{ root: { width: 300 } }}>
            <Stack
              horizontal
              tokens={{ childrenGap: 20 }}
              horizontalAlign={'space-between'}
              verticalAlign={'center'}
              styles={{ root: { paddingRight: 5 } }}
            >
              <Text className={styles.title}>Stock List</Text>
              {refreshing ? (
                <Spinner labelPosition='right' size={SpinnerSize.small} label={'refreshing ...'} />
              ) : null}
            </Stack>
            <Stack className={styles.content}>Hello</Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack className={styles.container}>
      {!event && !initialising ? renderInitForm() : null}
      {!event && initialising ? renderInitLoading() : null}
      {event && !initialising ? renderEvent() : null}
    </Stack>
  );
};

export default EventPortalPage;
