import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  DefaultButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services

// import interfaces
import AddToppingPanel from './surfaces/panelAddTopping';
import ToppingServices from '../../../../services/toppings';
import { IToppingResourceShort } from '../../../../props/toppings';
import UpdateToppingPanel from './surfaces/panelUpdateTopping';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';
import SortToppingsPanel from './surfaces/panelSortToppings';

interface IToppingPageProps {
  context: ProviderStore;
}

interface IToppingPageState {
  toppings: IToppingResourceShort[];
  loaded: boolean;
  addToppingDialog?: JSX.Element;
  updateToppingDialog?: JSX.Element;
  sortToppingPanel?: JSX.Element;
}

export default class ToppingPage extends React.Component<IToppingPageProps, IToppingPageState> {
  constructor(props: IToppingPageProps) {
    super(props);

    this.state = {
      toppings: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getToppings();
  }

  private getToppings = async () => {
    this.setState({ loaded: false });
    try {
      const toppings = await ToppingServices.retrieve();
      this.setState({ toppings, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { toppings, loaded, addToppingDialog, updateToppingDialog, sortToppingPanel } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addToppingDialog}
        {updateToppingDialog}
        {sortToppingPanel}
        <Text variant={'xLarge'}>Topping</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={toppings}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: IToppingResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                </Stack>
              ),
            },
            {
              key: 'name',
              name: 'Name',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IToppingResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{item.name}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () =>
                            this.setState({
                              updateToppingDialog: (
                                <UpdateToppingPanel
                                  closePanel={(r) => {
                                    if (r) {
                                      this.getToppings();
                                    }
                                    this.setState({ updateToppingDialog: undefined });
                                  }}
                                  topping={item}
                                />
                              ),
                            }),
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'update_topping',
                          ]),
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {
                            this.deleteTopping(item);
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'delete_topping',
                          ]),
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add_topping',
        text: 'Add Topping',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Tambah Topping Baru'
              styles={{ root: { marginLeft: 10 } }}
              onClick={this.showDialog}
            />
          );
        },
      },
      {
        key: 'sort_toppings',
        text: 'Sort Toppings',
        onRender: () => {
          return (
            <DefaultButton
              disabled={!loaded}
              iconProps={{ iconName: 'ArrowDownUp' }}
              text='Ubah Urutan Topping'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() =>
                this.setState({
                  sortToppingPanel: (
                    <SortToppingsPanel
                      closePanel={(r) => {
                        this.setState({ sortToppingPanel: undefined });
                        if (r) {
                          this.getToppings();
                        }
                      }}
                    />
                  ),
                })
              }
            />
          );
        },
      },
    ];
  };

  private showDialog = () =>
    this.setState({
      addToppingDialog: (
        <AddToppingPanel
          closePanel={(r) => {
            this.setState({ addToppingDialog: undefined });
            if (r) {
              this.getToppings();
            }
          }}
          orderNumber={
            this.state.toppings.length !== 0
              ? Number(this.state.toppings[this.state.toppings.length - 1].orderNumber) + 1
              : 1
          }
        />
      ),
    });

  private deleteTopping = async (item: IToppingResourceShort) => {
    if (window.confirm('Delete Topping')) {
      try {
        await ToppingServices.delete(item.id);
        this.getToppings();
      } catch (error) {}
    }
  };
}
