import * as React from 'react';

// import ui
import { PrimaryButton, Stack, Text } from '@fluentui/react';
import AuthenticationService from '../../../../services/auth/user';
import OrderPage from '../orders';

interface IDashboardProps {}

interface IDashboardState {}

export default class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  constructor(props: IDashboardProps) {
    super(props);

    this.state = {};
  }

  private logout = async () => {
    try {
      await AuthenticationService.logout();
    } catch (error) {}
  };

  public render() {
    return <Stack></Stack>;
  }
}
