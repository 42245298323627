import * as React from 'react';

// import ui
import { Checkbox, DefaultButton, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';

// import interfaces
import { IUserResourceShortProps } from '../../../../../props/user';
import RoleService from '../../../../../services/roles';
import { IRoleResourceShort } from '../../../../../props/roles';
import { IRenderMessageBarProps } from '../../../../../props/general';

interface IUserRolesPanelProps {
  closePanel: () => void;
  user: IUserResourceShortProps;
  messageBar?: IRenderMessageBarProps;
}

interface IUserRolesPanelState {
  roles: IRoleResourceShort[];
  loaded: boolean;
  user: IUserResourceShortProps;
}

export default class UserRolesPanel extends React.Component<IUserRolesPanelProps, IUserRolesPanelState> {
  constructor(props: IUserRolesPanelProps) {
    super(props);

    this.state = {
      roles: [],
      loaded: false,
      user: props.user,
    };
  }

  public render() {
    const { roles, loaded, user } = this.state;
    return (
      <Panel
        headerText='User Roles'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {loaded && (
          <Stack tokens={{ childrenGap: 10 }}>
            {roles.map((r) => (
              <Checkbox
                label={r.name}
                key={r.key}
                checked={user.userRoles.find((f) => f.roleKey === r.key) ? true : false}
                onChange={(e, c) => {
                  this.onChangeCheckbox(r, e, c);
                }}
              />
            ))}
          </Stack>
        )}
      </Panel>
    );
  }

  private onChangeCheckbox = (
    r: IRoleResourceShort,
    e?: React.FormEvent<HTMLElement | HTMLInputElement>,
    c?: boolean
  ) => {
    const { user } = this.state;
    const userRoles = [...user.userRoles];

    if (c) {
      userRoles.push({ roleKey: r.key, username: r.name });
      user.userRoles = userRoles;
    } else {
      user.userRoles = userRoles.filter((f) => f.roleKey !== r.key);
    }
    this.setState({ user });
  };

  componentDidMount() {
    this.getAllRoles();
  }

  private getAllRoles = async () => {
    try {
      const roles = await RoleService.retrieve();
      this.setState({ roles, loaded: true });
    } catch (error) {}
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' />
      </Stack>
    );
  };
}
