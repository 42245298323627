import * as React from 'react';

import {
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import UserService from '../../../../../services/auth/user';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import validationService from '../../../../../services/validation';
import { IUserResourceShortProps } from '../../../../../props/user';

interface IUpdateUserPanelProps {
  closePanel: () => void;
  user: IUserResourceShortProps;
}

interface IUpdateUserPanelState {
  data?: { username?: string; password?: string; name?: string; email?: string; address?: string };
  errorData?: { username?: string; password?: string; name?: string; email?: string; address?: string };
  messageBar?: IRenderMessageBarProps;
}

export default class UpdateUserPanel extends React.Component<IUpdateUserPanelProps, IUpdateUserPanelState> {
  constructor(props: IUpdateUserPanelProps) {
    super(props);

    this.state = {
      data: {
        name: props.user.details?.name || '',
        address: props.user.details?.address || '',
      },
    };
  }

  public render() {
    const { data, errorData } = this.state;
    return (
      <Panel
        headerText='Add User'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <TextField
          label='Name'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={(e, nv) => {
            const validation = validationService.combination(nv || '', ['required', 'limit'], {
              maxChars: 255,
            }).message;
            this.setState({
              data: { ...data, name: nv },
              errorData: { ...errorData, name: validation },
            });
          }}
        />
        <TextField
          label='Address'
          value={data?.address}
          multiline
          rows={3}
          resizable={false}
          errorMessage={errorData?.address}
          onChange={(e, v) => {
            const validation = validationService.combination(v || '', ['limit'], {
              maxChars: 255,
            }).message;
            this.setState({
              data: { ...data, address: v },
              errorData: { ...errorData, address: validation },
            });
          }}
        />
      </Panel>
    );
  }

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data } = this.state;
    if (data && data.name && data.name.trim() !== '') {
      const fd = new FormData();
      fd.append('name', data.name);
      data.address && fd.append('address', data.address);
      try {
        await UserService.create(fd);
        this.props.closePanel();
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };
}
