import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { MessagePayload, getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import React from 'react';

const firebaseConfig = {
  apiKey: 'AIzaSyDmSf1V1fed4X_GvNv-Vlkg5GculNWEhSA',
  authDomain: 'laure-admin.firebaseapp.com',
  projectId: 'laure-admin',
  storageBucket: 'laure-admin.appspot.com',
  messagingSenderId: '56311916889',
  appId: '1:56311916889:web:7b20f7b6b11576744b4448',
  measurementId: 'G-32LV5GV479',
};

const vapidKey = 'BFClRZMuNU8RgtGvES0AdDQM52IE-mh8rnCxWte4eF2g6NzLlB8iLArJPy8UodRObzrn6ntXGeqYuM8iVivUrUc';

interface FirebaseMessagingResponse {
  message?: MessagePayload;
  token?: string;
}

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export async function initializeFirebase(): Promise<string | undefined> {
  try {
    const permission = await getNotificationPermission();
    if (permission === 'granted') {
      const serviceWorkerRegistration = await navigator.serviceWorker.register('../firebase-messaging-sw.js');
      const currentToken = await getToken(messaging, { vapidKey, serviceWorkerRegistration });
      return currentToken;
    }
  } catch (err) {
    throw err;
  }
}

export async function getNotificationPermission() {
  return await Notification.requestPermission();
}

const useFirebaseMessaging = (): FirebaseMessagingResponse => {
  const [message, setMessage] = useState<MessagePayload>();
  const [token, setToken] = useState<string | undefined>();

  const onMessageReceived = React.useCallback((payload: MessagePayload) => {
    setMessage(payload);
  }, []);

  useEffect(() => {
    const unregister = onMessage(messaging, onMessageReceived);

    return unregister;
  }, [onMessageReceived]);

  useEffect(() => {
    init();

    return () => {};
  }, []);

  async function init() {
    try {
      const token = await initializeFirebase();
      console.log('Token: ' + token);
      setToken(token);
    } catch (error) {
      console.log(error);
    }
  }

  return { message, token };
};

export default useFirebaseMessaging;
