import * as React from 'react';

// import ui
import {
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';

// import services
import validationService from '../../../../../services/validation';
import GeneralService from '../../../../../services/general';

// import component
import MessageBarComponent from '../../../../../components/messageBar';
import LoadingComponent from '../../../../../components/loading';
import GiftCardService from '../../../../../services/gift';

interface IAddGiftCardPanelProps {
  closePanel: (rrefresh?: boolean, messageBar?: IRenderMessageBarProps) => void;
}

export type GiftCardDataType = {
  id?: string;
  amount?: string;
};

interface IAddGiftCardPanelState {
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  data?: GiftCardDataType;
  errorData?: GiftCardDataType;
  submiting: boolean;
}

export default class AddGiftCardPanel extends React.Component<
  IAddGiftCardPanelProps,
  IAddGiftCardPanelState
> {
  constructor(props: IAddGiftCardPanelProps) {
    super(props);

    this.state = {
      data: {},
      submiting: false,
      loaded: true,
    };
  }

  public render() {
    const { data, loaded, messageBar, errorData } = this.state;
    return (
      <Panel
        headerText='Tambah Gift Card'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <Stack tokens={{ childrenGap: 10 }}>
          {messageBar ? <MessageBarComponent {...messageBar} /> : null}
          {!loaded ? (
            <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
          ) : null}
          {loaded ? (
            <>
              <Stack.Item>
                <TextField
                  label='Id'
                  value={data?.id}
                  errorMessage={errorData?.id}
                  onChange={this.onChangeId}
                  required
                />
              </Stack.Item>
              <Stack.Item>
                <TextField
                  label='Total'
                  value={data?.amount}
                  errorMessage={errorData?.amount}
                  onChange={this.onChangeAmount}
                  required
                />
              </Stack.Item>
            </>
          ) : null}
        </Stack>
      </Panel>
    );
  }

  private onRenderFooter = () => {
    const { submiting } = this.state;
    if (submiting) {
      return <LoadingComponent label={'Submiting'} />;
    } else {
      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <PrimaryButton text='Submit' onClick={this.onSubmit} />
          <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        </Stack>
      );
    }
  };

  private onSubmit = async () => {
    const { data } = this.state;
    if (data?.amount && data.id) {
      this.setState({ submiting: true });
      const fd = new FormData();
      fd.append('amount', data.amount);
      fd.append('id', data.id);
      try {
        await GiftCardService.create(fd);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
          submiting: false,
        });
      }
    } else {
      this.setState({
        messageBar: {
          type: MessageBarType.warning,
          text: 'Kolom nama tidak boleh kosong',
        },
      });
    }
  };

  private onChangeAmount = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit', 'number'], { maxChars: 11 }).message;
    this.setState({
      data: { ...this.state.data, amount: v },
      errorData: { ...this.state.errorData, amount: err },
    });
  };

  private onChangeId = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, id: v },
      errorData: { ...this.state.errorData, id: err },
    });
  };
}
