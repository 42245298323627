import * as React from 'react';

import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import { UploadFile } from 'antd/lib/upload/interface';

import { IPackagingResourceShort } from '../../../../../props/packagings';

// import services
import validationService from '../../../../../services/validation';
import ProductService from '../../../../../services/products';
import PackagingService from '../../../../../services/packagings';
import ProductTypeService from '../../../../../services/products/type';
import GeneralService from '../../../../../services/general';

// import interfaces
import { IProductTypeResourceShort } from '../../../../../props/products';
import { IFlavourResourceShort } from '../../../../../props/flavours';
import { IRenderMessageBarProps } from '../../../../../props/general';

// import component
import MessageBarComponent from '../../../../../components/messageBar';
import Flavours from '../components/flavours';
import PicturesWall from '../components/uploadFile';

export type DataType = {
  flavour?: IFlavourResourceShort;
  qty: number;
  key: string;
  replaceable: boolean;
};

interface IAddProductPanelProps {
  closePanel: (r?: boolean) => void;
  orderNumber: number;
}

export type ProductDataType = {
  name?: string;
  description?: string;
  price?: string;
  minQuantity?: string;
  maxQuantity?: string;
};

interface IAddProductPanelState {
  data?: ProductDataType;
  errorData?: ProductDataType;
  files: UploadFile<any>[];
  packagings: IPackagingResourceShort[];
  loaded: boolean;
  packagingsId: string[];
  productTypes: IProductTypeResourceShort[];
  productTypeId?: string;
  messageBar?: IRenderMessageBarProps;
  dataFlavours: DataType[];
}

export default class AddProductPanel extends React.Component<IAddProductPanelProps, IAddProductPanelState> {
  constructor(props: IAddProductPanelProps) {
    super(props);

    this.state = {
      files: [],
      packagings: [],
      loaded: false,
      packagingsId: [],
      productTypes: [],
      dataFlavours: [],
    };
  }

  componentDidMount() {
    this.getPackagings();
  }

  private getPackagings = async () => {
    try {
      const packagings = await PackagingService.retrieve();
      this.setState({ packagings }, this.getProductTypes);
    } catch (error) {}
  };

  private getProductTypes = async () => {
    try {
      const productTypes = await ProductTypeService.retrieve();
      this.setState({ productTypes, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { data, errorData, packagings, packagingsId, productTypes, productTypeId, loaded, messageBar } =
      this.state;
    return (
      <Panel
        headerText='Tambah Produk'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack>
            <Label>Gambar Produk</Label>
            <PicturesWall fileList={[]} onUpdate={(files) => this.setState({ files })} />
          </Stack>
          <TextField
            label='Nama Produk'
            value={data?.name}
            errorMessage={errorData?.name}
            onChange={this.onChangeName}
            disabled={!loaded}
          />
          <TextField
            value={data?.description}
            errorMessage={errorData?.description}
            label='Detail / Catatan Tambahan'
            onChange={this.onChengeDescription}
            disabled={!loaded}
          />
          <TextField
            value={data?.price}
            errorMessage={errorData?.price}
            label='Harga'
            prefix={'Rp'}
            onChange={this.onChengePrice}
            disabled={!loaded}
          />
          <Dropdown
            placeholder='Pilih tipe produk ...'
            label='Tipe Produk'
            options={productTypes.map((p) => ({ key: p.id, text: p.name }))}
            selectedKey={productTypeId}
            onChange={this.onChangeDropdownType}
            disabled={!loaded || productTypes.length === 0}
          />
          <TextField
            value={data?.minQuantity}
            errorMessage={errorData?.minQuantity}
            label='Min. Pilihan Rasa'
            onChange={this.onChangeMinQty}
            disabled={!loaded}
          />
          <TextField
            value={data?.maxQuantity}
            errorMessage={errorData?.maxQuantity}
            label='Maks. Pilihan Rasa'
            onChange={this.onChangeMaxQty}
            disabled={!loaded}
          />
          <Dropdown
            placeholder='Pilih packaging ...'
            label='Packaging yang bisa dipakai'
            options={packagings.map((p) => ({ key: p.id, text: p.name }))}
            multiSelect
            selectedKeys={packagingsId}
            onChange={this.onChangeDropdownPackagings}
            disabled={!loaded || packagings.length === 0}
          />
          <Flavours
            onUpdateDataFlavour={this.onUpdateDataFlavour}
            maxQuantity={Number(data?.maxQuantity || '0')}
            flavoursData={[]}
          />
        </Stack>
      </Panel>
    );
  }

  private onUpdateDataFlavour = (dataFlavours: DataType[]) => {
    this.setState({ dataFlavours });
  };

  private onChangeDropdownPackagings = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    const { packagingsId } = this.state;
    this.setState({
      packagingsId: option?.selected
        ? [...packagingsId, option.key as string]
        : packagingsId.filter((k) => k !== option?.key),
    });
  };

  private onChangeDropdownType = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    if (option) {
      this.setState({ productTypeId: option.key as string });
    }
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, files, errorData, packagingsId, productTypeId, dataFlavours } = this.state;
    if (
      files.length !== 0 &&
      data?.name &&
      data?.name.trim() !== '' &&
      data.price &&
      data.minQuantity &&
      data.maxQuantity &&
      data.price.trim() !== '' &&
      Number(data.maxQuantity || '0') >=
        dataFlavours
          .filter((df) => df.flavour !== undefined)
          .map((df) => df.qty)
          .reduce((a, b) => a + b, 0)
    ) {
      const fd = new FormData();
      fd.append('name', data.name);
      fd.append('price', data.price);
      fd.append('minQuantity', data.minQuantity);
      fd.append('maxQuantity', data.maxQuantity);
      packagingsId.map((k) => fd.append('packagingsId[]', k));
      dataFlavours
        .filter((f) => f.flavour !== undefined)
        .map((f) =>
          fd.append(
            'flavours[]',
            JSON.stringify({ id: f.flavour?.id, qty: f.qty, replaceable: f.replaceable ? 1 : 0 })
          )
        );
      data.description && fd.append('description', data.description);
      productTypeId && fd.append('typeId', productTypeId);
      files.map((f) => f.originFileObj && fd.append('files[]', f?.originFileObj));
      fd.append('orderNumber', String(this.props.orderNumber));
      try {
        await ProductService.create(fd);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };
  private onChangeMinQty = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit', 'number'], { maxChars: 6 }).message;
    this.setState({
      data: { ...this.state.data, minQuantity: v },
      errorData: { ...this.state.errorData, minQuantity: err },
    });
  };

  private onChangeMaxQty = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit', 'number'], { maxChars: 6 }).message;
    this.setState({
      data: { ...this.state.data, maxQuantity: v },
      errorData: { ...this.state.errorData, maxQuantity: err },
    });
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 1000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };

  private onChengePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    if (/^\d+$/.test(v || '')) {
      const err = validationService.combination(v, ['limit', 'number'], { maxChars: 11 }).message;
      this.setState({
        data: { ...this.state.data, price: v },
        errorData: { ...this.state.errorData, price: err },
      });
    }
  };
}
