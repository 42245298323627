import React from 'react';

// import manifests

// import configs

// import stylings

// import props

// import services

// import components

// import antd component
import { IFontStyles, MessageBar, Stack, Text } from '@fluentui/react';
import styles from './style.module.scss';

export interface ITagComponentProps {
    text: string;
    variant?: keyof IFontStyles;
    type: 'success' | 'warning' | 'error';
}

export default class TagComponent extends React.Component<ITagComponentProps, {}> {

    constructor(props: ITagComponentProps) {
        super(props);
        this.state = {};
    }

    public render() {
        return <Stack className={`${styles.tag} ${styles[this.props.type]}`}>
            <Text variant={this.props.variant || 'small'}>{this.props.text}</Text>
        </Stack>
    }
}