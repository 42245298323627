import * as React from 'react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';

// import ui
import {
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';

// import component
import PicturesWall from '../components/uploadFile';

// import interfaces

// import config
import GeneralConfig from '../../../../../config';
import { IPackagingResourceShort } from '../../../../../props/packagings';
import PackagingService from '../../../../../services/packagings';
import PackagingImageService from '../../../../../services/packagings/images';
import { PackagingDataType } from './panelAddPackaging';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import MessageBarComponent from '../../../../../components/messageBar';

interface IUpdatePackagingPanelProps {
  closePanel: (r?: boolean) => void;
  packaging: IPackagingResourceShort;
}

interface IUpdatePackagingPanelState {
  packaging: IPackagingResourceShort;
  data?: PackagingDataType;
  errorData?: PackagingDataType;
  files: UploadFile<any>[];
  messageBar?: IRenderMessageBarProps;
}

export default class UpdatePackagingPanel extends React.Component<
  IUpdatePackagingPanelProps,
  IUpdatePackagingPanelState
> {
  constructor(props: IUpdatePackagingPanelProps) {
    super(props);

    this.state = {
      files: [],
      packaging: props.packaging,
      data: {
        name: props.packaging.name,
        price: props.packaging.additionalPrice || '0',
      },
    };
  }

  public render() {
    const { data, errorData, packaging, messageBar } = this.state;
    return (
      <Panel
        headerText='Update Packaging'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <Stack styles={{ root: { marginTop: 10 } }}>
          <Label>Gambar Packaging</Label>
          <PicturesWall
            fileList={packaging.images.map((f) => ({
              uid: f.id,
              name: f.name,
              status: 'done',
              url: GeneralConfig.assetsHostname + '/' + f.url,
            }))}
            onUpdate={(files) => this.setState({ files })}
            onRemoveFile={this.onRemoveFile}
            onAddFile={this.onAddFile}
          />
        </Stack>
        <TextField
          label='Name'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={this.onChangeName}
        />
        <TextField
          value={data?.price}
          errorMessage={errorData?.price}
          label='Additional Price'
          onChange={this.onChengePrice}
        />
      </Panel>
    );
  }

  private onAddFile = async (file: RcFile) => {
    let fd = new FormData();
    fd.append('file', file);
    try {
      await PackagingImageService.create(this.props.packaging.id, fd);
    } catch (error) {}
  };

  private onRemoveFile = async (file: UploadFile<any>) => {
    try {
      await PackagingImageService.delete(this.props.packaging.id, file.uid);
    } catch (error) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(error),
        },
      });
    }
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, files, errorData } = this.state;
    if (data?.name) {
      const fd = new FormData();
      fd.append('name', data.name);
      fd.append('additionalPrice', data.price || '0');
      try {
        await PackagingService.update(fd, this.props.packaging.id);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChengePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'number'], { maxChars: 10 }).message;
    this.setState({
      data: { ...this.state.data, price: v },
      errorData: { ...this.state.errorData, price: err },
    });
  };
}
