import { CommandBarButton, SelectionMode, ShimmeredDetailsList, Stack, Text } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { EventTransactionProps } from '../../../../props/events';

type EventTransactionListProps = {
  children?: React.ReactNode;
  transactions: EventTransactionProps[];
  loaded: boolean;
  onDeleteItem: (eventFlavour: EventTransactionProps) => void;
  onUpdateItem: (eventFlavour: EventTransactionProps) => void;
};

const EventTransactionList: React.FC<EventTransactionListProps> = (props) => {
  const transactions = props.transactions;
  const [loaded, setLoaded] = useState<boolean>(props.loaded);

  useEffect(() => {
    setLoaded(props.loaded);
    return () => {};
  }, [props.loaded]);

  return (
    <ShimmeredDetailsList
      selectionMode={SelectionMode.none}
      items={transactions}
      enableShimmer={!loaded}
      columns={[
        {
          key: 'no',
          name: 'No',
          maxWidth: 20,
          minWidth: 20,
          isMultiline: true,
          onRender: (item: EventTransactionProps, ix) => (
            <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
              <Text>{(ix || 0) + 1}</Text>
            </Stack>
          ),
        },
        {
          key: 'name',
          name: 'Flavour',
          maxWidth: 200,
          minWidth: 200,
          isMultiline: true,
          onRender: (item: EventTransactionProps, ix) => (
            <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
              <Text>{item.flavour.name}</Text>
            </Stack>
          ),
        },
        {
          key: 'quantity',
          name: 'Quantity',
          maxWidth: 200,
          minWidth: 200,
          isMultiline: true,
          onRender: (item: EventTransactionProps, ix) => (
            <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
              <Text>{item.quantity}</Text>
            </Stack>
          ),
        },
      ]}
    />
  );
};

export default EventTransactionList;
