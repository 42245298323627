import * as React from 'react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';

// import ui
import {
  DefaultButton,
  Dropdown,
  IconButton,
  IDropdownOption,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';
import FlavourImageService from '../../../../../services/flavours/images';
import FlavourService from '../../../../../services/flavours';
import ProductTypeService from '../../../../../services/products/type';
import GeneralService from '../../../../../services/general';

// import component
import PicturesWall from '../components/uploadFile';
import MessageBarComponent from '../../../../../components/messageBar';
import LoadingComponent from '../../../../../components/loading';

// import interfaces
import { IFlavourResourceShort, IFlavourTagResourceShort } from '../../../../../props/flavours';
import { IProductTypeResourceShort } from '../../../../../props/products';
import { IRenderMessageBarProps } from '../../../../../props/general';

// import interfaces
import GeneralConfig from '../../../../../config';

import { FlavourDataType } from './panelAddFlavour';
import FlavourTags from '../components/tags';

interface IUpdateFlavourPanelProps {
  closePanel: (r?: boolean) => void;
  flavour: IFlavourResourceShort;
}

interface IUpdateFlavourPanelState {
  flavour: IFlavourResourceShort;
  data?: FlavourDataType;
  errorData?: FlavourDataType;
  files: UploadFile<any>[];
  choices: {
    productTypes: IProductTypeResourceShort[];
  };
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  tags?: IFlavourTagResourceShort[];
  submiting: boolean;
}

export default class UpdateFlavourPanel extends React.Component<
  IUpdateFlavourPanelProps,
  IUpdateFlavourPanelState
> {
  constructor(props: IUpdateFlavourPanelProps) {
    super(props);

    this.state = {
      files: [],
      flavour: props.flavour,
      data: {
        name: props.flavour.name,
        description: props.flavour.description,
        available: props.flavour.isAvailable === 1,
        premium: props.flavour.isPremium === 1,
        vegan: props.flavour.isVegan === 1,
        seasonal: props.flavour.isSeasonal === 1,
        productTypes: props.flavour.productTypes.map((t) => t.productType),
        additionalPrice: props.flavour.additionalPrice,
      },
      choices: { productTypes: [] },
      submiting: false,
    };
  }

  componentDidMount() {
    this.getProductTypes();
  }

  private getProductTypes = async () => {
    try {
      const productTypes = await ProductTypeService.retrieve();
      this.setState({
        choices: { productTypes },
        loaded: true,
      });
    } catch (e) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(e),
        },
      });
    }
  };

  public render() {
    const { data, errorData, flavour, choices, messageBar, loaded } = this.state;
    return (
      <Panel
        headerText='Ubah Rasa'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        {!loaded ? (
          <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
        ) : null}
        {loaded && (
          <>
            <Stack styles={{ root: { marginTop: 10 } }}>
              <Label>Gambar Rasa</Label>
              <PicturesWall
                fileList={flavour.images.map((f) => ({
                  uid: f.id,
                  name: f.name,
                  status: 'done',
                  url: GeneralConfig.assetsHostname + '/' + f.url,
                }))}
                onUpdate={(files) => this.setState({ files })}
                onRemoveFile={this.onRemoveFile}
                onAddFile={this.onAddFile}
              />
            </Stack>
            <TextField
              label='Name'
              value={data?.name}
              errorMessage={errorData?.name}
              onChange={this.onChangeName}
            />
            <TextField
              value={data?.description}
              errorMessage={errorData?.description}
              label='Penjelasan / Catatan Tambahan'
              onChange={this.onChengeDescription}
            />
            <TextField
              value={data?.additionalPrice}
              errorMessage={errorData?.additionalPrice}
              label='Harga Tambahan'
              onChange={this.onChangeAdditionalPrice}
              prefix='Rp'
            />
            <Dropdown
              multiSelect
              label={'Tipe Produk'}
              options={choices.productTypes.map((t) => {
                return { key: t.id, text: t.name };
              })}
              selectedKeys={data?.productTypes?.map((t) => t.id) || []}
              onChange={this.onProductTypeChanged}
            />
            <Stack>
              <Label>Tags</Label>
              <FlavourTags
                tags={this.props.flavour.tags}
                onUpdate={(tags: IFlavourTagResourceShort[]) => this.setState({ tags })}
              />
            </Stack>
            <Toggle
              label='Available'
              checked={data?.available}
              onChange={(e, c) => this.setState({ data: { ...data, available: c } })}
            />
            <Toggle
              label='Premium'
              checked={data?.premium}
              onChange={(e, c) => this.setState({ data: { ...data, premium: c } })}
            />
            <Toggle
              label='Vegan'
              checked={data?.vegan}
              onChange={(e, c) => this.setState({ data: { ...data, vegan: c } })}
            />
            <Toggle
              label='Seasonal'
              checked={data?.seasonal}
              onChange={(e, c) => this.setState({ data: { ...data, seasonal: c } })}
            />
          </>
        )}
      </Panel>
    );
  }

  private onChangeAdditionalPrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'number'], { maxChars: 11 }).message;
    this.setState({
      data: { ...this.state.data, additionalPrice: v },
      errorData: { ...this.state.errorData, additionalPrice: err },
    });
  };

  private onProductTypeChanged = (e: React.FormEvent<HTMLDivElement>, o: IDropdownOption | undefined) => {
    let productTypes = this.state.data?.productTypes || [];
    if (o) {
      const idx = productTypes.findIndex((t) => t.id.toLowerCase() === (o.key as string).toLowerCase());
      const v = this.state.choices.productTypes.find(
        (t) => t.id.toLowerCase() === (o.key as string).toLowerCase()
      );
      if (idx > -1) {
        productTypes.splice(idx, 1);
      } else if (v) {
        productTypes.push(v);
      }

      this.setState({ data: { ...this.state.data, productTypes } });
    }
  };

  private onAddFile = async (file: RcFile) => {
    let fd = new FormData();
    fd.append('file', file);
    try {
      await FlavourImageService.create(this.props.flavour.id, fd);
    } catch (error) {}
  };

  private onRemoveFile = async (file: UploadFile<any>) => {
    try {
      await FlavourImageService.delete(this.props.flavour.id, file.uid);
    } catch (error) {}
  };

  private onRenderFooter = () => {
    const { submiting } = this.state;
    if (submiting) {
      return <LoadingComponent label={'Submiting'} />;
    } else {
      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
          <PrimaryButton text='Submit' onClick={this.onSubmit} />
        </Stack>
      );
    }
  };

  private onSubmit = async () => {
    const { data, files, tags } = this.state;
    if (data?.name) {
      this.setState({ submiting: true });
      const fd = new FormData();
      fd.append('name', data.name);
      data.description && fd.append('description', data.description);
      data.available && fd.append('available', data.available && '1');
      data.premium && fd.append('premium', data.premium && '1');
      data.vegan && fd.append('vegan', data.vegan && '1');
      data.seasonal && fd.append('seasonal', data.seasonal && '1');
      data.additionalPrice && fd.append('additionalPrice', data.additionalPrice || '0');
      data.productTypes &&
        data.productTypes.forEach((productType) => {
          fd.append('productTypesId[]', productType.id);
        });
      tags && tags.map((t) => fd.append('tags[]', JSON.stringify(t)));
      try {
        await FlavourService.update(fd, this.props.flavour.id);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
          submiting: false,
        });
      }
    } else {
      this.setState({
        messageBar: {
          type: MessageBarType.warning,
          text: 'Kolom nama tidak boleh kosong',
        },
      });
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 1000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };
}
