import {
  CommandBar,
  CommandBarButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import Header from '../../../../components/header';
import { EventCodeType, EventType } from '../../../../props/events';
import eventCodeServices from '../../../../services/events/code';
import AddEventCodePanel from './surfaces/addEventCode';
import UpdateEventCodePanel from './surfaces/updateEventCode';

type EventCodePageProps = {
  eventCodes?: EventCodeType[];
  event?: EventType;
};

const EventCodePage: React.FC<EventCodePageProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(props.eventCodes ? true : false);
  const [eventCodes, setEventCodes] = useState<EventCodeType[]>(props.eventCodes ? props.eventCodes : []);
  const [addEventCodePanel, setAddEventCodePanel] = useState<JSX.Element>();
  const [updateEventCodePanel, setUpdateEventCodePanel] = useState<JSX.Element>();

  useEffect(() => {
    if (props.eventCodes === undefined) {
      getEventCodes();
    }

    return () => {};
  }, []);

  const getEventCodes = async () => {
    setLoaded(false);
    try {
      const eventCodes = await eventCodeServices.retrieve();
      setEventCodes(eventCodes);
      setLoaded(true);
    } catch (error) {}
  };

  const getItems = () => {
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Tambah Event Code'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() => {
                setAddEventCodePanel(
                  <AddEventCodePanel
                    event={props.event}
                    closePanel={(refresh?: boolean | undefined) => {
                      if (refresh) {
                        getEventCodes();
                      }
                      setAddEventCodePanel(undefined);
                    }}
                  />
                );
              }}
            />
          );
        },
      },
    ];
  };

  const onDeleteItem = async (eventCode: EventCodeType) => {
    if (window.confirm(`Hapus ${eventCode.name}`))
      try {
        await eventCodeServices.delete(eventCode.id, '');
        getEventCodes();
      } catch (error) {}
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      {addEventCodePanel}
      {updateEventCodePanel}
      {!props.eventCodes && (
        <Header
          title={'Daftar Codes'}
          breadcrumbs={[
            { key: 'home', text: 'Beranda', href: '/' },
            { key: 'events', text: 'Event', href: '/events' },
            { key: 'flavours', text: `Codes`, href: `/eventCodes` },
          ]}
        />
      )}
      <Stack>
        <CommandBar items={getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={eventCodes}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: EventCodeType, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                </Stack>
              ),
            },
            {
              key: 'event',
              name: 'Event',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: EventCodeType, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{item.event.name}</Text>
                </Stack>
              ),
            },
            {
              key: 'name',
              name: 'Name',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: EventCodeType) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{item.name}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () => {
                            setUpdateEventCodePanel(
                              <UpdateEventCodePanel
                                closePanel={(refresh?: boolean | undefined) => {
                                  if (refresh) {
                                    getEventCodes();
                                  }
                                  setUpdateEventCodePanel(undefined);
                                }}
                                eventCode={item}
                              />
                            );
                          },
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {
                            onDeleteItem(item);
                          },
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default EventCodePage;
