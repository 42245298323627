import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services

// import interfaces
import { IExpensesResourceShort } from '../../../../props/expenses';
import AddExpensesPanel from './surfaces/panelAddExpenses';
import UpdateExpensesPanel from './surfaces/panelUpdateExpenses';
import ExpensesService from '../../../../services/expenses';
import GeneralConfig from '../../../../config';
import moment from 'moment';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';

// import component

interface IExpensesPageProps {
  context: ProviderStore;
}

interface IExpensesPageState {
  expenses: IExpensesResourceShort[];
  loaded: boolean;
  addExpensesDialog?: JSX.Element;
  updateExpensesDialog?: JSX.Element;
}

export default class ExpensesPage extends React.Component<IExpensesPageProps, IExpensesPageState> {
  constructor(props: IExpensesPageProps) {
    super(props);

    this.state = {
      expenses: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getExpenses();
  }

  private getExpenses = async () => {
    try {
      const expenses = await ExpensesService.retrieve();
      this.setState({ expenses, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { expenses, loaded, addExpensesDialog, updateExpensesDialog } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addExpensesDialog}
        {updateExpensesDialog}
        <Text variant={'xLarge'}>Expenses</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={expenses}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: IExpensesResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                </Stack>
              ),
            },

            {
              key: 'image',
              name: 'Gambar',
              maxWidth: 300,
              minWidth: 300,
              isMultiline: true,
              onRender: (item: IExpensesResourceShort, ix) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  tokens={{ childrenGap: 10 }}
                  wrap
                >
                  {item.images.map((i) => (
                    <img
                      key={i.id}
                      src={GeneralConfig.assetsHostname + '/' + i.url}
                      style={{ height: 60, width: 60 }}
                    />
                  ))}
                </Stack>
              ),
            },
            {
              key: 'tanggal',
              name: 'Tanggal',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IExpensesResourceShort, ix) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  horizontalAlign='space-between'
                >
                  <Text>{moment(item.date).format('DD/MM/YYYY')}</Text>
                </Stack>
              ),
            },
            {
              key: 'name',
              name: 'Name',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IExpensesResourceShort) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  horizontalAlign='space-between'
                >
                  <Text>{item.name}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () =>
                            this.setState({
                              updateExpensesDialog: (
                                <UpdateExpensesPanel
                                  closePanel={(r) => {
                                    if (r) {
                                      this.getExpenses();
                                    }
                                    this.setState({ updateExpensesDialog: undefined });
                                  }}
                                  expenses={item}
                                />
                              ),
                            }),
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'update_expense',
                          ]),
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {
                            this.deleteExpenses(item);
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'delete_expense',
                          ]),
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Add Expenses'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() =>
                this.setState({
                  addExpensesDialog: (
                    <AddExpensesPanel
                      closePanel={(r?: boolean) => {
                        if (r) {
                          this.getExpenses();
                        }
                        this.setState({ addExpensesDialog: undefined });
                      }}
                    />
                  ),
                })
              }
            />
          );
        },
      },
    ];
  };

  private deleteExpenses = async (item: IExpensesResourceShort) => {
    if (window.confirm('Delete Expenses')) {
      try {
        await ExpensesService.delete(item.id);
        this.getExpenses();
      } catch (error) {}
    }
  };
}
