import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import urls from './../../configs/urls.json';

import UserService from '../../services/auth/user';
import { IUserRoleResourceShort } from '../../props/user';
import { PermissionKeyType } from '../../props/roles';
import EventPortalPage from './event';
import useFirebaseMessaging from '../../utils/firebaseApp';

type PortalPageProps = {
  children?: React.ReactNode;
};

const redirect = () => {
  window.location.href = urls.auth.login;
};

const PortalPage: React.FC<PortalPageProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    getCurrentUser();
    return () => {};
  }, []);

  const getCurrentUser = async () => {
    try {
      const user = await UserService.currentUser();
      setLoaded(true);
    } catch (error) {
      const status = (error as { status: number }).status;
      if (status === 401) {
        redirect();
      }
    }
  };

  return (
    <Stack>
      {loaded && (
        <>
          <Stack>
            <Routes>
              <Route path={urls.portal.index} element={<EventPortalPage />} />
              <Route path={urls.portal.event.index} element={<EventPortalPage />} />
            </Routes>
          </Stack>
        </>
      )}
      {!loaded && (
        <Stack styles={{ root: { height: '100vh' } }} horizontalAlign='center' verticalAlign='center'>
          <Spinner size={SpinnerSize.large} />
        </Stack>
      )}
    </Stack>
  );
};

export default PortalPage;
