import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

// import ui
import { Stack } from '@fluentui/react';

// import services
import AuthenticationService from '../../services/auth/user';

// import ui
import Dashboard from './subPages/dashboard';
import UsersPage from './subPages/users';
import FlavoursPage from './subPages/flavours';

import urls from '../../configs/urls.json';
import MenubarComponent from '../../components/menubar';

// import styles
import styles from './styles.module.scss';

import { IUserRoleResourceShort } from '../../props/user';
import { PermissionKeyType } from '../../props/roles';
import { ProviderStore } from '../../context';

// import component
import ProductsPage from './subPages/products';
import PackagingsPage from './subPages/packagings';
import ProductTypesPage from './subPages/productType';
import DeliveryMethodPage from './subPages/deliveryMethods';
import ExpensesPage from './subPages/expenses';
import ClosureDatesPage from './subPages/closureDate';
import VoucherPage from './subPages/vouchers';
import ToppingPage from './subPages/toppings';
import RolesPage from './subPages/roles';
import MyProfile from './subPages/myProfile';
import OrderPage from './subPages/orders';
import GiftCardsPage from './subPages/gift';
import EventPage from './subPages/events';
import EventDetailsPage from './subPages/events/details';
import EventFlavourPage from './subPages/eventFlavour';
import EventTransactionPage from './subPages/eventTransactions';
import EventCodePage from './subPages/eventCodes';
import PrintBarcodesPage from './subPages/printBarcodes';
import FaqsPage from './subPages/faqs';
import { initializeFirebase } from '../../utils/firebaseApp';

interface IHomePageProps {
  context: ProviderStore;
}

interface IHomePageState {
  loaded: boolean;
}

export default class HomePage extends React.Component<IHomePageProps, IHomePageState> {
  constructor(props: IHomePageProps) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.getCurrentUser();
  }

  private getCurrentUser = async () => {
    try {
      const user = await AuthenticationService.currentUser();
      let isAdmin = false;
      let isStaff = false;
      user.userRoles.forEach((item) => {
        if (item.roleKey === 'admin') {
          isAdmin = true;
        } else if (item.roleKey === 'staff') {
          isStaff = true;
        }
      });
      if (isAdmin || isStaff) {
        this.savetoken(user.username);
      }
      this.setPermissionKeys(user.userRoles);
    } catch (error) {
      const status = (error as { status: number }).status;
      if (status === 401) {
        this.redirect();
      }
    }
  };

  private savetoken = async (username: string) => {
    try {
      const token = await initializeFirebase();
      if (token) await AuthenticationService.saveToken(username, token);
    } catch (error) {}
  };

  private setPermissionKeys = (userRoles: IUserRoleResourceShort[]) => {
    const { context } = this.props;
    try {
      const myPermissionKeys: PermissionKeyType[] = [];
      userRoles.map((us) => {
        us.role?.permissions?.map((p) => {
          const fn = myPermissionKeys.find((mp) => mp === p.permissionKey);
          if (!fn) {
            myPermissionKeys.push(p.permissionKey);
          }
        });
      });
      context.updateGlobalState({ key: 'myPermissionKeys', value: myPermissionKeys }, () => {
        this.setState({ loaded: true });
      });
    } catch (error) {
      console.error(error);
    }
  };

  private redirect = () => {
    window.location.href = urls.auth.login;
  };

  public render() {
    const { context } = this.props;
    const { loaded } = this.state;
    return (
      <Stack>
        {loaded && (
          <>
            <MenubarComponent />
            <Stack className={styles.container}>
              <Routes>
                <Route path={urls.home.index} element={<Dashboard />} />
                <Route path={urls.home.users.index} element={<UsersPage context={context} />} />
                <Route path={urls.home.flavours.index} element={<FlavoursPage context={context} />} />
                <Route path={urls.home.products.index} element={<ProductsPage context={context} />} />
                <Route path={urls.home.packagings.index} element={<PackagingsPage context={context} />} />
                <Route path={urls.home.productType.index} element={<ProductTypesPage />} />
                <Route
                  path={urls.home.deliveryMethods.index}
                  element={<DeliveryMethodPage context={context} />}
                />
                <Route path={urls.home.expenses.index} element={<ExpensesPage context={context} />} />
                <Route path={urls.home.closureDates.index} element={<ClosureDatesPage context={context} />} />
                <Route path={urls.home.vouchers.index} element={<VoucherPage context={context} />} />
                <Route path={urls.home.toppings.index} element={<ToppingPage context={context} />} />
                <Route path={urls.home.roles.index} element={<RolesPage context={context} />} />
                <Route path={urls.home.myProfile.index} element={<MyProfile context={context} />} />
                <Route path={urls.home.orders.index} element={<OrderPage context={context} />} />
                <Route path={urls.home.giftCards.index} element={<GiftCardsPage context={context} />} />
                <Route path={urls.home.events.index} element={<EventPage />} />
                <Route path={urls.home.eventFlavours.index} element={<EventFlavourPage />} />
                <Route path={urls.home.eventCodes.index} element={<EventCodePage />} />
                <Route path={urls.home.eventTransactions.index} element={<EventTransactionPage />} />
                <Route path={`${urls.home.events.index}/:id`} element={<EventDetailsPage />} />
                <Route path={`${urls.home.printBarcodes.index}/:id`} element={<PrintBarcodesPage />} />
                <Route path={urls.home.faqs.index} element={<FaqsPage context={context} />} />
              </Routes>
            </Stack>
          </>
        )}
      </Stack>
    );
  }
}
