import { EventCodeType, EventFlavourType, FileType } from '../../props/events';
import Request from './../request';

// import interfaces

const eventFlavourServices = {
  retrieve: async (): Promise<EventFlavourType[]> => {
    try {
      const results = await Request.get(`/api/events/eventID/flavours`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData, eventId: string): Promise<EventFlavourType[]> => {
    try {
      const results = await Request.post(`/api/events/${eventId}/flavours`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, eventId: string, id: string): Promise<EventFlavourType[]> => {
    try {
      const results = await Request.post(
        `/api/events/${eventId}/flavours/${id}?_method=PUT`,
        data,
        'multipart/form-data'
      );
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (eventId: string, id: string): Promise<EventFlavourType[]> => {
    try {
      const results = await Request.delete(`/api/events/${eventId}/flavours/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  images: {
    create: async (eventId: string, data: FormData): Promise<FileType[]> => {
      try {
        const results = await Request.post(
          `/api/eventFlavours/${eventId}/images`,
          data,
          'multipart/form-data'
        );
        return results;
      } catch (e) {
        throw e;
      }
    },
    delete: async (eventId: string, id: string): Promise<FileType> => {
      try {
        const results = await Request.delete(`/api/eventFlavours/${eventId}/images/${id}`);
        return results;
      } catch (e) {
        throw e;
      }
    },
  },
};

export default eventFlavourServices;
