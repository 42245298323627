import { Component } from 'react';

// import styles
import styles from '../styles.module.scss';

// import interfaces
import { IUserResourceShortProps } from '../../../../../props/user';
import { IconButton, Label, Stack } from '@fluentui/react';
import PanelUpdatePassword from '../surfaces/updatePassword';

type Props = {
  user: IUserResourceShortProps;
};

type State = {
  user: IUserResourceShortProps;
  panelUpdateEmail?: JSX.Element;
  panelUpdatePassword?: JSX.Element;
};

export default class UpdatePassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: props.user,
    };
  }
  render() {
    const { panelUpdatePassword } = this.state;
    return (
      <Stack className={styles.card} tokens={{ childrenGap: 10 }}>
        {panelUpdatePassword}

        <Stack horizontal wrap verticalAlign='center' horizontalAlign='space-between'>
          <Label>Password:-</Label>
          <IconButton
            iconProps={{ iconName: 'Edit' }}
            onClick={() => {
              this.setState({
                panelUpdatePassword: (
                  <PanelUpdatePassword
                    onDismiss={(user) => {
                      this.setState({ panelUpdatePassword: undefined });
                    }}
                  />
                ),
              });
            }}
          />
        </Stack>
      </Stack>
    );
  }
}
