import Request from './../request';
import { IProductResourceShort } from '../../props/products';
import { IPackagingResourceShort } from '../../props/packagings';

// import interfaces

const PackagingService = {
  retrieve: async (): Promise<IPackagingResourceShort[]> => {
    try {
      const results = await Request.get(`/api/packagings`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IPackagingResourceShort[]> => {
    try {
      const results = await Request.post(`/api/packagings`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, id: string): Promise<IPackagingResourceShort[]> => {
    try {
      const results = await Request.post(`/api/packagings/${id}?_method=PUT`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<IPackagingResourceShort[]> => {
    try {
      const results = await Request.delete(`/api/packagings/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  reorder: async (data: FormData) => {
    try {
      const result = await Request.post(`/api/reorderPackagings`, data, 'multipart/form-data');
      return result;
    } catch (error) {
      throw error;
    }
  },
};

export default PackagingService;
