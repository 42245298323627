import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  DetailsList,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services
import FlavourService from '../../../../services/flavours';

// import interfaces
import { IFlavourResourceShort } from '../../../../props/flavours';
import { ProviderStore } from '../../../../context';
import UpdatePassword from './subPages/password';
import UserService from '../../../../services/auth/user';
import { IUserResourceShortProps } from '../../../../props/user';
import UpdateEmail from './subPages/email';

interface IMyProfileProps {
  context: ProviderStore;
}

interface IMyProfileState {
  user?: IUserResourceShortProps;
}

export default class MyProfile extends React.Component<IMyProfileProps, IMyProfileState> {
  constructor(props: IMyProfileProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.getUser();
  }

  private getUser = async () => {
    try {
      const user = await UserService.currentUser();
      this.setState({ user });
    } catch (error) {}
  };

  public render() {
    const { user } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }} horizontal>
        {user && (
          <>
            <UpdatePassword user={user} />
            <UpdateEmail user={user} />
          </>
        )}
      </Stack>
    );
  }
}
