import React from 'react';
import 'antd/dist/antd.css';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import getBase64 from '../../utils/getBase64';

interface IPicturesWallProps {
  onUpdate: (fileList: UploadFile<any>[]) => void;
  fileList: UploadFile[];
  onRemoveFile?: (file: UploadFile<any>) => void;
  onAddFile?: (file: RcFile) => void;
}

interface IPicturesWallState {
  previewVisible: boolean;
  previewImage?: string;
  previewTitle?: string;
  fileList: UploadFile[];
}

export default class PicturesWall extends React.Component<IPicturesWallProps, IPicturesWallState> {
  constructor(props: IPicturesWallProps) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: props.fileList,
    };
  }

  private handleCancel = () => this.setState({ previewVisible: false });

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        <Upload
          listType='picture-card'
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          beforeUpload={this.beforeUpload}
          onRemove={(f) => {
            if (window.confirm(`Delete ${f.name}`)) {
              if (this.props.onRemoveFile) {
                this.props.onRemoveFile(f);
                return true;
              }
            } else {
              return false;
            }
          }}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handleCancel}>
          <img alt='example' style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  }

  private handleChange = ({ fileList }: UploadChangeParam<UploadFile<any>>) => {
    this.setState({ fileList }, this.onUpdate);
  };

  private handlePreview = async (file: UploadFile<unknown>) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file?.url || file?.preview,
      previewVisible: true,
      previewTitle: file?.name || file?.url?.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  private onUpdate = () => this.props.onUpdate(this.state.fileList);

  private beforeUpload = (file: RcFile, FileList: RcFile[]) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    if (isJpgOrPng && isLt2M) {
      this.onAddFile(file, FileList);
    }
    return isJpgOrPng && isLt2M;
  };

  private onAddFile = (file: RcFile, FileList: RcFile[]) => {
    if (this.props.onAddFile) {
      this.props.onAddFile(file);
    }
  };
}
