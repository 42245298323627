import * as React from 'react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';

// import ui
import {
  DefaultButton,
  Dropdown,
  IconButton,
  IDropdownOption,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';
import ProductTypeService from '../../../../../services/products/type';
import GeneralService from '../../../../../services/general';

// import component
import PicturesWall from '../components/uploadFile';
import MessageBarComponent from '../../../../../components/messageBar';
import LoadingComponent from '../../../../../components/loading';

// import interfaces
import { IProductTypeResourceShort } from '../../../../../props/products';
import { IRenderMessageBarProps } from '../../../../../props/general';

// import interfaces
import GeneralConfig from '../../../../../config';

import { GiftCardDataType } from './panelAddGiftCard';
import { IGiftCardResourceShort } from '../../../../../props/gift';
import GiftCardService from '../../../../../services/gift';

interface IUpdateGiftCardPanelProps {
  closePanel: (r?: boolean) => void;
  giftCard: IGiftCardResourceShort;
}

interface IUpdateGiftCardPanelState {
  giftCard: IGiftCardResourceShort;
  data?: GiftCardDataType;
  errorData?: GiftCardDataType;
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  submiting: boolean;
}

export default class UpdateGiftCardPanel extends React.Component<
  IUpdateGiftCardPanelProps,
  IUpdateGiftCardPanelState
> {
  constructor(props: IUpdateGiftCardPanelProps) {
    super(props);

    this.state = {
      giftCard: props.giftCard,
      data: {},
      submiting: false,
    };
  }

  public render() {
    const { data, errorData, messageBar, loaded } = this.state;
    return (
      <Panel
        headerText='Ubah Rasa'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        {!loaded ? (
          <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
        ) : null}
        {loaded && (
          <>
            <Stack.Item>
              <TextField
                label='Id'
                value={data?.id}
                errorMessage={errorData?.id}
                onChange={this.onChangeId}
                required
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                label='Nama'
                value={data?.amount}
                errorMessage={errorData?.amount}
                onChange={this.onChangeAmount}
                required
              />
            </Stack.Item>
          </>
        )}
      </Panel>
    );
  }

  private onRenderFooter = () => {
    const { submiting } = this.state;
    if (submiting) {
      return <LoadingComponent label={'Submiting'} />;
    } else {
      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
          <PrimaryButton text='Submit' onClick={this.onSubmit} />
        </Stack>
      );
    }
  };

  private onSubmit = async () => {
    const { data } = this.state;
    if (data?.amount && data.id) {
      this.setState({ submiting: true });
      const fd = new FormData();
      fd.append('amount', data.amount);
      fd.append('id', data.id);
      try {
        await GiftCardService.update(fd, this.props.giftCard.id);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
          submiting: false,
        });
      }
    } else {
      this.setState({
        messageBar: {
          type: MessageBarType.warning,
          text: 'Kolom nama tidak boleh kosong',
        },
      });
    }
  };

  private onChangeAmount = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 11 }).message;
    this.setState({
      data: { ...this.state.data, amount: v },
      errorData: { ...this.state.errorData, amount: err },
    });
  };

  private onChangeId = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, id: v },
      errorData: { ...this.state.errorData, id: err },
    });
  };
}
