import React from 'react';
import { v4 as uuidv4 } from 'uuid';

// import ui
import {
  Checkbox,
  ComboBox,
  DefaultButton,
  DetailsList,
  IComboBoxOption,
  IconButton,
  Label,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';

// import services
import FlavourService from '../../../../../services/flavours';

// import interfaces
import { IFlavourResourceShort } from '../../../../../props/flavours';
import { DataType } from '../surfaces/panelAddProduct';

interface IFlavoursProps {
  onUpdateDataFlavour(rs: DataType[]): void;
  maxQuantity: number;
  flavoursData: DataType[];
}

interface IFlavoursState {
  flavours: IFlavourResourceShort[];
  maxQuantity: number;
  loaded: boolean;
  flavoursData: DataType[];
}

export default class Flavours extends React.Component<IFlavoursProps, IFlavoursState> {
  constructor(props: IFlavoursProps) {
    super(props);
    this.state = {
      loaded: false,
      flavours: [],
      flavoursData:
        props.flavoursData.length !== 0 ? props.flavoursData : [{ qty: 0, key: '-', replaceable: false }],
      maxQuantity: props.maxQuantity,
    };
  }

  static getDerivedStateFromProps(props: IFlavoursProps, state: IFlavoursState) {
    return {
      maxQuantity: props.maxQuantity,
    };
  }

  componentDidMount() {
    this.getFlavours();
  }

  private getFlavours = async () => {
    try {
      const flavours = await FlavourService.retrieve();
      this.setState({ flavours, loaded: true });
    } catch (error) {}
  };

  render() {
    const { flavoursData } = this.state;
    return (
      <>
        <Label>Default rasa</Label>
        <DetailsList selectionMode={SelectionMode.none} items={flavoursData} columns={this.columns()} />
      </>
    );
  }

  private onChangeFlavour = (
    flavours: IFlavourResourceShort[],
    option: IComboBoxOption | undefined,
    flavoursData: DataType[],
    item: DataType
  ) => {
    const flavour = flavours.find((f) => f.id === option?.key);
    const ix = flavoursData.findIndex((fd) => fd.key === item.key);
    flavoursData[ix].flavour = flavour;
    this.setState({ flavoursData }, () => {
      if (flavoursData.length - 1 === ix) {
        this.onUpdateFlavours();
      }
    });
  };

  private columns = () => {
    const { flavours, flavoursData, maxQuantity } = this.state;
    return [
      {
        key: 'no',
        name: 'No',
        maxWidth: 20,
        minWidth: 20,
        isMultiline: true,
        onRender: (item: DataType, ix?: number) =>
          item?.key !== '-' && (
            <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
              <Text>{(ix || 0) + 1}</Text>
            </Stack>
          ),
      },
      {
        key: 'name',
        name: 'Name',
        minWidth: 100,
        isMultiline: true,
        onRender: (item: DataType, ix?: number) =>
          item?.key !== '-' ? (
            <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
              <ComboBox
                allowFreeform={true}
                autoComplete={'on'}
                options={flavours.map((f) => ({ key: f.id, text: f.name }))}
                key={ix}
                selectedKey={item.flavour?.id}
                onChange={(event, option, index, value) =>
                  this.onChangeFlavour(flavours, option, flavoursData, item)
                }
              />
            </Stack>
          ) : (
            <Stack>
              <DefaultButton
                disabled={flavoursData.length - 1 >= maxQuantity}
                text='Tambah'
                onClick={this.onAddFlavour}
              />
            </Stack>
          ),
      },
      {
        key: 'qty',
        name: 'Qty',
        minWidth: 100,
        isMultiline: true,
        onRender: (item: DataType, ix?: number) =>
          item?.key !== '-' && (
            <Stack
              horizontal
              styles={{ root: { width: '100%' } }}
              verticalAlign='center'
              tokens={{ childrenGap: 5 }}
            >
              <IconButton
                iconProps={{ iconName: 'MinusCircle' }}
                disabled={item.qty <= 0}
                onClick={() => this.onMinFlavour(flavoursData, item)}
              />
              <Text>{item.qty}</Text>
              <IconButton
                iconProps={{ iconName: 'PlusCircle' }}
                onClick={() => this.onPlusFlavour(flavoursData, item)}
              />
            </Stack>
          ),
      },
      {
        key: 'replaceable',
        name: 'Replaceable',
        minWidth: 100,
        isMultiline: true,
        onRender: (item: DataType, ix?: number) =>
          item?.key !== '-' && (
            <Stack
              horizontal
              styles={{ root: { width: '100%' } }}
              verticalAlign='center'
              tokens={{ childrenGap: 5 }}
            >
              <Checkbox
                checked={item.replaceable}
                onChange={(e, c) => {
                  this.onChangeCheckbox(flavoursData, item);
                }}
              />
            </Stack>
          ),
      },
      {
        key: 'action',
        name: 'Delete',
        minWidth: 50,
        isMultiline: true,
        onRender: (item: DataType, ix?: number) =>
          item?.key !== '-' && (
            <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
              <IconButton
                iconProps={{ iconName: 'TrashIcon' }}
                onClick={() => {
                  this.onDeleteFalvourData(item);
                }}
                disabled={flavoursData.length === 0}
              />
            </Stack>
          ),
      },
    ];
  };

  private onChangeCheckbox = (flavoursData: DataType[], item: DataType) => {
    const r = flavoursData[flavoursData.findIndex((f) => f.key === item.key)];
    r.replaceable = !r.replaceable;
    this.setState({ flavoursData }, this.onUpdateFlavours);
  };

  private onDeleteFalvourData(item: DataType) {
    const { flavoursData } = this.state;
    if (flavoursData.length > 1) {
      this.setState(
        { flavoursData: flavoursData.filter((fd) => fd.key !== item.key) },
        this.onUpdateFlavours
      );
    }
  }

  private onMinFlavour(flavoursData: DataType[], item: DataType) {
    const ix = flavoursData.findIndex((fd) => fd.key === item.key);
    flavoursData[ix].qty -= 1;
    this.setState({ flavoursData }, this.onUpdateFlavours);
  }

  private onPlusFlavour(flavoursData: DataType[], item: DataType) {
    const ix = flavoursData.findIndex((fd) => fd.key === item.key);
    flavoursData[ix].qty += 1;
    this.setState({ flavoursData }, this.onUpdateFlavours);
  }

  private onUpdateFlavours = () => {
    const { onUpdateDataFlavour } = this.props;
    const { flavoursData } = this.state;
    onUpdateDataFlavour(flavoursData.filter((f) => f.flavour !== undefined));
  };

  private onAddFlavour = () => {
    const { flavoursData } = this.state;
    flavoursData[flavoursData.findIndex((f) => f.key === '-')].key = uuidv4();
    flavoursData.push({ qty: 0, key: '-', replaceable: false });
    this.setState({ flavoursData }, this.onUpdateFlavours);
  };
}
