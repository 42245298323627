import Request from './../request';
import { IClosureDateResourceShort } from '../../props/dates';
import { IToppingResourceShort } from '../../props/toppings';

// import interfaces

const ToppingServices = {
  retrieve: async (): Promise<IToppingResourceShort[]> => {
    try {
      const results = await Request.get(`/api/toppings`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IToppingResourceShort[]> => {
    try {
      const results = await Request.post(`/api/toppings`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, id: string): Promise<IToppingResourceShort[]> => {
    try {
      const results = await Request.post(`/api/toppings/${id}?_method=PUT`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<IToppingResourceShort[]> => {
    try {
      const results = await Request.delete(`/api/toppings/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  reorder: async (data: FormData) => {
    try {
      const result = await Request.post(`/api/reorderToppings`, data, 'multipart/form-data');
      return result;
    } catch (error) {
      throw error;
    }
  },
};

export default ToppingServices;
