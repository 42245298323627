import * as React from 'react';

import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  MessageBarType,
  Label,
} from '@fluentui/react';
import validationService from '../../../../../services/validation';
import ProductTypeService, { ProductTypeImageService } from '../../../../../services/products/type';
import { IProductTypeResourceShort } from '../../../../../props/products';
import LoadingComponent from '../../../../../components/loading';
import { IRenderMessageBarProps } from '../../../../../props/general';
import MessageBarComponent from '../../../../../components/messageBar';
import GeneralService from '../../../../../services/general';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import GeneralConfig from '../../../../../config';
import PicturesWall from '../components/uploadFile';

// import services

interface IUpdateProductTypePanelProps {
  did: string;
  closePanel: (refresh?: boolean, messageBar?: IRenderMessageBarProps) => void;
}

interface IUpdateProductTypePanelState {
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  submitting?: boolean;
  data: Partial<IProductTypeResourceShort>;
  errorData?: {
    name?: string;
  };
  files: UploadFile<any>[];
}

export default class UpdateProductTypePanel extends React.Component<
  IUpdateProductTypePanelProps,
  IUpdateProductTypePanelState
> {
  constructor(props: IUpdateProductTypePanelProps) {
    super(props);

    this.state = {
      data: {
        name: '',
      },
      files: [],
    };
  }

  public componentDidMount = async () => {
    try {
      const data = await ProductTypeService.get(this.props.did);
      this.setState({
        data,
        loaded: true,
      });
    } catch (e) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(e),
        },
      });
    }
  };

  public render() {
    const { data, messageBar, loaded, errorData, submitting } = this.state;
    return (
      <Panel
        headerText='Tambah Tipe Produk'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <Stack tokens={{ childrenGap: 10 }}>
          {messageBar ? <MessageBarComponent {...messageBar} /> : null}
          {!loaded ? (
            <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
          ) : null}
          {loaded ? (
            <>
              {data.images && (
                <Stack styles={{ root: { marginTop: 10 } }}>
                  <Label>Gambar Packaging</Label>
                  <PicturesWall
                    fileList={data.images.map((f) => ({
                      uid: f.id,
                      name: f.name,
                      status: 'done',
                      url: GeneralConfig.assetsHostname + '/' + f.url,
                    }))}
                    onUpdate={(files) => this.setState({ files })}
                    onRemoveFile={this.onRemoveFile}
                    onAddFile={this.onAddFile}
                  />
                </Stack>
              )}
              <TextField
                required
                disabled={submitting}
                label='Name'
                value={data?.name}
                errorMessage={errorData?.name}
                onChange={this.onChangeName}
              />
            </>
          ) : null}
        </Stack>
      </Panel>
    );
  }

  private onAddFile = async (file: RcFile) => {
    let fd = new FormData();
    fd.append('file', file);
    try {
      await ProductTypeImageService.create(this.props.did, fd);
    } catch (error) {}
  };

  private onRemoveFile = async (file: UploadFile<any>) => {
    try {
      await ProductTypeImageService.delete(this.props.did, file.uid);
    } catch (error) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(error),
        },
      });
    }
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        {!this.state.submitting ? (
          <>
            <PrimaryButton text='Ubah' disabled={this.isSubmitButtonDisabled()} onClick={this.onSubmit} />
            <DefaultButton text='Batal' onClick={() => this.props.closePanel()} />
          </>
        ) : null}
        {this.state.submitting ? (
          <LoadingComponent
            label='Menambahkan tipe produk ...'
            labelPosition='right'
            spinnerPosition='baseline'
          />
        ) : null}
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, errorData } = this.state;
    const fd = new FormData();
    fd.append('name', data.name || '');

    try {
      this.setState({ submitting: true });
      await ProductTypeService.update(fd, this.props.did);
      this.props.closePanel(true, {
        type: MessageBarType.success,
        text: `Tipe produk ${data.name || ''} berhasil ditambahkan.`,
      });
    } catch (error) {}
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 125 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private isSubmitButtonDisabled = () => {
    const { data, errorData } = this.state;
    if (data.name?.trim() === '' || (errorData && errorData.name)) {
      return true;
    }

    return false;
  };
}
