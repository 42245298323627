import React from 'react';

import {
  CommandBar,
  CommandBarButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

import AddGiftCardPanel from './surfaces/panelAddGiftCard';
import { ProviderStore } from '../../../../context';
import GiftCardService from '../../../../services/gift';
import { IGiftCardResourceShort } from '../../../../props/gift';
import UpdateGiftCardPanel from './surfaces/panelUpdateFlavour';

interface IGiftCardsPageProps {
  context: ProviderStore;
}

interface IGiftCardsPageState {
  giftCards: IGiftCardResourceShort[];
  loaded: boolean;
  addGiftCardDialog?: JSX.Element;
  updateGiftCardDialog?: JSX.Element;
}

export default class GiftCardsPage extends React.Component<IGiftCardsPageProps, IGiftCardsPageState> {
  constructor(props: IGiftCardsPageProps) {
    super(props);

    this.state = {
      giftCards: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getGiftCards();
  }

  private getGiftCards = async () => {
    this.setState({ loaded: false });
    try {
      const giftCards = await GiftCardService.retrieve();
      this.setState({ giftCards, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { giftCards, loaded, addGiftCardDialog, updateGiftCardDialog } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addGiftCardDialog}
        {updateGiftCardDialog}
        <Text variant={'xLarge'}>Gift Cards</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={giftCards}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: IGiftCardResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                </Stack>
              ),
            },
            {
              key: 'id',
              name: 'Id',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IGiftCardResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{item.id}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () => {
                            this.setState({
                              updateGiftCardDialog: (
                                <UpdateGiftCardPanel
                                  closePanel={(r) => {
                                    this.setState({ updateGiftCardDialog: undefined });
                                    if (r) {
                                      this.getGiftCards();
                                    }
                                  }}
                                  giftCard={item}
                                />
                              ),
                            });
                          },
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {},
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
            {
              key: 'amount',
              name: 'Total',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IGiftCardResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{item.amount}</Text>
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Tambah Gift Card Baru'
              styles={{ root: { marginLeft: 10 } }}
              onClick={this.showDialog}
            />
          );
        },
      },
    ];
  };

  private showDialog = () =>
    this.setState({
      addGiftCardDialog: (
        <AddGiftCardPanel
          closePanel={(r) => {
            this.setState({ addGiftCardDialog: undefined });
            if (r) {
              this.getGiftCards();
            }
          }}
        />
      ),
    });

  private deleteGiftCard = async (item: IGiftCardResourceShort) => {
    if (window.confirm('Delete GiftCard')) {
      try {
        await GiftCardService.delete(item.id);
        this.getGiftCards();
      } catch (error) {}
    }
  };
}
