import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services

// import interfaces
import AddRolesPanel from './surfaces/panelAddRole';
import UpdateRolesPanel from './surfaces/panelUpdateRole';
import { IRoleResourceShort } from '../../../../props/roles';
import RoleServices from '../../../../services/roles';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';

// import component

interface IRolesPageProps {
  context: ProviderStore;
}

interface IRolesPageState {
  roles: IRoleResourceShort[];
  loaded: boolean;
  addRolesDialog?: JSX.Element;
  updateRolesDialog?: JSX.Element;
}

export default class RolesPage extends React.Component<IRolesPageProps, IRolesPageState> {
  constructor(props: IRolesPageProps) {
    super(props);

    this.state = {
      roles: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getRoles();
  }

  private getRoles = async () => {
    this.setState({ loaded: false });
    try {
      const roles = await RoleServices.retrieve();
      this.setState({ roles, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { roles, loaded, addRolesDialog, updateRolesDialog } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addRolesDialog}
        {updateRolesDialog}
        <Text variant={'xLarge'}>Roles</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={roles}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: IRoleResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                </Stack>
              ),
            },
            {
              key: 'name',
              name: 'Name',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IRoleResourceShort) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  horizontalAlign='space-between'
                >
                  <Text>{item.name}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () => {
                            this.setState({
                              updateRolesDialog: (
                                <UpdateRolesPanel
                                  closePanel={(f) => {
                                    this.setState({ updateRolesDialog: undefined });
                                    if (f) {
                                      this.getRoles();
                                    }
                                  }}
                                  role={item}
                                />
                              ),
                            });
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'update_role',
                          ]),
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {
                            this.deleteRoles(item);
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'delete_role',
                          ]),
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Add Roles'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() => {
                this.setState({
                  addRolesDialog: (
                    <AddRolesPanel
                      closePanel={(f) => {
                        this.setState({ addRolesDialog: undefined });
                        if (f) {
                          this.getRoles();
                        }
                      }}
                    />
                  ),
                });
              }}
            />
          );
        },
      },
    ];
  };

  private deleteRoles = async (item: IRoleResourceShort) => {
    if (window.confirm('Delete Packaging')) {
      try {
        await RoleServices.delete(item.key);
        this.getRoles();
      } catch (error) {}
    }
  };
}
