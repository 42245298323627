import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services

// import interfaces
import AddVoucherPanel from './surfaces/panelAddVoucher';
import VoucherService from '../../../../services/vouchers';
import { IVoucherResourceShort } from '../../../../props/vouchers';
import UpdateVoucherPanel from './surfaces/panelUpdateVoucher';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';

// import component

interface IVoucherPageProps {
  context: ProviderStore;
}

interface IVoucherPageState {
  loaded: boolean;
  addVoucherDialog?: JSX.Element;
  updateVoucherDialog?: JSX.Element;
  vouchers: IVoucherResourceShort[];
}

export default class VoucherPage extends React.Component<IVoucherPageProps, IVoucherPageState> {
  constructor(props: IVoucherPageProps) {
    super(props);

    this.state = {
      loaded: false,
      vouchers: [],
    };
  }

  componentDidMount() {
    this.getVoucher();
  }

  private getVoucher = async () => {
    this.setState({ loaded: false });
    try {
      const vouchers = await VoucherService.retrieve();
      this.setState({ vouchers, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { vouchers, loaded, addVoucherDialog, updateVoucherDialog } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addVoucherDialog}
        {updateVoucherDialog}
        <Text variant={'xLarge'}>Voucher</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={vouchers}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: IVoucherResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                </Stack>
              ),
            },
            {
              key: 'code',
              name: 'Kode',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IVoucherResourceShort) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  horizontalAlign='space-between'
                >
                  <Text>{item.code}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () => {
                            this.setState({
                              updateVoucherDialog: (
                                <UpdateVoucherPanel
                                  closePanel={(r) => {
                                    if (r) {
                                      this.getVoucher();
                                    }
                                  }}
                                  voucher={item}
                                />
                              ),
                            });
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'update_voucher',
                          ]),
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {
                            this.deleteVoucher(item);
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'delete_voucher',
                          ]),
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Add Voucher'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() => {
                this.setState({
                  addVoucherDialog: (
                    <AddVoucherPanel
                      closePanel={(f) => {
                        this.setState({ addVoucherDialog: undefined });
                        if (f) {
                          this.getVoucher();
                        }
                      }}
                    />
                  ),
                });
              }}
            />
          );
        },
      },
    ];
  };

  private deleteVoucher = async (item: IVoucherResourceShort) => {
    if (window.confirm('Delete Packaging')) {
      try {
        await VoucherService.delete(item.id);
        this.getVoucher();
      } catch (error) {}
    }
  };
}
