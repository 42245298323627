import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  DefaultButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services
import ProductService from '../../../../services/products';

// import interfaces
import { IProductResourceShort } from '../../../../props/products';

// import component
import AddPackagingPanel from './surfaces/panelAddPackaging';
import UpdatePackagingPanel from './surfaces/panelUpdatePackaging';
import GeneralConfig from '../../../../config';
import PackagingService from '../../../../services/packagings';
import { IPackagingResourceShort } from '../../../../props/packagings';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';
import SortPackagingPanel from './surfaces/panelSortPackaging';

interface IPackagingsPageProps {
  context: ProviderStore;
}

interface IPackagingsPageState {
  packagings: IPackagingResourceShort[];
  loaded: boolean;
  addPackagingDialog?: JSX.Element;
  updatePackagingDialog?: JSX.Element;
  panelSortPackaging?: JSX.Element;
}

export default class PackagingsPage extends React.Component<IPackagingsPageProps, IPackagingsPageState> {
  constructor(props: IPackagingsPageProps) {
    super(props);

    this.state = {
      packagings: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getPackagings();
  }

  private getPackagings = async () => {
    this.setState({ loaded: false });
    try {
      const packagings = await PackagingService.retrieve();
      this.setState({ packagings, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { packagings, loaded, addPackagingDialog, updatePackagingDialog, panelSortPackaging } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addPackagingDialog}
        {updatePackagingDialog}
        {panelSortPackaging}
        <Text variant={'xLarge'}>Packagings</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={packagings}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: IPackagingResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                </Stack>
              ),
            },
            {
              key: 'image',
              name: 'Image',
              maxWidth: 300,
              minWidth: 300,
              isMultiline: true,
              onRender: (item: IPackagingResourceShort, ix) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  tokens={{ childrenGap: 10 }}
                  wrap
                >
                  {item.images.map((i) => (
                    <img
                      key={i.id}
                      src={GeneralConfig.assetsHostname + '/' + i.url}
                      style={{ height: 60, width: 60 }}
                    />
                  ))}
                </Stack>
              ),
            },
            {
              key: 'name',
              name: 'Name',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IPackagingResourceShort) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  horizontalAlign='space-between'
                >
                  <Text>{item.name}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () => {
                            this.setState({
                              updatePackagingDialog: (
                                <UpdatePackagingPanel
                                  closePanel={(r) => {
                                    this.setState({ updatePackagingDialog: undefined });
                                    if (r) {
                                      this.getPackagings();
                                    }
                                  }}
                                  packaging={item}
                                />
                              ),
                            });
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'update_packaging',
                          ]),
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {
                            this.deletePackaging(item);
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'delete_packaging',
                          ]),
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
            {
              key: 'name',
              name: 'Name',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IPackagingResourceShort) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  horizontalAlign='space-between'
                >
                  <Text>{item.additionalPrice}</Text>
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Add Packaging'
              styles={{ root: { marginLeft: 10 } }}
              onClick={this.showDialog}
            />
          );
        },
      },
      {
        key: 'sort_toppings',
        text: 'Sort Packagings',
        onRender: () => {
          return (
            <DefaultButton
              disabled={!loaded}
              iconProps={{ iconName: 'ArrowDownUp' }}
              text='Ubah Urutan Packaging'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() =>
                this.setState({
                  panelSortPackaging: (
                    <SortPackagingPanel
                      closePanel={(r) => {
                        this.setState({ panelSortPackaging: undefined });
                        if (r) {
                          this.getPackagings();
                        }
                      }}
                    />
                  ),
                })
              }
            />
          );
        },
      },
    ];
  };

  private showDialog = () =>
    this.setState({
      addPackagingDialog: (
        <AddPackagingPanel
          closePanel={(r) => {
            this.setState({ addPackagingDialog: undefined });
            if (r) {
              this.getPackagings();
            }
          }}
          orderNumber={
            this.state.packagings.length !== 0
              ? Number(this.state.packagings[this.state.packagings.length - 1].orderNumber) + 1
              : 1
          }
        />
      ),
    });

  private deletePackaging = async (item: IPackagingResourceShort) => {
    if (window.confirm('Delete Packaging')) {
      try {
        await PackagingService.delete(item.id);
        this.getPackagings();
      } catch (error) {}
    }
  };
}
