import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services

// import interfaces
import { IClosureDateResourceShort } from '../../../../props/dates';
import ClosureDateServices from '../../../../services/closureDate';
import GeneralConfig from '../../../../config';
import AddClosureDatePanel from './surfaces/panelAddClosureDate';
import UpdateClosureDatePanel from './surfaces/panelUpdateClosureDate';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';

interface IClosureDatesPageProps {
  context: ProviderStore;
}

interface IClosureDatesPageState {
  closureDates: IClosureDateResourceShort[];
  loaded: boolean;
  addClosureDateDialog?: JSX.Element;
  updateClosureDateDialog?: JSX.Element;
}

export default class ClosureDatesPage extends React.Component<
  IClosureDatesPageProps,
  IClosureDatesPageState
> {
  constructor(props: IClosureDatesPageProps) {
    super(props);

    this.state = {
      closureDates: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getClosureDates();
  }

  private getClosureDates = async () => {
    this.setState({ loaded: false });
    try {
      const closureDates = await ClosureDateServices.retrieve();
      this.setState({ closureDates, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { closureDates, loaded, addClosureDateDialog, updateClosureDateDialog } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addClosureDateDialog}
        {updateClosureDateDialog}
        <Text variant={'xLarge'}>Closure Dates</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={closureDates}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: IClosureDateResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () =>
                            this.setState({
                              updateClosureDateDialog: (
                                <UpdateClosureDatePanel
                                  closePanel={(r) => {
                                    this.setState({ updateClosureDateDialog: undefined });
                                    if (r) {
                                      this.getClosureDates();
                                    }
                                  }}
                                  closureDate={item}
                                />
                              ),
                            }),
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {
                            this.deleteClosureDate(item);
                          },
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
            {
              key: 'image',
              name: 'Image',
              maxWidth: 300,
              minWidth: 300,
              isMultiline: true,
              onRender: (item: IClosureDateResourceShort, ix) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  tokens={{ childrenGap: 10 }}
                  wrap
                >
                  {item.images.map((i) => (
                    <img
                      key={i.id}
                      src={GeneralConfig.assetsHostname + '/' + i.url}
                      style={{ height: 60, width: 60 }}
                    />
                  ))}
                </Stack>
              ),
            },

            {
              key: 'description',
              name: 'Description',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IClosureDateResourceShort) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{item.description}</Text>
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={
                !loaded ||
                !checkPermission(this.props.context.globalState.myPermissionKeys, ['create_closure_date'])
              }
              iconProps={{ iconName: 'Add' }}
              text='Tambah Tanggal Baru'
              styles={{ root: { marginLeft: 10 } }}
              onClick={this.showDialog}
            />
          );
        },
      },
    ];
  };

  private showDialog = () =>
    this.setState({
      addClosureDateDialog: (
        <AddClosureDatePanel
          closePanel={(r) => {
            this.setState({ addClosureDateDialog: undefined });
            if (r) {
              this.getClosureDates();
            }
          }}
        />
      ),
    });

  private deleteClosureDate = async (item: IClosureDateResourceShort) => {
    if (checkPermission(this.props.context.globalState.myPermissionKeys, ['delete_closure_date'])) {
      if (window.confirm('Delete ClosureDate')) {
        try {
          await ClosureDateServices.delete(item.id);
          this.getClosureDates();
        } catch (error) {}
      }
    }
  };
}
