import * as React from 'react';

// inport ui
import { PrimaryButton, Spinner, Stack, Text, TextField } from '@fluentui/react';
import AuthenticationService from '../../../../services/auth/user';
import GeneralConfig from '../../../../config';
import urls from '../../../../configs/urls.json';

interface ILoginPageProps {}

interface ILoginPageState {
  username?: string;
  password?: string;
  submitting: boolean;
}

export default class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {
  constructor(props: ILoginPageProps) {
    super(props);

    this.state = {
      submitting: false,
    };
  }

  public render() {
    const { username, password, submitting } = this.state;
    return (
      <Stack styles={{ root: { width: '100%' } }} tokens={{ childrenGap: 20 }}>
        <Stack tokens={{childrenGap: 2}}>
          <Text as={"h2"} variant={'xLarge'}>Selamat Datang di Loure</Text>
          <Text>Gunakan username dan password untuk login ke portal administrasi Loure Gelateria.</Text>
        </Stack>
        <Stack tokens={{childrenGap:8}}>
          <Stack.Item>
            <TextField
              label='Username'
              value={username}
              onChange={(e, v) => {
                this.setState({ username: v });
              }}
              disabled={submitting}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label='Password'
              value={password}
              type='password'
              onChange={(e, v) => {
                this.setState({ password: v });
              }}
              disabled={submitting}
            />
          </Stack.Item>
        </Stack>
        <Stack>
          {!submitting ? <PrimaryButton text='MASUK' onClick={this.onSubmit} /> : null}
          {submitting ? <Spinner /> : null}
        </Stack>
      </Stack>
    );
  }

  public onSubmit = async () => {
    const { username, password } = this.state;
    if (username && password) {
      try {
        this.setState({submitting: true});
        await AuthenticationService.login(username, password);
        window.location.href = urls.home.index;
      } catch (error) {}
    }
  };
}
