import * as React from 'react';
import { UploadFile } from 'antd/lib/upload/interface';

// import ui
import {
  DatePicker,
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';

// import component

// import interfaces
import { IDeliveryMethodResourceShort } from '../../../../../props/deliveries';
import { DataInputDeliveryMethodType } from './panelAddDeliveryMethod';

// import config
import DeliveryMethodService from '../../../../../services/deliveries';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import MessageBarComponent from '../../../../../components/messageBar';

interface IUpdateDeliveryMethodPanelProps {
  closePanel: (r?: boolean) => void;
  deliveryMethod: IDeliveryMethodResourceShort;
}

interface IUpdateDeliveryMethodPanelState {
  deliveryMethod: IDeliveryMethodResourceShort;
  data?: DataInputDeliveryMethodType;
  errorData?: DataInputDeliveryMethodType;
  files: UploadFile<any>[];
  messageBar?: IRenderMessageBarProps;
}

export default class UpdateDeliveryMethodPanel extends React.Component<
  IUpdateDeliveryMethodPanelProps,
  IUpdateDeliveryMethodPanelState
> {
  constructor(props: IUpdateDeliveryMethodPanelProps) {
    super(props);

    this.state = {
      files: [],
      deliveryMethod: props.deliveryMethod,
      data: {
        name: props.deliveryMethod.name,
        price: props.deliveryMethod.price,
        startTime: new Date(props.deliveryMethod.startTime),
        endTime: new Date(props.deliveryMethod.endTime),
      },
    };
  }

  public render() {
    const { data, errorData, messageBar } = this.state;
    return (
      <Panel
        headerText='Update DeliveryMethod'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <TextField
          label='Name'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={this.onChangeName}
        />
        <TextField
          label='Price'
          value={data?.price}
          errorMessage={errorData?.price}
          onChange={this.onChangePrice}
        />
        <DatePicker label='Start Time' value={data?.startTime} onSelectDate={this.onChangeStartTime} />
        <DatePicker label='End Time' value={data?.endTime} onSelectDate={this.onChangeEndTime} />
      </Panel>
    );
  }

  private onChangeStartTime = (date: Date | null | undefined) => {
    if (date) {
      this.setState({ data: { ...this.state.data, startTime: date } });
    }
  };

  private onChangeEndTime = (date: Date | null | undefined) => {
    if (date) {
      this.setState({ data: { ...this.state.data, endTime: date } });
    }
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { deliveryMethod } = this.props;
    const { data, errorData } = this.state;
    if (!errorData?.name) {
      const fd = new FormData();
      data?.name && fd.append('name', data.name);
      data?.startTime && fd.append('startTime', data.startTime.toISOString());
      data?.endTime && fd.append('endTime', data.endTime.toISOString());
      data?.price && fd.append('price', data.price);
      try {
        await DeliveryMethodService.update(fd, deliveryMethod.id);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChangePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit', 'number'], { maxChars: 6 }).message;
    this.setState({
      data: { ...this.state.data, price: v },
      errorData: { ...this.state.errorData, price: err },
    });
  };
}
