import { Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { EventCodeType, EventFlavourType, EventType } from '../../../../props/events';
import eventServices from '../../../../services/events';
import Header from '../../../../components/header';
import LoadingComponent from '../../../../components/loading';
import EventDetails from '../../../../components/events/details';
import UpdateEventPanel from './surfaces/updateEvent';
import EventFlavourPage from '../eventFlavour';
import EventCodePage from '../eventCodes';
import UpdateEventCardTemplatePanel from './surfaces/updateEventImage';
import urls from './../../../../configs/urls.json';
import PrintBarcodePanel from '../../../../components/printBarcode';

type PivotKeyType = 'flavours' | 'codes';

type EventDetailsPageProps = {
  children?: React.ReactNode;
};

const EventDetailsPage: React.FC<EventDetailsPageProps> = (props) => {
  const currentURL = window.location.href;
  const urlParts = currentURL.split('/');
  const id = urlParts[urlParts.length - 1];
  const [eventId, setEventId] = useState<string | undefined>(id);
  const [event, setEvent] = useState<EventType | undefined>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [updateEventPanel, setUpdateEventPanel] = useState<JSX.Element>();
  const [updateImagePanel, setUpdateImagePanel] = useState<JSX.Element | undefined>();
  const [selectedPivotItem, setSelectedPivotItem] = useState<PivotKeyType>('flavours');
  const [flavours, setFlavours] = useState<EventFlavourType[]>();
  const [codes, setCodes] = useState<EventCodeType[]>();

  useEffect(() => {
    if (eventId !== undefined) {
      getEvent(eventId);
    }
    return () => {};
  }, [eventId]);

  const getEvent = async (id: string) => {
    setLoaded(false);
    try {
      const ev = await eventServices.get(id);
      setLoaded(true);
      setFlavours(ev.flavours);
      setCodes(ev.codes);
      setEvent(ev);
    } catch (error) {}
  };

  const onDeleteItem = async (event: EventType) => {
    if (window.confirm(`Hapus ${event.name}`))
      try {
        await eventServices.delete(event.code);
        getEvent(eventId || '');
      } catch (error) {}
  };

  const onUpdateItem = (event: EventType) => {
    setUpdateEventPanel(
      <UpdateEventPanel
        closePanel={(refresh?: boolean | undefined) => {
          if (refresh) {
            getEvent(eventId || '');
          }
          setUpdateEventPanel(undefined);
        }}
        event={event}
      />
    );
  };

  const onLinkClick = (
    item?: PivotItem | undefined,
    ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => {
    const key = item?.props.itemKey;
    if (key) {
      setSelectedPivotItem(key as PivotKeyType);
    }
  };

  const onUpdateImage = (event: EventType) => {
    setUpdateImagePanel(
      <UpdateEventCardTemplatePanel
        closePanel={(refresh?: boolean | undefined) => {
          if (refresh) {
            getEvent(eventId || '');
          }
          setUpdateImagePanel(undefined);
        }}
        event={event}
      />
    );
  };

  return (
    <Stack>
      {updateEventPanel}
      {updateImagePanel}
      {event && (
        <Header
          title={'Details Event'}
          breadcrumbs={[
            { key: 'home', text: 'Beranda', href: '/' },
            { key: 'events', text: 'Event', href: '/events' },
            { key: 'eventId', text: `${event.name}`, href: `/events/${eventId}` },
          ]}
        />
      )}
      <Stack tokens={{ childrenGap: 20 }}>
        {!loaded && <LoadingComponent label={'Memuat Data'} />}
        {loaded && event && (
          <EventDetails
            event={event}
            onDelete={onDeleteItem}
            onUpdate={onUpdateItem}
            onUpdateImage={onUpdateImage}
            onPrintBarcodes={(event: EventType) => {
              window.location.href = urls.home.printBarcodes.index + '/' + event.code;
            }}
          />
        )}
        {loaded && event && flavours && (
          <Stack className={styles.detailsPivot}>
            <Pivot
              aria-label='Detail order pivot'
              className={'linePivot'}
              selectedKey={selectedPivotItem}
              onLinkClick={onLinkClick}
            >
              <PivotItem headerText='Rasa' itemKey={'flavours'}>
                <Stack styles={{ root: { padding: 20 } }}>
                  <EventFlavourPage event={event} eventFlavours={flavours} />
                </Stack>
              </PivotItem>
              <PivotItem headerText='Kode' itemKey={'codes'}>
                <Stack styles={{ root: { padding: 20 } }}>
                  <EventCodePage event={event} eventCodes={codes} />
                </Stack>
              </PivotItem>
            </Pivot>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default EventDetailsPage;
