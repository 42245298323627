import Request from './../request';
import { IClosureDateResourceShort } from '../../props/dates';

// import interfaces

const ClosureDateServices = {
  retrieve: async (): Promise<IClosureDateResourceShort[]> => {
    try {
      const results = await Request.get(`/api/closureDates`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IClosureDateResourceShort[]> => {
    try {
      const results = await Request.post(`/api/closureDates`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, id: string): Promise<IClosureDateResourceShort[]> => {
    try {
      const results = await Request.post(`/api/closureDates/${id}?_method=PUT`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<IClosureDateResourceShort[]> => {
    try {
      const results = await Request.delete(`/api/closureDates/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default ClosureDateServices;
