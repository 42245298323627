import { EventTransactionProps } from '../../props/events';
import Request from './../request';

// import interfaces

const eventTransactionService = {
  retrieve: async (eventId: string, qs?: string): Promise<EventTransactionProps[]> => {
    try {
      const results = await Request.get(`/api/events/${eventId}/transactions?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  complete: async (eventId: string, transactionId: string): Promise<EventTransactionProps> => {
    try {
      const results = await Request.post(
        `/api/events/${eventId}/transactions/${transactionId}/complete?_method=PUT`,
        new FormData(),
        'multipart/form-data'
      );
      return results;
    } catch (e) {
      throw e;
    }
  },
  reject: async (eventId: string, transactionId: string): Promise<EventTransactionProps> => {
    try {
      const results = await Request.post(
        `/api/events/${eventId}/transactions/${transactionId}/reject?_method=PUT`,
        new FormData(),
        'multipart/form-data'
      );
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default eventTransactionService;
