import {
  CommandBar,
  CommandBarButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import EventFlavourList from '../../../../components/events/flavours/list';
import Header from '../../../../components/header';
import GeneralConfig from '../../../../config';
import { EventFlavourType, EventType } from '../../../../props/events';
import eventFlavourServices from '../../../../services/events/flavour';
import AddEventFlavourPanel from './surfaces/addEventFlavour';
import UpdateEventFlavourPanel from './surfaces/updateEventFlavour';

type EventFlavourPageProps = {
  eventFlavours?: EventFlavourType[];
  event?: EventType;
};

const EventFlavourPage: React.FC<EventFlavourPageProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(props.eventFlavours ? true : false);
  const [eventFlavours, setEventFlavours] = useState<EventFlavourType[]>(
    props.eventFlavours ? props.eventFlavours : []
  );
  const [addEventFlavourPanel, setAddEventFlavourPanel] = useState<JSX.Element>();
  const [updateEventFlavourPanel, setUpdateEventFlavourPanel] = useState<JSX.Element>();

  useEffect(() => {
    if (props.eventFlavours === undefined) {
      getEventFlavours();
    }

    return () => {};
  }, []);

  const getEventFlavours = async () => {
    setLoaded(false);
    try {
      const eventFlavours = await eventFlavourServices.retrieve();
      setEventFlavours(eventFlavours);
      setLoaded(true);
    } catch (error) {}
  };

  const getItems = () => {
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Tambah Event Flavour'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() => {
                setAddEventFlavourPanel(
                  <AddEventFlavourPanel
                    event={props.event}
                    closePanel={(refresh?: boolean | undefined) => {
                      if (refresh) {
                        getEventFlavours();
                      }
                      setAddEventFlavourPanel(undefined);
                    }}
                  />
                );
              }}
            />
          );
        },
      },
    ];
  };

  const onDeleteItem = async (eventFlavour: EventFlavourType) => {
    if (window.confirm(`Hapus ${eventFlavour.name}`))
      try {
        await eventFlavourServices.delete(eventFlavour.id, '');
        getEventFlavours();
      } catch (error) {}
  };

  const onUpdateItem = (item: EventFlavourType) => {
    setUpdateEventFlavourPanel(
      <UpdateEventFlavourPanel
        closePanel={(refresh?: boolean | undefined) => {
          if (refresh) {
            getEventFlavours();
          }
          setUpdateEventFlavourPanel(undefined);
        }}
        eventFlavour={item}
      />
    );
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      {!props.eventFlavours && (
        <Header
          title={'Daftar Falvours'}
          breadcrumbs={[
            { key: 'home', text: 'Beranda', href: '/' },
            { key: 'events', text: 'Event', href: '/events' },
            { key: 'flavours', text: `Flavours`, href: `/eventFlavours` },
          ]}
        />
      )}
      {addEventFlavourPanel}
      {updateEventFlavourPanel}
      <Stack>
        <CommandBar items={getItems()} />
        <EventFlavourList
          flavourEvents={eventFlavours}
          loaded={loaded}
          onDeleteItem={onDeleteItem}
          onUpdateItem={onUpdateItem}
        />
      </Stack>
    </Stack>
  );
};

export default EventFlavourPage;
