import * as React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';

// import ui
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, Text } from '@fluentui/react';

// import services

// import interfaces

// import component

// import styles
import styles from './styles.module.scss';
import GeneralConfig from '../../../../../config';
import LoadingComponent from '../../../../../components/loading';
import { IPackagingResourceShort } from '../../../../../props/packagings';
import PackagingService from '../../../../../services/packagings';

interface ISortPackagingPanelProps {
  closePanel: (refresh?: boolean) => void;
}

interface ISortPackagingPanelState {
  packagings: IPackagingResourceShort[];
  updatePackagings: IPackagingResourceShort[];
  loaded: boolean;
  submiting: boolean;
}

export default class SortPackagingPanel extends React.Component<
  ISortPackagingPanelProps,
  ISortPackagingPanelState
> {
  constructor(props: ISortPackagingPanelProps) {
    super(props);

    this.state = {
      packagings: [],
      loaded: false,
      submiting: false,
      updatePackagings: [],
    };
  }

  componentDidMount() {
    this.getPackagings();
  }

  private getPackagings = async () => {
    this.setState({ loaded: false });
    try {
      const packagings = await PackagingService.retrieve();
      this.setState({ packagings, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { packagings, loaded } = this.state;
    return (
      <Panel
        headerText='Ubah Urutan Packaging'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {loaded && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <section {...provided.droppableProps} ref={provided.innerRef}>
                  {packagings.map((f, index) => (
                    <Draggable key={f.id} draggableId={f.id} index={index}>
                      {(provided, snapshot) => (
                        <section
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            position: 'static',
                            background: '#ffffff',
                          }}
                          className={styles.columnItem}
                        >
                          <Stack
                            key={f.id}
                            className={styles.item}
                            horizontal
                            verticalAlign='center'
                            tokens={{ childrenGap: 10 }}
                          >
                            {f.images.map((i) => (
                              <img
                                key={i.id}
                                src={GeneralConfig.assetsHostname + '/' + i.url}
                                className={styles.image}
                              />
                            ))}
                            <Text>{f.name}</Text>
                          </Stack>
                        </section>
                      )}
                    </Draggable>
                  ))}
                </section>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Panel>
    );
  }

  private onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { packagings: items } = this.state;
    let newNumber: number = 0;

    const lastIndex = items.length - 1;
    if (result.destination) {
      const to = result.destination.index;
      const from = result.source.index;

      if (Number(items[from].orderNumber) > Number(items[to].orderNumber)) {
        if (to - 1 !== lastIndex) {
          if (items[to - 1]) {
            newNumber = (Number(items[to].orderNumber) + Number(items[to - 1].orderNumber)) / 2;
          } else {
            newNumber = Number(items[to].orderNumber) / 2;
          }
        } else {
          newNumber = Number(items[to].orderNumber) - 1;
        }
      } else {
        if (items[to + 1]) {
          newNumber = (Number(items[to].orderNumber) + Number(items[to + 1].orderNumber)) / 2;
        } else {
          newNumber = Number(items[to].orderNumber) + 1;
        }
      }

      newNumber === NaN && (newNumber = Math.floor(Math.random() * 10) + 1);

      this.updateColumns(items, from, to, newNumber);

      this.updateOrderNumber(newNumber, items[from]);
    }
  };

  private updateOrderNumber = async (orderNumber: number, product: IPackagingResourceShort) => {
    const { updatePackagings } = this.state;
    const findToppings = updatePackagings.findIndex((f) => f.id === product.id);
    if (updatePackagings[findToppings]) {
      updatePackagings[findToppings].orderNumber = String(orderNumber);
    } else {
      updatePackagings.push(product);
    }

    this.setState({ updatePackagings });
  };

  private updateColumns = (
    packagings: IPackagingResourceShort[],
    from: number,
    to: number,
    newOrderNumber: number
  ) => {
    packagings[from].orderNumber = String(newOrderNumber);
    this.setState({ packagings: this._reorder(packagings, from, to) });
  };

  private _reorder = (list: IPackagingResourceShort[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  private onRenderFooter = () => {
    if (!this.state.submiting) {
      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          {this.state.updatePackagings.length !== 0 && <PrimaryButton text='Save' onClick={this.onSubmit} />}
          <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        </Stack>
      );
    } else {
      return <LoadingComponent label={'Mengubah Urutan Packaging'} />;
    }
  };

  private onSubmit = async () => {
    const { updatePackagings } = this.state;

    const fd = new FormData();
    updatePackagings.forEach((u) => fd.append('packagings[]', JSON.stringify(u)));
    this.setState({ submiting: true });
    try {
      await PackagingService.reorder(fd);
      this.props.closePanel(true);
      this.setState({ submiting: false });
    } catch (error) {
      this.setState({ submiting: false });
    }
  };
}
