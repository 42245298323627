import * as React from 'react';

// import ui
import {
  CommandBar,
  CommandBarButton,
  CommandButton,
  DetailsList,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';

// import styles
import styles from './styles.module.scss';
import UserService from '../../../../services/auth/user';
import { IUserResourceShortProps } from '../../../../props/user';
import AddUserPanel from './surfaces/panelAddUser';
import UserRolesPanel from './surfaces/userRoles';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';
import UpdateUserPanel from './surfaces/panelUpdateDetails';

interface IUsersPageProps {
  context: ProviderStore;
}

interface IUsersPageState {
  users: IUserResourceShortProps[];
  addUserPanel?: JSX.Element;
  userRolesPanel?: JSX.Element;
  updateUserPanel?: JSX.Element;
}

export default class UsersPage extends React.Component<IUsersPageProps, IUsersPageState> {
  constructor(props: IUsersPageProps) {
    super(props);

    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  private getUsers = async () => {
    try {
      const users = await UserService.retrieve();
      this.setState({ users });
    } catch (error) {}
  };

  public render() {
    const { users, addUserPanel, userRolesPanel, updateUserPanel } = this.state;
    return (
      <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {addUserPanel}
        {userRolesPanel}
        {updateUserPanel}
        <Text variant={'xLarge'}>Users</Text>
        <CommandBar items={this.getItems()} />
        <DetailsList selectionMode={SelectionMode.none} items={users} columns={this.getColumns()} />
      </Stack>
    );
  }

  private getColumns = () => {
    return [
      {
        key: 'no',
        name: 'No',
        minWidth: 0,
        maxWidth: 50,
        onRender: (item: IUserResourceShortProps, index: any) => (
          <Stack>
            <Text>{(index || 0) + 1}</Text>
          </Stack>
        ),
      },
      {
        key: 'username',
        name: 'Username',
        minWidth: 100,
        maxWidth: 300,
        onRender: (item: IUserResourceShortProps, index: any) => (
          <Stack
            horizontal
            styles={{ root: { width: '100%' } }}
            verticalAlign='center'
            horizontalAlign='space-between'
          >
            <Text>{item.username}</Text>
            <CommandBarButton
              menuProps={{
                items: [
                  {
                    key: 'roles',
                    text: 'Roles',
                    iconProps: { iconName: 'KeyIcon' },
                    onClick: () =>
                      this.setState({
                        userRolesPanel: (
                          <UserRolesPanel
                            closePanel={() => this.setState({ userRolesPanel: undefined })}
                            user={item}
                          />
                        ),
                      }),
                    disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, []),
                  },
                  {
                    key: 'edit',
                    text: 'Edit',
                    iconProps: { iconName: 'Edit' },
                    onClick: () =>
                      this.setState({
                        updateUserPanel: (
                          <UpdateUserPanel
                            closePanel={() => this.setState({ updateUserPanel: undefined })}
                            user={item}
                          />
                        ),
                      }),
                    disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, []),
                  },
                ],
              }}
            />
          </Stack>
        ),
      },
      {
        key: 'name',
        name: 'Name',
        minWidth: 100,
        maxWidth: 500,
        isMultiline: true,
        onRender: (item: IUserResourceShortProps, index: any) => (
          <Stack>
            <Text>{item?.details?.name}</Text>
          </Stack>
        ),
      },
      {
        key: 'email',
        name: 'Email',
        minWidth: 100,
        maxWidth: 500,
        isMultiline: true,
        onRender: (item: IUserResourceShortProps, index: any) => (
          <Stack>
            <Text>{item?.details?.email}</Text>
          </Stack>
        ),
      },
      {
        key: 'address',
        name: 'Address',
        isMultiline: true,
        minWidth: 100,
        maxWidth: 500,
        onRender: (item: IUserResourceShortProps, index: any) => (
          <Stack>
            <Text>{item?.details?.address}</Text>
          </Stack>
        ),
      },
    ];
  };

  private getItems = () => {
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              iconProps={{ iconName: 'Add' }}
              text='Add User'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() =>
                checkPermission(this.props.context.globalState.myPermissionKeys, []) &&
                this.setState({
                  addUserPanel: (
                    <AddUserPanel closePanel={() => this.setState({ addUserPanel: undefined })} />
                  ),
                })
              }
              disabled={!checkPermission(this.props.context.globalState.myPermissionKeys, [])}
            />
          );
        },
      },
    ];
  };
}
