import React from 'react';

// import manifests

// import configs

// import stylings
import styles from './style.module.scss';

// import props

// import services

// import components

// import antd component
import { Alignment, Spinner, SpinnerLabelPosition, SpinnerSize, Stack } from '@fluentui/react';

export interface ILoadingComponentProps {
    label: string;
    size?: SpinnerSize;
    labelPosition?: SpinnerLabelPosition;
    spinnerPosition?: Alignment;
    withBackground?: boolean;
}
interface ILoadingComponentState {}

export default class LoadingComponent extends React.Component<ILoadingComponentProps, ILoadingComponentState> {

    constructor(props: ILoadingComponentProps) {
        super(props);
        this.state = {};
    }

    public render() {
        const {label, labelPosition, withBackground, spinnerPosition} = this.props;

      return (
        <Stack horizontalAlign={spinnerPosition} className={`${styles.loadingComponent} ${withBackground ? styles.withBackground : ""}`}>
            <Spinner labelPosition={labelPosition} label={label} />
        </Stack>
      );
    }
}