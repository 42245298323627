import React, { useEffect, useState } from 'react';
import { Callout, mergeStyleSets, Stack } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

const systemColors = {
  light: [
    '#F7F7F2',
    '#DEDEE0',
    '#FBDCE2',
    '#AEDCC0',
    '#FBF2C0',
    '#FDFCDC',
    '#FED9B7',
    '#EDF2F4',
    '#F1FFFA',
    '#DFF8EB',
    '#CDCDCD',
    '#E8E9EB',
    '#E0DFD5',
    '#E0EEC6',
    '#D5FFF3',
    '#C7EDE4',
    '#CEB5A7',
  ],
  dark: [
    '#00171F',
    '#003459',
    '#2C4251',
    '#64B6AC',
    '#456990',
    '#533747',
    '#5F506B',
    '#1B263B',
    '#4A314D',
    '#C1292E',
    '#6247AA',
    '#D64933',
    '#FF36AB',
    '#52796F',
  ],
};

type TypeColorPickerProps = {
  children?: React.ReactNode;
  color?: string;
  onSelectColor: (color: string) => void;
};

const ColorPicker: React.FC<TypeColorPickerProps> = (props) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const [colorKey, setColorKey] = useState<string | undefined>(props.color);
  const buttonId = useId('callout-button');
  const labelId = useId('callout-label');
  const descriptionId = useId('callout-description');

  useEffect(() => {
    if (props.color && props.color !== colorKey) {
      setColorKey(props.color);
    }

    return () => {};
  }, [props.color]);

  const onPickColor = (item: string) => {
    setColorKey(item);
    toggleIsCalloutVisible();
    props.onSelectColor(item);
  };

  return (
    <Stack horizontal>
      <section
        id={buttonId}
        className={styles.item}
        onClick={toggleIsCalloutVisible}
        style={{ backgroundColor: colorKey ?? 'unset' }}
      />

      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          ariaLabelledBy={labelId}
          ariaDescribedBy={descriptionId}
          role='dialog'
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          <Stack>
            <Stack wrap horizontal>
              {systemColors.light.map((item) => (
                <section
                  className={styles.item}
                  key={item}
                  style={{ backgroundColor: item, margin: 5 }}
                  onClick={() => onPickColor(item)}
                >
                  {colorKey === item && <FontAwesomeIcon icon={faCheck} color={'black'} />}
                </section>
              ))}
              {systemColors.dark.map((item) => (
                <section
                  className={styles.item}
                  key={item}
                  style={{ backgroundColor: item, margin: 5 }}
                  onClick={() => onPickColor(item)}
                >
                  {colorKey === item && <FontAwesomeIcon icon={faCheck} color={'white'} />}
                </section>
              ))}
            </Stack>
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};

const styles = mergeStyleSets({
  callout: {
    maxWidth: 400,
    padding: 20,
  },
  item: {
    height: 40,
    width: 40,
    borderRadius: 5,
    border: '3px solid black',
    margin: 5,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ColorPicker;
