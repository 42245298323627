import * as React from 'react';

import { Upload, Button, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// import ui
import {
  DatePicker,
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';
import { IProductTypeResourceShort } from '../../../../../props/products/index';

// import services
import validationService from '../../../../../services/validation';
import FlavourService from '../../../../../services/flavours';
import ProductTypeService from '../../../../../services/products/type';
import GeneralService from '../../../../../services/general';

// import component
import MessageBarComponent from '../../../../../components/messageBar';
import LoadingComponent from '../../../../../components/loading';
import PicturesWall from '../../../../../components/picturesWall';
import ClosureDateServices from '../../../../../services/closureDate';
import { IClosureDateResourceShort } from '../../../../../props/dates';
import { ClosureDateDataType } from './panelAddClosureDate';
import GeneralConfig from '../../../../../config';
import ClosureDateImageService from '../../../../../services/closureDate/images';

interface IUpdateClosureDatePanelProps {
  closePanel: (rrefresh?: boolean, messageBar?: IRenderMessageBarProps) => void;
  closureDate: IClosureDateResourceShort;
}

interface IUpdateClosureDatePanelState {
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  data?: ClosureDateDataType;
  errorData?: {
    name?: string;
    description?: string;
  };
  calloutTarget?: number;
  fileList: UploadFile[];
}

export default class UpdateClosureDatePanel extends React.Component<
  IUpdateClosureDatePanelProps,
  IUpdateClosureDatePanelState
> {
  constructor(props: IUpdateClosureDatePanelProps) {
    super(props);

    this.state = {
      data: {
        startDate: new Date(props.closureDate.startDate),
        endDate: new Date(props.closureDate.endDate),
        description: props.closureDate.description,
      },
      loaded: true,
      fileList: props.closureDate.images.map((f) => ({
        uid: f.id,
        name: f.name,
        status: 'done',
        url: GeneralConfig.assetsHostname + '/' + f.url,
      })),
    };
  }

  public render() {
    const { data, loaded, messageBar, errorData, calloutTarget, fileList } = this.state;
    return (
      <Panel
        headerText='Tambah Tanggal'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <Stack tokens={{ childrenGap: 10 }}>
          {messageBar ? <MessageBarComponent {...messageBar} /> : null}
          {!loaded ? (
            <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
          ) : null}
          {loaded ? (
            <>
              <Stack>
                <Upload
                  action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                  listType='picture'
                  maxCount={1}
                  onChange={this.handleChange}
                  fileList={fileList}
                  onRemove={this.onRemoveFile}
                >
                  <Button icon={<UploadOutlined />}>Upload Gambar</Button>
                </Upload>
              </Stack>
              <TextField
                value={data?.description}
                errorMessage={errorData?.description}
                multiline
                rows={3}
                autoAdjustHeight
                resizable={false}
                label='Penjelasan / Catatan Tambahan'
                onChange={this.onChengeDescription}
              />
              <DatePicker label='Mulai' value={data?.startDate} onSelectDate={this.onSelectStartDate} />
              <DatePicker label='Selesai' value={data?.endDate} onSelectDate={this.onSelectEndtDate} />
            </>
          ) : null}
        </Stack>
      </Panel>
    );
  }

  private onRemoveFile = async (file: UploadFile<any>) => {
    try {
      await ClosureDateImageService.delete(this.props.closureDate.id, file.uid);
    } catch (error) {}
  };

  private handleChange = ({ fileList }: UploadChangeParam<UploadFile<any>>) => {
    this.setState({ fileList });
  };

  private onSelectStartDate = (date: Date | null | undefined) =>
    this.setState({ data: { ...this.state.data, startDate: date || undefined } });

  private onSelectEndtDate = (date: Date | null | undefined) =>
    this.setState({ data: { ...this.state.data, endDate: date || undefined } });

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, fileList } = this.state;
    const fd = new FormData();
    fileList.forEach((f: any) => fd.append('files[]', f.originFileObj));

    if (data) {
      data.description && fd.append('description', data.description);
      data.startDate && fd.append('startDate', data.startDate.toISOString());
      data.endDate && fd.append('endDate', data.endDate.toISOString());
      try {
        await ClosureDateServices.update(fd, this.props.closureDate.id);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 2000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };
}
