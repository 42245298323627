import Request from './../request';

import { IGiftCardResourceShort } from '../../props/gift';

// import interfaces

const GiftCardService = {
  retrieve: async (): Promise<IGiftCardResourceShort[]> => {
    try {
      const results = await Request.get(`/api/giftcards`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IGiftCardResourceShort[]> => {
    try {
      const results = await Request.post(`/api/giftcards`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, id: string): Promise<IGiftCardResourceShort[]> => {
    try {
      const results = await Request.post(`/api/giftcards/${id}?_method=PUT`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<IGiftCardResourceShort[]> => {
    try {
      const results = await Request.delete(`/api/giftcards/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default GiftCardService;
