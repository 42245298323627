import * as React from 'react';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

// import components
import LoginPage from './login';

// import assets
import LogoImage from './../../../assets/logo/logo-square.png';
import AuthPageBackgroundImage from './../../../assets/backgrounds/auth-page.jpg';
import { Stack, Text } from '@fluentui/react';

// import styles
import styles from './style.module.scss';

interface IAuthPageProps {}

interface IAuthPageState {}

export default class AuthPage extends React.Component<IAuthPageProps, IAuthPageState> {
  constructor(props: IAuthPageProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <Stack className={styles.authPage}>
        <Stack className={styles.container} horizontal>
          <Stack className={styles.leftContainer}>
            <Stack
              className={styles.background}
              styles={{ root: { background: `url(${AuthPageBackgroundImage})` } }}
            />
            <Stack
              verticalAlign={'center'}
              horizontalAlign={'center'}
              className={styles.content}
              tokens={{ childrenGap: 20 }}
            >
              <Routes>
                <Route path='/login' element={<LoginPage />} />
              </Routes>
            </Stack>
          </Stack>
          <Stack className={styles.rightContainer}>
            <Stack verticalAlign={'center'} horizontalAlign={'center'} className={styles.content}>
              <Stack>
                <img src={LogoImage} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}
