import * as React from 'react';

import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// import ui
import {
  DatePicker,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';

// import services
import validationService from '../../../../../services/validation';
import GeneralService from '../../../../../services/general';

// import component
import MessageBarComponent from '../../../../../components/messageBar';
import LoadingComponent from '../../../../../components/loading';
import GeneralConfig from '../../../../../config';
import { IToppingResourceShort } from '../../../../../props/toppings';
import { ToppingDataType } from './panelAddTopping';
import ToppingServices from '../../../../../services/toppings';
import ToppingImageService from '../../../../../services/toppings/images';
import { IProductTypeResourceShort } from '../../../../../props/products';
import ProductTypeService from '../../../../../services/products/type';

interface IUpdateToppingPanelProps {
  closePanel: (rrefresh?: boolean, messageBar?: IRenderMessageBarProps) => void;
  topping: IToppingResourceShort;
}

interface IUpdateToppingPanelState {
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  data?: ToppingDataType;
  errorData?: ToppingDataType;
  calloutTarget?: number;
  fileList: UploadFile[];
  submiting: boolean;
  productTypes: IProductTypeResourceShort[];
  productTypeId?: string;
}

export default class UpdateToppingPanel extends React.Component<
  IUpdateToppingPanelProps,
  IUpdateToppingPanelState
> {
  constructor(props: IUpdateToppingPanelProps) {
    super(props);

    this.state = {
      data: {
        name: props.topping.name,
        description: props.topping.description,
        price: props.topping.price,
        isFavourite: props.topping.isFavourite,
        orderNumber: props.topping.orderNumber,
      },
      loaded: true,
      fileList: props.topping.images.map((f) => ({
        uid: f.id,
        name: f.name,
        status: 'done',
        url: GeneralConfig.assetsHostname + '/' + f.url,
      })),
      submiting: false,
      productTypes: [],
      productTypeId: props.topping.productTypeId,
    };
  }

  componentDidMount() {
    this.getProductTypes();
  }

  private getProductTypes = async () => {
    try {
      const productTypes = await ProductTypeService.retrieve();
      this.setState({ productTypes, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { data, loaded, messageBar, errorData, calloutTarget, fileList, productTypes, productTypeId } =
      this.state;
    return (
      <Panel
        headerText='Ubah Topping'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <Stack tokens={{ childrenGap: 10 }}>
          {messageBar ? <MessageBarComponent {...messageBar} /> : null}
          {!loaded ? (
            <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
          ) : null}
          {loaded ? (
            <>
              <Stack>
                <Upload
                  listType='picture'
                  maxCount={1}
                  onChange={this.handleChange}
                  fileList={fileList}
                  onRemove={this.onRemoveFile}
                  beforeUpload={this.beforeUpload}
                >
                  <Button icon={<UploadOutlined />}>Upload Gambar</Button>
                </Upload>
              </Stack>
              <TextField
                value={data?.name}
                errorMessage={errorData?.name}
                label='Nama'
                onChange={this.onChengeName}
              />
              <TextField
                value={data?.description}
                errorMessage={errorData?.description}
                multiline
                rows={3}
                autoAdjustHeight
                resizable={false}
                label='Penjelasan / Catatan Tambahan'
                onChange={this.onChengeDescription}
              />
              <TextField
                value={data?.price}
                errorMessage={errorData?.price}
                label='Harga Tambahan'
                onChange={this.onChengePrice}
                prefix='Rp'
              />
              <Dropdown
                placeholder='Pilih tipe produk ...'
                label='Tipe Produk'
                options={productTypes.map((p) => ({ key: p.id, text: p.name }))}
                selectedKey={productTypeId}
                onChange={this.onChangeDropdownType}
                disabled={!loaded || productTypes.length === 0}
                required
              />
              <Toggle
                label='Favorit'
                onChange={this.onChangeToggle}
                checked={data?.isFavourite === 1 ? true : false}
              />
            </>
          ) : null}
        </Stack>
      </Panel>
    );
  }

  private onChangeDropdownType = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    if (option) {
      this.setState({ productTypeId: option.key as string });
    }
  };

  private onChangeToggle = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined
  ) => this.setState({ data: { ...this.state.data, isFavourite: checked ? 1 : 0 } });

  private onRemoveFile = async (file: UploadFile<any>) => {
    if (window.confirm(`Delete ${file.name}`)) {
      try {
        await ToppingImageService.delete(this.props.topping.id, file.uid);
      } catch (error) {}
    } else {
      return false;
    }
  };

  private handleChange = ({ fileList }: UploadChangeParam<UploadFile<any>>) => {
    this.setState({ fileList });
  };

  private onRenderFooter = () => {
    if (!this.state.submiting) {
      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <PrimaryButton text='Submit' onClick={this.onSubmit} />
          <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        </Stack>
      );
    } else {
      return <LoadingComponent label={'Mengubah Topping'} />;
    }
  };

  private onSubmit = async () => {
    const { data, fileList, productTypeId } = this.state;
    const fd = new FormData();
    fileList.forEach((f: any) => fd.append('files[]', f.originFileObj));

    if (data) {
      data.name && fd.append('name', data.name);
      data.price && fd.append('price', data.price || '0');
      data.description && fd.append('description', data.description);
      data.isFavourite === 1 ? fd.append('isFavourite', '1') : fd.append('isFavourite', '0');
      productTypeId && fd.append('productTypeId', productTypeId);
      this.setState({ submiting: true });
      try {
        await ToppingServices.update(fd, this.props.topping.id);
        this.props.closePanel(true);
        this.setState({ submiting: false });
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
          submiting: false,
        });
      }
    }
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 2000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };

  private onChengePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'number'], { maxChars: 11 }).message;
    this.setState({
      data: { ...this.state.data, price: v },
      errorData: { ...this.state.errorData, price: err },
    });
  };

  private onChengeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'required'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private beforeUpload = (file: RcFile, FileList: RcFile[]) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    if (isJpgOrPng && isLt2M) {
      this.onAddFile(file);
    }
    return isJpgOrPng && isLt2M;
  };

  private onAddFile = async (file: RcFile) => {
    let fd = new FormData();
    fd.append('file', file);
    try {
      await ToppingImageService.create(this.props.topping.id, fd);
    } catch (error) {}
  };
}
