import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  DefaultButton,
  DetailsList,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles
import styles from './styles.module.scss';

// import services
import FlavourService from '../../../../services/flavours';

// import interfaces
import { IFlavourResourceShort } from '../../../../props/flavours';
import AddFlavourPanel from './surfaces/panelAddFlavour';
import GeneralConfig from '../../../../config';
import UpdateFlavourPanel from './surfaces/panelUpdateFlavour';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';
import SortFlavourPanel from './surfaces/panelSortFlavours';

interface IFlavoursPageProps {
  context: ProviderStore;
}

interface IFlavoursPageState {
  flavours: IFlavourResourceShort[];
  loaded: boolean;
  addFlavourDialog?: JSX.Element;
  updateFlavourDialog?: JSX.Element;
  sortFlavourPanel?: JSX.Element;
}

export default class FlavoursPage extends React.Component<IFlavoursPageProps, IFlavoursPageState> {
  constructor(props: IFlavoursPageProps) {
    super(props);

    this.state = {
      flavours: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getFlavours();
  }

  private getFlavours = async () => {
    this.setState({ loaded: false });
    try {
      const flavours = await FlavourService.retrieve();
      this.setState({ flavours, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { flavours, loaded, addFlavourDialog, updateFlavourDialog, sortFlavourPanel } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addFlavourDialog}
        {updateFlavourDialog}
        {sortFlavourPanel}
        <Text variant={'xLarge'}>Flavours</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={flavours}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: IFlavourResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                </Stack>
              ),
            },
            {
              key: 'image',
              name: 'Image',
              maxWidth: 300,
              minWidth: 300,
              isMultiline: true,
              onRender: (item: IFlavourResourceShort, ix) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  tokens={{ childrenGap: 10 }}
                  wrap
                >
                  {item.images.map((i) => (
                    <img
                      key={i.id}
                      src={GeneralConfig.assetsHostname + '/' + i.url}
                      style={{ height: 60, width: 60 }}
                    />
                  ))}
                </Stack>
              ),
            },
            {
              key: 'name',
              name: 'Name',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IFlavourResourceShort) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  horizontalAlign='space-between'
                >
                  <Text>{item.name}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () => {
                            this.setState({
                              updateFlavourDialog: (
                                <UpdateFlavourPanel
                                  closePanel={(r) => {
                                    this.setState({ updateFlavourDialog: undefined });
                                    if (r) {
                                      this.getFlavours();
                                    }
                                  }}
                                  flavour={item}
                                />
                              ),
                            });
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'update_flavour',
                          ]),
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {
                            this.deleteFlavour(item);
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'delete_flavour',
                          ]),
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
            {
              key: 'description',
              name: 'Description',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IFlavourResourceShort) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{item.description}</Text>
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Tambah Rasa Baru'
              styles={{ root: { marginLeft: 10 } }}
              onClick={this.showDialog}
            />
          );
        },
      },
      {
        key: 'sort_flavours',
        text: 'Sort Flavours',
        onRender: () => {
          return (
            <DefaultButton
              disabled={!loaded}
              iconProps={{ iconName: 'ArrowDownUp' }}
              text='Ubah Urutan Rasa'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() =>
                this.setState({
                  sortFlavourPanel: (
                    <SortFlavourPanel
                      closePanel={(refresh) => {
                        if (refresh) {
                          this.getFlavours();
                        }
                        this.setState({ sortFlavourPanel: undefined });
                      }}
                    />
                  ),
                })
              }
            />
          );
        },
      },
    ];
  };

  private showDialog = () =>
    this.setState({
      addFlavourDialog: (
        <AddFlavourPanel
          closePanel={(r) => {
            this.setState({ addFlavourDialog: undefined });
            if (r) {
              this.getFlavours();
            }
          }}
          orderNumber={
            this.state.flavours.length !== 0
              ? Number(this.state.flavours[this.state.flavours.length - 1].orderNumber) + 1
              : 1
          }
        />
      ),
    });

  private deleteFlavour = async (item: IFlavourResourceShort) => {
    if (window.confirm('Delete Flavour')) {
      try {
        await FlavourService.delete(item.id);
        this.getFlavours();
      } catch (error) {}
    }
  };
}
