import * as React from 'react';

import {
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import validationService from '../../../../../services/validation';
import { UploadFile } from 'antd/lib/upload/interface';
import PicturesWall from '../components/uploadFile';

// import services
import PackagingService from '../../../../../services/packagings';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import MessageBarComponent from '../../../../../components/messageBar';

interface IAddPackagingtPanelProps {
  closePanel: (r?: boolean) => void;
  orderNumber: number;
}

export type PackagingDataType = {
  name?: string;
  price?: string;
};

interface IAddPackagingtPanelState {
  data?: PackagingDataType;
  errorData?: PackagingDataType;
  files: UploadFile<any>[];
  messageBar?: IRenderMessageBarProps;
}

export default class AddPackagingtPanel extends React.Component<
  IAddPackagingtPanelProps,
  IAddPackagingtPanelState
> {
  constructor(props: IAddPackagingtPanelProps) {
    super(props);

    this.state = {
      files: [],
    };
  }

  public render() {
    const { data, errorData, messageBar } = this.state;
    return (
      <Panel
        headerText='Add Packaging'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack>
            <Label>Gambar Packaging</Label>
            <PicturesWall fileList={[]} onUpdate={(files) => this.setState({ files })} />
          </Stack>
          <TextField
            label='Nama'
            value={data?.name}
            errorMessage={errorData?.name}
            onChange={this.onChangeName}
          />
          <TextField
            value={data?.price}
            errorMessage={errorData?.price}
            label='Harga'
            prefix={'Rp'}
            onChange={this.onChengePrice}
          />
        </Stack>
      </Panel>
    );
  }

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, files, errorData } = this.state;
    if (
      data?.name &&
      data?.name.trim() !== '' &&
      !errorData?.name &&
      !errorData?.price &&
      data.price &&
      data.price.trim() !== '' &&
      files.length !== 0
    ) {
      const fd = new FormData();
      fd.append('name', data.name);
      fd.append('additionalPrice', data.price);
      files.map((f) => f.originFileObj && fd.append('files[]', f?.originFileObj));
      fd.append('orderNumber', String(this.props.orderNumber));
      try {
        await PackagingService.create(fd);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChengePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'number'], { maxChars: 11 }).message;
    this.setState({
      data: { ...this.state.data, price: v },
      errorData: { ...this.state.errorData, price: err },
    });
  };
}
