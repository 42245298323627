import {
  DatePicker,
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from '@fluentui/react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import React, { useState } from 'react';
import MessageBarComponent from '../../../../../components/messageBar';
import PicturesWall from '../../../../../components/uploadImage';
import GeneralConfig from '../../../../../config';
import { EventType } from '../../../../../props/events';
import { IRenderMessageBarProps } from '../../../../../props/general';
import eventServices from '../../../../../services/events';
import GeneralService from '../../../../../services/general';
import validationService from '../../../../../services/validation';

type UpdateEventCardTemplatePanelProps = {
  children?: React.ReactNode;
  closePanel: (refresh?: boolean) => void;
  event: EventType;
};

const UpdateEventCardTemplatePanel: React.FC<UpdateEventCardTemplatePanelProps> = (props) => {
  const [messageBar, setMessageBar] = useState<IRenderMessageBarProps>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile<any>[]>([]);

  const onSubmit = async () => {
    if (files[0] && files[0].originFileObj) {
      let fd = new FormData();
      fd.append('file', files[0].originFileObj);
      setSubmitting(true);
      try {
        await eventServices.cardTemplates.create(props.event.code, fd);
        setSubmitting(false);
        props.closePanel(true);
      } catch (error) {}
    }
    // const fd = new FormData();
    // setSubmitting(true);
    // try {
    //   await eventServices.update(fd, props.event.id);
    //   props.closePanel(true);
    // } catch (error) {
    //   setMessageBar({
    //     type: MessageBarType.error,
    //     text: GeneralService.getErrorMessage(error),
    //   });
    //   setSubmitting(false);
    // }
  };

  const onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        {!submitting && (
          <>
            <DefaultButton text='Cancel' onClick={() => props.closePanel()} />
            <PrimaryButton text='Submit' onClick={onSubmit} />
          </>
        )}
        {submitting && <Spinner />}
      </Stack>
    );
  };

  const onRemoveFile = async (file: UploadFile<any>) => {
    // try {
    //   await eventServices.logos.delete(props.event.id, file.uid);
    // } catch (error) {}
  };

  const onAddFile = async (file: RcFile) => {
    // let fd = new FormData();
    // fd.append('file', file);
    // try {
    //   await eventServices.logos.create(props.event.id, fd);
    // } catch (error) {}
  };

  return (
    <Panel
      headerText='Ubah Template Kartu'
      type={PanelType.medium}
      isOpen
      onDismiss={() => props.closePanel()}
      onRenderFooterContent={onRenderFooter}
      isFooterAtBottom={true}
      closeButtonAriaLabel='Close'
    >
      {messageBar ? <MessageBarComponent {...messageBar} /> : null}
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack>
          <Label>Template Kartu</Label>
          <PicturesWall
            fileList={props.event.cardTemplates.map((f) => ({
              uid: f.id,
              name: f.name,
              status: 'done',
              url: GeneralConfig.assetsHostname + '/' + f.url,
            }))}
            onUpdate={(files) => setFiles(files)}
            onRemoveFile={onRemoveFile}
            onAddFile={onAddFile}
          />
        </Stack>
      </Stack>
    </Panel>
  );
};

export default UpdateEventCardTemplatePanel;
