import * as React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { IPermissionResourceShort, PermissionKeyType } from '../../../../../props/roles';
import PermissionServices from '../../../../../services/roles/permission';

interface IRolePermissionComponentProps {
  onUpdate: (permissionKeys: PermissionKeyType[]) => void;
  permissionKeys: PermissionKeyType[];
}

interface IRolePermissionComponentState {
  permissions: IPermissionResourceShort[];
  loaded: boolean;
  permissionKeys: PermissionKeyType[];
}

export default class RolePermissionComponent extends React.Component<
  IRolePermissionComponentProps,
  IRolePermissionComponentState
> {
  constructor(props: IRolePermissionComponentProps) {
    super(props);

    this.state = { permissions: [], loaded: false, permissionKeys: props.permissionKeys };
  }

  componentDidMount() {
    this.getPermissions();
  }

  private getPermissions = async () => {
    this.setState({ loaded: false });
    try {
      const permissions = await PermissionServices.retrieve();
      this.setState({ permissions, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { permissions, permissionKeys, loaded } = this.state;
    return (
      <Dropdown
        placeholder='Select options'
        label='Permissions'
        selectedKeys={permissionKeys}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={this.onChange}
        multiSelect
        options={permissions.map((p) => ({ ...p, text: p.name }))}
        disabled={!loaded}
      />
    );
  }

  private onChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    const { permissionKeys } = this.state;
    if (option) {
      this.setState(
        {
          permissionKeys: option.selected
            ? [...permissionKeys, option.key as PermissionKeyType]
            : permissionKeys.filter((key) => key !== option.key),
        },
        this.onUpdate
      );
    }
  };

  private onUpdate = () => {
    const { permissionKeys } = this.state;
    const { onUpdate } = this.props;
    onUpdate(permissionKeys);
  };
}
