import * as React from 'react';
import { NavLink } from 'react-router-dom';

import LogoImage from './../../assets/logo/logo-text.png';

// import ui
import {
  CommandButton,
  IButtonProps,
  IconButton,
  IContextualMenuItem,
  Link,
  Persona,
  PersonaSize,
  Stack,
  Text,
} from '@fluentui/react';

// import styles
import styles from './styles.module.scss';

import urls from '../../configs/urls.json';

interface IMenubarComponentProps {}

interface IMenubarComponentState {}

export default class MenubarComponent extends React.Component<
  IMenubarComponentProps,
  IMenubarComponentState
> {
  constructor(props: IMenubarComponentProps) {
    super(props);

    this.state = {};
  }

  private _onRenderSubmenu = (submenu: IContextualMenuItem) => {
    return (
      <NavLink to={submenu.href || ''} className={styles.subitem}>
        {submenu.text}
      </NavLink>
    );
  };

  private menus: IButtonProps[] = [
    { text: 'Users', href: '/users' },
    { text: 'Pesanan', href: '/orders' },
    {
      text: 'Data',
      menuProps: {
        items: [
          {
            key: 'products',
            href: urls.home.products.index,
            text: 'Produk',
            onRender: this._onRenderSubmenu,
          },
          { key: 'flavours', href: urls.home.flavours.index, text: 'Rasa', onRender: this._onRenderSubmenu },
          {
            key: 'packagings',
            href: urls.home.packagings.index,
            text: 'Packaging',
            onRender: this._onRenderSubmenu,
          },
          {
            key: 'productTypes',
            href: urls.home.productType.index,
            text: 'Tipe Produk',
            onRender: this._onRenderSubmenu,
          },
          {
            key: 'toppings',
            href: urls.home.toppings.index,
            text: 'Topping',
            onRender: this._onRenderSubmenu,
          },
          {
            key: 'deliveryMethods',
            href: urls.home.deliveryMethods.index,
            text: 'Metode Pengiriman',
            onRender: this._onRenderSubmenu,
          },
          {
            key: 'events',
            href: urls.home.events.index,
            text: 'Event',
            onRender: this._onRenderSubmenu,
          },
          {
            key: 'eventFlavours',
            href: urls.home.eventFlavours.index,
            text: 'Event Flavour',
            onRender: this._onRenderSubmenu,
          },
          {
            key: 'eventCodes',
            href: urls.home.eventCodes.index,
            text: 'Event Codes',
            onRender: this._onRenderSubmenu,
          },
          {
            key: 'eventTransactions',
            href: urls.home.eventTransactions.index,
            text: 'Event Transactions',
            onRender: this._onRenderSubmenu,
          },
        ],
      },
    },
  ];

  public render() {
    return (
      <Stack className={styles.menubar} horizontal horizontalAlign='space-between' verticalAlign='center'>
        <Stack horizontal>
          <Stack className={styles.logo}>
            <img src={LogoImage} />
          </Stack>
          <Stack grow={1} horizontal className={styles.menu} tokens={{ childrenGap: 10 }}>
            {this.menus.map((menu, ix) => {
              if (menu.menuProps) {
                return <CommandButton key={ix} {...menu} className={styles.button} />;
              } else if (menu.href) {
                return (
                  <NavLink key={ix} to={menu.href} className={styles.item}>
                    {menu.text}
                  </NavLink>
                );
              }
            })}
          </Stack>
          <Stack
            horizontal
            verticalAlign='center'
            tokens={{ childrenGap: 5 }}
            className={styles.containerApp}
          ></Stack>
        </Stack>
        <NavLink className={styles.userIcon} to={urls.home.myProfile.index}>
          <IconButton iconProps={{ iconName: 'User' }} />
        </NavLink>
      </Stack>
    );
  }
}
