import * as React from 'react';
import { UploadFile } from 'antd/lib/upload/interface';

// import ui
import {
  DatePicker,
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';
import GeneralService from '../../../../../services/general';

// import component

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';
import { IRoleResourceShort, PermissionKeyType } from '../../../../../props/roles';

// import config
import MessageBarComponent from '../../../../../components/messageBar';
import { DataInputRoleType } from './panelAddRole';
import RolePermissionComponent from '../components/permission';
import RoleServices from '../../../../../services/roles';

interface IUpdateRolePanelProps {
  closePanel: (r?: boolean) => void;
  role: IRoleResourceShort;
}

interface IUpdateRolePanelState {
  role: IRoleResourceShort;
  data?: DataInputRoleType;
  errorData?: DataInputRoleType;
  files: UploadFile<any>[];
  messageBar?: IRenderMessageBarProps;
  permissionKeys: PermissionKeyType[];
}

export default class UpdateRolePanel extends React.Component<IUpdateRolePanelProps, IUpdateRolePanelState> {
  constructor(props: IUpdateRolePanelProps) {
    super(props);

    this.state = {
      files: [],
      role: props.role,
      data: {
        name: props.role.name,
        description: props.role.description,
        key: props.role.key,
      },
      permissionKeys: props.role.permissions.map((p) => p.permissionKey),
    };
  }

  public render() {
    const { data, errorData, messageBar } = this.state;
    return (
      <Panel
        headerText='Update Role'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <TextField label='key' value={data?.key} errorMessage={errorData?.key} onChange={this.onChangeKey} />
        <TextField
          label='Nama'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={this.onChangeName}
        />
        <TextField
          label='Detail / Catatan Tambahan'
          value={data?.description}
          errorMessage={errorData?.description}
          onChange={this.onChangeDescription}
          multiline
          rows={3}
          resizable={false}
        />
        <RolePermissionComponent
          onUpdate={(permissionKeys: PermissionKeyType[]) => this.setState({ permissionKeys })}
          permissionKeys={this.state.permissionKeys}
        />
      </Panel>
    );
  }

  private onChangeKey = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, key: v },
      errorData: { ...this.state.errorData, key: err },
    });
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { role } = this.props;
    const { data, errorData, permissionKeys } = this.state;
    if (!errorData?.name && !errorData?.key) {
      const fd = new FormData();
      data?.key && fd.append('key', data.key);
      data?.name && fd.append('name', data.name);
      data?.description && fd.append('description', data.description);
      permissionKeys.map((p) => fd.append('permissionKeys[]', p));
      try {
        await RoleServices.update(fd, role.key);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChangeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 2000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };
}
