import { IExpensesResourceShort } from '../../props/expenses';
import Request from './../request';

// import interfaces

const ExpensesImageService = {
  create: async (expensesid: string, data: FormData): Promise<IExpensesResourceShort[]> => {
    try {
      const results = await Request.post(`/api/expenses/${expensesid}/images`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (expensesid: string, id: string): Promise<IExpensesResourceShort> => {
    try {
      const results = await Request.delete(`/api/expenses/${expensesid}/images/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default ExpensesImageService;
