import React from 'react';
import moment from 'moment';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  Label,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services
import OrderService from '../../../../services/orders/indes';

// import interfaces
import { IOrderResourceShort } from '../../../../props/orders';
import { IMessageBarContentProps } from '../../../../props/general';
import { ProviderStore } from '../../../../context';
import ComplateOrder from './subPages/complate';
import ActiveOrder from './subPages/active';

// import component

interface IOrderPageProps {
  context: ProviderStore;
}

interface IOrderPageState {
  orders: IOrderResourceShort[];
  loaded: boolean;
  addOrderDialog?: JSX.Element;
  updateOrderDialog?: JSX.Element;
  messageBar?: IMessageBarContentProps;
}

export default class OrderPage extends React.Component<IOrderPageProps, IOrderPageState> {
  constructor(props: IOrderPageProps) {
    super(props);

    this.state = {
      orders: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getOrders();
  }

  private getOrders = async () => {
    try {
      const orders = await OrderService.retrieve();
      this.setState({ orders, loaded: true });
    } catch (error) {}
  };

  private onReload = () => {
    this.setState({orders: [], loaded: false});
    this.getOrders();
  }

  public render() {
    const { orders, loaded, addOrderDialog, updateOrderDialog } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        <CommandBar items={this.getItems()} />
        <Pivot aria-label='Basic Pivot Example'>
          <PivotItem headerText='Active'>
            <ActiveOrder orders={orders} context={this.props.context} onReload={this.onReload} />
          </PivotItem>
          <PivotItem headerText='Complete'>
            <ComplateOrder orders={orders} context={this.props.context} />
          </PivotItem>
        </Pivot>
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Add Order'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() => {}}
            />
          );
        },
      },
    ];
  };

  private columns = () => {
    return [
      {
        key: 'no',
        name: 'No',
        maxWidth: 100,
        minWidth: 100,
        isMultiline: true,
        onRender: (item: IOrderResourceShort, ix: any) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{(ix || 0) + 1}</Text>
            <CommandBarButton
              menuProps={{
                items: [
                  {
                    key: 'update',
                    text: 'Update',
                    iconProps: { iconName: 'SingleColumnEdit' },
                    onClick: () => {},
                  },
                  {
                    key: 'delete',
                    text: 'Delete',
                    iconProps: { iconName: 'Delete' },
                    onClick: () => {},
                  },
                ],
              }}
            />
          </Stack>
        ),
      },
      {
        key: 'paymentDate',
        name: 'Tanggal Pembayaran',
        maxWidth: 100,
        minWidth: 100,
        isMultiline: true,
        onRender: (item: IOrderResourceShort, ix: any) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{moment(item.paymentDate).format('DD/MM/YYYY')}</Text>
          </Stack>
        ),
      },
      {
        key: 'paymentStatus',
        name: 'PaymentStatus',
        maxWidth: 100,
        minWidth: 100,
        isMultiline: true,
        onRender: (item: IOrderResourceShort, ix: any) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{item.paymentStatus}</Text>
          </Stack>
        ),
      },
    ];
  };
}
