import React, { Component } from 'react';

// import assets

// import styles

// import components

// import props

// import styles

// import services

// import office fabric components
import { Link, Stack, Text, TextField } from '@fluentui/react';
import { IRenderMessageBarProps } from '../../../../../props/general';
import Loading from '../../../../../components/loading';

interface IOTPCodeInputProps {
  onSubmit(otp: string): Promise<void>;
  onResendOTP(): void;
}
interface IOTPCodeInputState {
  checking?: boolean;
  sending?: boolean;
  otp: string;
  messageBar?: IRenderMessageBarProps;
  redirecting?: boolean;
}

export default class OTPCodeInput extends Component<IOTPCodeInputProps, IOTPCodeInputState> {
  constructor(props: IOTPCodeInputProps) {
    super(props);
    this.state = {
      otp: '',
    };
  }

  private _onOTPValueChanged = (evt?: any, value?: string) => {
    const regex = /^\d+$/;
    if (value && value.length > 6) {
      value = (value || '').substring(0, 6);
    }
    if (regex.test(value || '')) {
      this.setState({ otp: value || '' });
      if ((value || '').length >= 6) {
        this.checkOTP(value || '');
      }
    }
    if (!value) {
      this.setState({ otp: '' });
    }
  };

  private checkOTP = async (otp: string) => {
    this.setState({ checking: true });
    try {
      await this.props.onSubmit(otp);
      this.setState({ checking: false });
    } catch (error) {
      this.setState({ checking: false });
    }
  };

  private resendOTP = async () => {
    this.setState({ sending: true });
    this.props.onResendOTP();
    this.setState({ sending: false });
  };

  public render() {
    return (
      <Stack tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 20 } }}>
        <Stack tokens={{ childrenGap: 30 }}>
          <Stack horizontalAlign={'center'} tokens={{ childrenGap: 10 }}>
            <TextField
              value={this.state.otp}
              disabled={this.state.sending || this.state.checking}
              onChange={this._onOTPValueChanged}
              styles={{
                field: { fontSize: 18, letterSpacing: '15px', paddingLeft: 15, textAlign: 'center' },
              }}
            />
            {!this.state.checking && !this.state.sending ? (
              <Text style={{ textAlign: 'center' }}>
                Didn't receive the code? <Link onClick={this.resendOTP}>Resend a new code.</Link>
              </Text>
            ) : null}
          </Stack>
          {this.state.sending ? (
            <Loading
              label={'Re-sending OTP to your email ...'}
              labelPosition={'right'}
              spinnerPosition={'center'}
            />
          ) : null}
          {this.state.checking ? (
            <Loading label={'Confirming your OTP ...'} labelPosition={'right'} spinnerPosition={'center'} />
          ) : null}
        </Stack>
      </Stack>
    );
  }
}
