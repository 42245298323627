import {
  DefaultButton,
  ITag,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TagPicker,
  TextField,
} from '@fluentui/react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import ColorPicker from '../../../../../components/colorPicker';
import MessageBarComponent from '../../../../../components/messageBar';
import PicturesWall from '../../../../../components/uploadImage';
import GeneralConfig from '../../../../../config';
import { EventFlavourType } from '../../../../../props/events';
import { IRenderMessageBarProps } from '../../../../../props/general';
import eventServices from '../../../../../services/events';
import eventFlavourServices from '../../../../../services/events/flavour';
import GeneralService from '../../../../../services/general';
import validationService from '../../../../../services/validation';

type UpdateEventFlavourPanelProps = {
  children?: React.ReactNode;
  closePanel: (refresh?: boolean) => void;
  eventFlavour: EventFlavourType;
};

export type EventFlavourDataType = {
  quantity?: string;
  name?: string;
  description?: string;
  titleColor?: string;
  descriptionColor?: string;
};

const UpdateEventFlavourPanel: React.FC<UpdateEventFlavourPanelProps> = (props) => {
  const [data, setData] = useState<EventFlavourDataType>({
    quantity: String(props.eventFlavour.quantity),
    name: props.eventFlavour.name,
    titleColor: props.eventFlavour.titleColor,
    description: props.eventFlavour.description,
    descriptionColor: props.eventFlavour.descriptionColor,
  });
  const [errorData, setErrorData] = useState<EventFlavourDataType>();
  const [messageBar, setMessageBar] = useState<IRenderMessageBarProps>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedEvents, setSelectedEvents] = useState<ITag[]>([
    { key: props.eventFlavour.eventCode, name: props.eventFlavour.event.name },
  ]);
  const [files, setFiles] = useState<UploadFile<any>[]>([]);

  const onSubmit = async () => {
    const fd = new FormData();
    if (data?.name && selectedEvents[0] && data?.quantity) {
      fd.append('name', data.name);
      fd.append('titleColor', data.titleColor || '');
      fd.append('description', data.description || '');
      fd.append('descriptionColor', data.descriptionColor || '');
      fd.append('quantity', data.quantity);
      setSubmitting(true);
      try {
        await eventFlavourServices.update(fd, selectedEvents[0].key as string, props.eventFlavour.id);
        props.closePanel(true);
      } catch (error) {
        setMessageBar({
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(error),
        });
        setSubmitting(false);
      }
    }
  };

  const onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        {!submitting && (
          <>
            <DefaultButton text='Cancel' onClick={() => props.closePanel()} />
            <PrimaryButton text='Submit' onClick={onSubmit} />
          </>
        )}
        {submitting && <Spinner />}
      </Stack>
    );
  };

  const onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 100 }).message;
    setData({ ...data, name: v });
    setErrorData({ ...errorData, name: err });
  };

  const onChangeTitleColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 10 }).message;
    setData({ ...data, titleColor: v });
    setErrorData({ ...errorData, titleColor: err });
  };

  const onChangeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    setData({ ...data, description: v });
    setErrorData({ ...errorData, description: err });
  };

  const onChangeDescriptionColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 10 }).message;
    setData({ ...data, descriptionColor: v });
    setErrorData({ ...errorData, descriptionColor: err });
  };

  const onChangeQuantity = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 100 }).message;
    setData({ ...data, quantity: v });
    setErrorData({ ...errorData, quantity: err });
  };

  const selectEvent = (selectedItem?: ITag | undefined): ITag | PromiseLike<ITag> | null => {
    if (selectedItem) {
      setSelectedEvents([selectedItem]);
    }
    return null;
  };

  const onResolveSuggestions = async (
    keyword: string,
    selectedItems?: ITag[] | undefined
  ): Promise<ITag[]> => {
    try {
      const data = (await eventServices.retrieve(keyword)).map((item) => ({
        key: item.code,
        name: item.name,
      }));
      return data;
    } catch (error) {
      return [];
    }
  };

  const onRemoveFile = async (file: UploadFile<any>) => {
    try {
      await eventFlavourServices.images.delete(props.eventFlavour.id, file.uid);
    } catch (error) {}
  };

  const onAddFile = async (file: RcFile) => {
    let fd = new FormData();
    fd.append('file', file);
    try {
      await eventFlavourServices.images.create(props.eventFlavour.id, fd);
    } catch (error) {}
  };

  return (
    <Panel
      headerText='Ubah Event Flavour'
      type={PanelType.medium}
      isOpen
      onDismiss={() => props.closePanel()}
      onRenderFooterContent={onRenderFooter}
      isFooterAtBottom={true}
      closeButtonAriaLabel='Close'
    >
      {messageBar ? <MessageBarComponent {...messageBar} /> : null}
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack>
          <Label>Logo</Label>
          <PicturesWall
            fileList={props.eventFlavour.images.map((f) => ({
              uid: f.id,
              name: f.name,
              status: 'done',
              url: GeneralConfig.assetsHostname + '/' + f.url,
            }))}
            onUpdate={(files) => setFiles(files)}
            onRemoveFile={onRemoveFile}
            onAddFile={onAddFile}
          />
        </Stack>
        <TextField
          required
          label='Nama'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={onChangeName}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Title Color</Label>
          <ColorPicker
            color={data?.titleColor}
            onSelectColor={(color: string) => setData({ ...data, titleColor: color })}
          />
        </Stack>
        <TextField
          required
          multiline
          rows={5}
          autoAdjustHeight
          resizable={false}
          label='Deskripsi'
          value={data?.description}
          errorMessage={errorData?.description}
          onChange={onChangeDescription}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Description Color</Label>
          <ColorPicker
            color={data?.descriptionColor}
            onSelectColor={(color: string) => setData({ ...data, descriptionColor: color })}
          />
        </Stack>
        <TextField
          required
          label='Quantity'
          value={data?.quantity}
          errorMessage={errorData?.quantity}
          onChange={onChangeQuantity}
        />
        <Stack.Item>
          <Label required>Event</Label>
          <TagPicker
            selectedItems={selectedEvents}
            removeButtonAriaLabel='Remove'
            itemLimit={1}
            onItemSelected={selectEvent}
            onChange={(items) => {
              items && items.length === 0 && setSelectedEvents([]);
            }}
            onResolveSuggestions={onResolveSuggestions}
          />
        </Stack.Item>
        {/* <Toggle
          label='Ditebus'
          checked={data?.isRedeemed}
          onChange={(e, c) => setData({ ...data, isRedeemed: c })}
        /> */}
      </Stack>
    </Panel>
  );
};

export default UpdateEventFlavourPanel;
