import { PermissionKeyType } from '../props/roles';

const checkPermission = (myPermissionKeys: PermissionKeyType[], requiredPermissions: PermissionKeyType[]) => {
  const fullControl = myPermissionKeys.find((mp) => mp === 'full_control');
  let accept = fullControl ? true : false;

  if (!accept) {
    requiredPermissions.map((rp) => {
      const fn = myPermissionKeys.find((mp) => mp === rp);
      if (fn) {
        accept = true;
      }
    });
  }

  return accept;
};

export default checkPermission;
