import {
  Checkbox,
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Text,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { EventCodeType } from '../../props/events';
import eventServices from '../../services/events';
import GeneralService from '../../services/general';
import LoadingComponent from '../loading';
import Barcode from '../barcode';

import styles from './styles.module.scss';

type TypePrintBarcodePanelProps = {
  children?: React.ReactNode;
  closePanel: (r?: boolean) => void;
  data: DataType[];
  onUpdateItems: (data: DataType[]) => void;
};

type DataType = {
  name: string;
  items: EventCodeType[];
  checked: boolean;
};

const PrintBarcodePanel: React.FC<TypePrintBarcodePanelProps> = (props) => {
  const [data, setData] = useState<DataType[]>(props.data);
  // const [showCodes, setShowCodes] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [templateUrl, setTemplateUrl] = useState<string | undefined>();
  const [cardWidth, setCardWidth] = useState<string>('200');
  const [barcodeWidth, setBarcodeWidth] = useState<string>('100');
  const [barcodeHeight, setBarcodeHeight] = useState<string>('');
  const [barcodeXPos, setBarcodeXPos] = useState<string>('');
  const [barcodeYPos, setBarcodeYPos] = useState<string>('');

  // useEffect(() => {
  //   if (showCodes) {
  //     getCodes();
  //   }
  //   return () => {};
  // }, [showCodes]);

  // const getCodes = async () => {
  //   setLoaded(false);
  //   if (eventId) {
  //     try {
  //       const codes = await eventServices.getCodes(eventId || '');
  //       const event = await eventServices.get(eventId);
  //       const formattedData = separateDataByName(codes);

  //       const data = Object.entries(formattedData).map((d) => ({ name: d[0], items: d[1], checked: true }));
  //       setData(data);
  //       if (event.cardTemplates[0]) {
  //         setTemplateUrl(GeneralService.getFileUrl(event.cardTemplates[0].url));
  //       }
  //       setLoaded(true);
  //     } catch (error) {}
  //   }
  // };

  const onChangeCheckbox = (
    index: number,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    const items = [...data];
    items[index].checked = checked ?? false;
    setData(items);
  };

  const onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => props.closePanel()} />
        <PrimaryButton text='Print' onClick={() => {}} />
      </Stack>
    );
  };

  return (
    <Panel
      headerText='Lihat / Sembunyikan'
      type={PanelType.medium}
      isOpen
      onDismiss={() => props.closePanel()}
      // onRenderFooterContent={onRenderFooter}
      isFooterAtBottom={true}
      closeButtonAriaLabel='Close'
    >
      <Stack tokens={{ childrenGap: 20 }}>
        {/* {loaded && (
          <Stack horizontal tokens={{ childrenGap: 20 }} className={'no-print'}>
            <TextField label={'Card width'} value={cardWidth} onChange={(e, v) => setCardWidth(v || '')} />
            <TextField
              label={'Barcode width'}
              value={barcodeWidth}
              onChange={(e, v) => setBarcodeWidth(v || '')}
            />
            <TextField
              label={'Barcode height'}
              value={barcodeHeight}
              onChange={(e, v) => setBarcodeHeight(v || '')}
            />
            <TextField
              label={'Barcode left position'}
              value={barcodeXPos}
              onChange={(e, v) => setBarcodeXPos(v || '')}
            />
            <TextField
              label={'Barcode top position'}
              value={barcodeYPos}
              onChange={(e, v) => setBarcodeYPos(v || '')}
            />
          </Stack>
        )} */}

        <Stack tokens={{ childrenGap: 20 }}>
          {data && (
            <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
              {data.map((item, ix) => (
                <Stack key={ix} className={styles.tag}>
                  <Checkbox
                    label={`${item.name} (${item.items.length} codes)`}
                    checked={item.checked}
                    onChange={(ev, checked) => onChangeCheckbox(ix, ev, checked)}
                  />
                </Stack>
              ))}
            </Stack>
          )}
          {/* <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
              {data
                .filter((f) => f.checked)
                .map((item) =>
                  item.items.map((code) => (
                    <Stack key={code.id} styles={{ root: { position: 'relative' } }}>
                      {templateUrl ? <img src={templateUrl} style={{ width: `${cardWidth}px` }} /> : null}
                      <Stack
                        key={code.id}
                        styles={{
                          root: { position: 'absolute', left: `${barcodeXPos}px`, top: `${barcodeYPos}px` },
                        }}
                      >
                        <Barcode
                          value={code.id}
                          barcodeWidth={`${barcodeWidth}px`}
                          barcodeHeight={`${barcodeHeight}px`}
                          left={`${barcodeXPos}px`}
                          top={`${barcodeYPos}px`}
                          options={{ background: 'transparent', displayValue: false }}
                        />
                      </Stack>
                    </Stack>
                  ))
                )}
            </Stack> */}
        </Stack>
      </Stack>
    </Panel>
  );
};

function separateDataByName(items: EventCodeType[]): { [key: string]: EventCodeType[] } {
  const separatedData: { [key: string]: EventCodeType[] } = {};

  for (const item of items) {
    const { name } = item;

    if (!separatedData[name]) {
      separatedData[name] = [];
    }
    separatedData[name].push(item);
  }
  return separatedData;
}

export default PrintBarcodePanel;
