import Request from './../request';
import { IExpensesResourceShort } from '../../props/expenses';

// import interfaces

const ExpensesService = {
  retrieve: async (): Promise<IExpensesResourceShort[]> => {
    try {
      const results = await Request.get(`/api/expenses`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IExpensesResourceShort[]> => {
    try {
      const results = await Request.post(`/api/expenses`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, id: string): Promise<IExpensesResourceShort[]> => {
    try {
      const results = await Request.post(`/api/expenses/${id}?_method=PUT`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<IExpensesResourceShort[]> => {
    try {
      const results = await Request.delete(`/api/expenses/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default ExpensesService;
