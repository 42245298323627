import React from 'react';

// impoert ui
import {
  ActionButton,
  CommandBar,
  CommandBarButton,
  IColumn,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services
import ProductTypeService from '../../../../services/products/type';

// import interfaces
import { IProductTypeResourceShort } from '../../../../props/products';
import AddProductTypePanel from './surfaces/panelAddProductType';
import UpdateProductTypePanel from './surfaces/panelUpdateProductType';
import Header from '../../../../components/header';
import { IRenderMessageBarProps } from '../../../../props/general';
import MessageBarComponent from '../../../../components/messageBar';
import GeneralConfig from '../../../../config';

// import component

interface IProductTypesPageProps {}

interface IProductTypesPageState {
  productTypes: IProductTypeResourceShort[];
  loaded: boolean;
  addProductTypeDialog?: JSX.Element;
  updateProductTypeDialog?: JSX.Element;
  messageBar?: IRenderMessageBarProps;
}

export default class ProductTypesPage extends React.Component<
  IProductTypesPageProps,
  IProductTypesPageState
> {
  private columns: IColumn[] = [
    {
      key: 'no',
      name: 'No',
      maxWidth: 20,
      minWidth: 20,
      isMultiline: true,
      className: 'textColumn',
      onRender: (item: IProductTypeResourceShort, ix) => (
        <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
          <Text>{(ix || 0) + 1}</Text>
        </Stack>
      ),
    },
    {
      key: 'image',
      name: 'Image',
      maxWidth: 300,
      minWidth: 300,
      isMultiline: true,
      onRender: (item: IProductTypeResourceShort, ix: any) => (
        <Stack
          horizontal
          styles={{ root: { width: '100%' } }}
          verticalAlign='center'
          tokens={{ childrenGap: 10 }}
          wrap
        >
          {item.images.map((i) => (
            <img
              key={i.id}
              src={GeneralConfig.assetsHostname + '/' + i.url}
              style={{ height: 60, width: 60 }}
            />
          ))}
        </Stack>
      ),
    },
    {
      key: 'name',
      name: 'Name',
      minWidth: 200,
      className: 'textColumn',
      onRender: (item: IProductTypeResourceShort) => (
        <Stack horizontal verticalAlign='center'>
          <Text>{item.name}</Text>
          <ActionButton
            menuProps={{
              items: [
                {
                  key: 'update',
                  text: 'Ubah',
                  iconProps: { iconName: 'SingleColumnEdit' },
                  onClick: () => {
                    this.setState({
                      updateProductTypeDialog: (
                        <UpdateProductTypePanel
                          closePanel={(refresh, messageBar) => {
                            this.setState({ updateProductTypeDialog: undefined, messageBar });
                            if (refresh) {
                              this.getProductTypes();
                            }
                          }}
                          did={item.id}
                        />
                      ),
                    });
                  },
                },
                {
                  key: 'delete',
                  text: 'Hapus',
                  iconProps: { iconName: 'Delete' },
                  onClick: () => {
                    this.deleteProductType(item);
                  },
                },
              ],
            }}
          />
        </Stack>
      ),
    },
  ];

  constructor(props: IProductTypesPageProps) {
    super(props);

    this.state = {
      productTypes: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getProductTypes();
  }

  private getProductTypes = async () => {
    this.setState({ loaded: false });
    try {
      const productTypes = await ProductTypeService.retrieve();
      this.setState({ productTypes, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { productTypes, loaded, addProductTypeDialog, updateProductTypeDialog, messageBar } = this.state;
    return (
      <Stack tokens={{ childrenGap: 20 }}>
        {addProductTypeDialog}
        {updateProductTypeDialog}
        <Header
          title={'Tipe Produk'}
          breadcrumbs={[
            { key: 'home', text: 'Beranda', href: '/' },
            { key: 'productTypes', text: 'Tipe Produk' },
          ]}
        />
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <Stack className='lou-content'>
          <CommandBar items={this.getItems()} />
          <ShimmeredDetailsList
            selectionMode={SelectionMode.none}
            items={productTypes}
            enableShimmer={!loaded}
            columns={this.columns}
          />
        </Stack>
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Tambah Tipe Produk'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() => {
                this.setState({
                  addProductTypeDialog: (
                    <AddProductTypePanel
                      closePanel={(f) => {
                        this.setState({ addProductTypeDialog: undefined });
                        if (f) {
                          this.getProductTypes();
                        }
                      }}
                    />
                  ),
                });
              }}
            />
          );
        },
      },
    ];
  };

  private deleteProductType = async (item: IProductTypeResourceShort) => {
    if (window.confirm(`Apakah anda yakin untuk menghapus tipe produk "${item.name}"?`)) {
      try {
        await ProductTypeService.delete(item.id);
        this.getProductTypes();
      } catch (error) {}
    }
  };
}
