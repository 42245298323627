import * as React from 'react';

import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// import ui
import {
  DatePicker,
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
  Dropdown,
  IDropdownOption,
} from '@fluentui/react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';

// import services
import validationService from '../../../../../services/validation';
import GeneralService from '../../../../../services/general';

// import component
import MessageBarComponent from '../../../../../components/messageBar';
import LoadingComponent from '../../../../../components/loading';
import ToppingServices from '../../../../../services/toppings';
import ProductTypeService from '../../../../../services/products/type';
import { IProductTypeResourceShort } from '../../../../../props/products';

interface IAddToppingPanelProps {
  closePanel: (rrefresh?: boolean, messageBar?: IRenderMessageBarProps) => void;
  orderNumber: number;
}

export type ToppingDataType = {
  description?: string;
  name?: string;
  price?: string;
  isFavourite?: 0 | 1;
  orderNumber?: string;
};

interface IAddToppingPanelState {
  loaded?: boolean;
  messageBar?: IRenderMessageBarProps;
  data?: ToppingDataType;
  errorData?: ToppingDataType;
  calloutTarget?: number;
  fileList: UploadFile[];
  submiting: boolean;
  productTypes: IProductTypeResourceShort[];
  productTypeId?: string;
}

export default class AddToppingPanel extends React.Component<IAddToppingPanelProps, IAddToppingPanelState> {
  constructor(props: IAddToppingPanelProps) {
    super(props);

    this.state = {
      data: {},
      loaded: false,
      fileList: [],
      submiting: false,
      productTypes: [],
    };
  }

  componentDidMount() {
    this.getProductTypes();
  }

  private getProductTypes = async () => {
    try {
      const productTypes = await ProductTypeService.retrieve();
      this.setState({ productTypes, loaded: true });
    } catch (error) {}
  };

  public render() {
    const {
      data,
      loaded,
      messageBar,
      errorData,
      calloutTarget,
      fileList,
      submiting,
      productTypes,
      productTypeId,
    } = this.state;
    return (
      <Panel
        headerText='Tambah Topping'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        <Stack tokens={{ childrenGap: 10 }}>
          {messageBar ? <MessageBarComponent {...messageBar} /> : null}
          {!loaded ? (
            <LoadingComponent label='Memuat form ...' spinnerPosition='baseline' labelPosition='right' />
          ) : null}
          {loaded ? (
            <>
              <Stack>
                <Upload
                  action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                  listType='picture'
                  maxCount={1}
                  onChange={this.handleChange}
                  fileList={fileList}
                  multiple={false}
                >
                  <Button icon={<UploadOutlined />}>Upload Gambar</Button>
                </Upload>
              </Stack>
              <TextField
                value={data?.name}
                errorMessage={errorData?.name}
                label='Nama'
                onChange={this.onChengeName}
                required
              />
              <TextField
                value={data?.description}
                errorMessage={errorData?.description}
                multiline
                rows={3}
                autoAdjustHeight
                resizable={false}
                label='Penjelasan / Catatan Tambahan'
                onChange={this.onChengeDescription}
              />
              <TextField
                value={data?.price}
                errorMessage={errorData?.price}
                label='Harga Tambahan'
                onChange={this.onChengePrice}
                prefix='Rp'
              />
              <Dropdown
                placeholder='Pilih tipe produk ...'
                label='Tipe Produk'
                options={productTypes.map((p) => ({ key: p.id, text: p.name }))}
                selectedKey={productTypeId}
                onChange={this.onChangeDropdownType}
                disabled={!loaded || productTypes.length === 0}
                required
              />
              <Toggle
                label='Favorit'
                onChange={this.onChangeToggle}
                checked={data?.isFavourite === 1 ? true : false}
              />
            </>
          ) : null}
        </Stack>
      </Panel>
    );
  }

  private onChangeDropdownType = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    if (option) {
      this.setState({ productTypeId: option.key as string });
    }
  };

  private onChengePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'number'], { maxChars: 11 }).message;
    this.setState({
      data: { ...this.state.data, price: v },
      errorData: { ...this.state.errorData, price: err },
    });
  };

  private onChangeToggle = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined
  ) => this.setState({ data: { ...this.state.data, isFavourite: checked ? 1 : 0 } });

  private handleChange = ({ fileList }: UploadChangeParam<UploadFile<any>>) => {
    this.setState({ fileList });
  };

  private onChengeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'required'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onRenderFooter = () => {
    if (!this.state.submiting) {
      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <PrimaryButton text='Submit' onClick={this.onSubmit} />
          <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        </Stack>
      );
    } else {
      return <LoadingComponent label={'Menambah Topping'} />;
    }
  };

  private onSubmit = async () => {
    const { data, fileList, productTypeId } = this.state;
    const fd = new FormData();

    if (data && data.name && productTypeId && fileList.length !== 0) {
      fileList.forEach((f: any) => fd.append('files[]', f.originFileObj));
      fd.append('name', data.name);
      data.price && fd.append('price', data.price);
      data.description && fd.append('description', data.description);
      data.isFavourite === 1 ? fd.append('isFavourite', '1') : fd.append('isFavourite', '0');
      fd.append('orderNumber', String(this.props.orderNumber));
      productTypeId && fd.append('productTypeId', productTypeId);
      this.setState({ submiting: true });
      try {
        await ToppingServices.create(fd);
        this.props.closePanel(true);
        this.setState({ submiting: false });
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
          submiting: false,
        });
      }
    }
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 2000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };
}
