import {
  DatePicker,
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from '@fluentui/react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import React, { useState } from 'react';
import ColorPicker from '../../../../../components/colorPicker';
import MessageBarComponent from '../../../../../components/messageBar';
import PicturesWall from '../../../../../components/uploadImage';
import GeneralConfig from '../../../../../config';
import { EventType } from '../../../../../props/events';
import { IRenderMessageBarProps } from '../../../../../props/general';
import eventServices from '../../../../../services/events';
import GeneralService from '../../../../../services/general';
import validationService from '../../../../../services/validation';
import { EventDataType } from './addEvent';

type UpdateEventPanelProps = {
  children?: React.ReactNode;
  closePanel: (refresh?: boolean) => void;
  event: EventType;
};

const UpdateEventPanel: React.FC<UpdateEventPanelProps> = (props) => {
  const [data, setData] = useState<EventDataType>({
    name: props.event.name,
    date: moment(props.event.date).toDate(),
    chooseFlavourTitle: props.event.chooseFlavourTitle,
    chooseFlavourTitleColor: props.event.chooseFlavourTitleColor,
    welcomeTitle: props.event.welcomeTitle,
    welcomeTitleColor: props.event.welcomeTitleColor,
    welcomeDescription: props.event.welcomeDescription,
    welcomeDescriptionColor: props.event.welcomeDescriptionColor,
    thankyouTitle: props.event.thankyouTitle,
    thankyouTitleColor: props.event.thankyouTitleColor,
    thankyouDescription: props.event.thankyouDescription,
    thankyouDescriptionColor: props.event.thankyouDescriptionColor,

    errorTitle: props.event.errorTitle,
    errorDescription: props.event.errorDescription,
    errorTitleColor: props.event.errorTitleColor,
    errorDescriptionColor: props.event.errorDescriptionColor,
    reedemedTitle: props.event.reedemedTitle,
    reedemedDescription: props.event.reedemedDescription,
    reedemedTitleColor: props.event.reedemedTitleColor,
    reedemedDescriptionColor: props.event.reedemedDescriptionColor,
  });
  const [errorData, setErrorData] = useState<EventDataType>();
  const [messageBar, setMessageBar] = useState<IRenderMessageBarProps>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile<any>[]>([]);
  const [mainBackgroundFiles, setMainBackgroundFiles] = useState<UploadFile<any>[]>([]);
  const [welcomeBackgroundFiles, setWelcomeBackgroundFiles] = useState<UploadFile<any>[]>([]);
  const [thankyouBackgroundFiles, setThankyouBackgroundFiles] = useState<UploadFile<any>[]>([]);

  const onSubmit = async () => {
    const fd = new FormData();
    data?.name && fd.append('name', data.name);
    data?.chooseFlavourTitle && fd.append('chooseFlavourTitle', data.chooseFlavourTitle);
    data?.chooseFlavourTitleColor && fd.append('chooseFlavourTitleColor', data.chooseFlavourTitleColor);
    data?.welcomeTitle && fd.append('welcomeTitle', data.welcomeTitle);
    data?.welcomeTitleColor && fd.append('welcomeTitleColor', data.welcomeTitleColor);
    data?.welcomeDescription && fd.append('welcomeDescription', data.welcomeDescription);
    data?.welcomeDescriptionColor && fd.append('welcomeDescriptionColor', data.welcomeDescriptionColor);
    data?.thankyouTitle && fd.append('thankyouTitle', data.thankyouTitle);
    data?.thankyouTitleColor && fd.append('thankyouTitleColor', data.thankyouTitleColor);
    data?.thankyouDescription && fd.append('thankyouDescription', data.thankyouDescription);
    data?.thankyouDescriptionColor && fd.append('thankyouDescriptionColor', data.thankyouDescriptionColor);
    data?.errorTitle && fd.append('errorTitle', data.errorTitle);
    data?.errorTitleColor && fd.append('errorTitleColor', data.errorTitleColor);
    data?.errorDescription && fd.append('errorDescription', data.errorDescription);
    data?.errorDescriptionColor && fd.append('errorDescriptionColor', data.errorDescriptionColor);
    data?.reedemedTitle && fd.append('reedemedTitle', data.reedemedTitle);
    data?.reedemedTitleColor && fd.append('reedemedTitleColor', data.reedemedTitleColor);
    data?.reedemedDescription && fd.append('reedemedDescription', data.reedemedDescription);
    data?.reedemedDescriptionColor && fd.append('reedemedDescriptionColor', data.reedemedDescriptionColor);
    data?.date && fd.append('date', moment(data.date).format('YYYY/MM/DD'));
    if (mainBackgroundFiles[0] && mainBackgroundFiles[0].originFileObj) {
      fd.append('mainBackground', mainBackgroundFiles[0].originFileObj);
    }
    if (welcomeBackgroundFiles[0] && welcomeBackgroundFiles[0].originFileObj) {
      fd.append('welcomeBackground', welcomeBackgroundFiles[0].originFileObj);
    }
    if (thankyouBackgroundFiles[0] && thankyouBackgroundFiles[0].originFileObj) {
      fd.append('thankyouBackground', thankyouBackgroundFiles[0].originFileObj);
    }
    setSubmitting(true);
    try {
      await eventServices.update(fd, props.event.code);
      props.closePanel(true);
    } catch (error) {
      setMessageBar({
        type: MessageBarType.error,
        text: GeneralService.getErrorMessage(error),
      });
      setSubmitting(false);
    }
  };

  const onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        {!submitting && (
          <>
            <DefaultButton text='Cancel' onClick={() => props.closePanel()} />
            <PrimaryButton text='Submit' onClick={onSubmit} />
          </>
        )}
        {submitting && <Spinner />}
      </Stack>
    );
  };

  const onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    setData({ ...data, name: v });
    setErrorData({ ...errorData, name: err });
  };

  const onChangeChooseFlavourTitle = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 100 }).message;
    setData({ ...data, chooseFlavourTitle: v });
    setErrorData({ ...errorData, chooseFlavourTitle: err });
  };

  const onChangeChooseFlavourTitleColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 10 }).message;
    setData({ ...data, chooseFlavourTitleColor: v });
    setErrorData({ ...errorData, chooseFlavourTitleColor: err });
  };

  const onChangeWelcomeTitle = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 100 }).message;
    setData({ ...data, welcomeTitle: v });
    setErrorData({ ...errorData, welcomeTitle: err });
  };

  const onChangeWelcomeTitleColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 10 }).message;
    setData({ ...data, welcomeTitleColor: v });
    setErrorData({ ...errorData, welcomeTitleColor: err });
  };

  const onChangeWelcomeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 255 }).message;
    setData({ ...data, welcomeDescription: v });
    setErrorData({ ...errorData, welcomeDescription: err });
  };

  const onChangeWelcomeDescriptionColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 10 }).message;
    setData({ ...data, welcomeDescriptionColor: v });
    setErrorData({ ...errorData, welcomeDescriptionColor: err });
  };

  const onChangeThankyouTitle = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 100 }).message;
    setData({ ...data, thankyouTitle: v });
    setErrorData({ ...errorData, thankyouTitle: err });
  };

  const onChangeThankyouTitleColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 10 }).message;
    setData({ ...data, thankyouTitleColor: v });
    setErrorData({ ...errorData, thankyouTitleColor: err });
  };

  const onChangeThankyouDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 255 }).message;
    setData({ ...data, thankyouDescription: v });
    setErrorData({ ...errorData, thankyouDescription: err });
  };

  const onChangeThankyouDescriptionColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 10 }).message;
    setData({ ...data, thankyouDescriptionColor: v });
    setErrorData({ ...errorData, thankyouDescriptionColor: err });
  };

  const onChangeErrorTitle = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 100 }).message;
    setData({ ...data, errorTitle: v });
    setErrorData({ ...errorData, errorTitle: err });
  };

  const onChangeErrorTitleColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 10 }).message;
    setData({ ...data, errorTitleColor: v });
    setErrorData({ ...errorData, errorTitleColor: err });
  };

  const onChangeErrorDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 255 }).message;
    setData({ ...data, errorDescription: v });
    setErrorData({ ...errorData, errorDescription: err });
  };

  const onChangeErrorDescriptionColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 10 }).message;
    setData({ ...data, errorDescriptionColor: v });
    setErrorData({ ...errorData, errorDescriptionColor: err });
  };

  const onChangeReedemedTitle = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 100 }).message;
    setData({ ...data, reedemedTitle: v });
    setErrorData({ ...errorData, reedemedTitle: err });
  };

  const onChangeReedemedTitleColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 10 }).message;
    setData({ ...data, reedemedTitleColor: v });
    setErrorData({ ...errorData, reedemedTitleColor: err });
  };

  const onChangeReedemedDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 255 }).message;
    setData({ ...data, reedemedDescription: v });
    setErrorData({ ...errorData, reedemedDescription: err });
  };

  const onChangeReedemedDescriptionColor = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 10 }).message;
    setData({ ...data, reedemedDescriptionColor: v });
    setErrorData({ ...errorData, reedemedDescriptionColor: err });
  };

  const onRemoveFileLogo = async (file: UploadFile<any>) => {
    try {
      await eventServices.logos.delete(props.event.code, file.uid);
    } catch (error) {}
  };

  const onRemoveFileBackground = async (file: UploadFile<any>) => {
    try {
      await eventServices.backgrounds.delete(file.uid);
    } catch (error) {}
  };

  const onAddFileLogo = async (file: RcFile) => {
    let fd = new FormData();
    fd.append('file', file);
    try {
      await eventServices.logos.create(props.event.code, fd);
    } catch (error) {}
  };

  return (
    <Panel
      headerText='Ubah Event'
      type={PanelType.medium}
      isOpen
      onDismiss={() => props.closePanel()}
      onRenderFooterContent={onRenderFooter}
      isFooterAtBottom={true}
      closeButtonAriaLabel='Close'
    >
      {messageBar ? <MessageBarComponent {...messageBar} /> : null}
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack>
          <Label>Logo</Label>
          <PicturesWall
            fileList={props.event.logos.map((f) => ({
              uid: f.id,
              name: f.name,
              status: 'done',
              url: GeneralConfig.assetsHostname + '/' + f.url,
            }))}
            onUpdate={(files) => setFiles(files)}
            onRemoveFile={onRemoveFileLogo}
            onAddFile={onAddFileLogo}
          />
        </Stack>
        {props.event.mainBackground && (
          <Stack>
            <Label>Main Background</Label>
            <PicturesWall
              fileList={[
                {
                  uid: props.event.mainBackground.id,
                  name: props.event.mainBackground.name,
                  status: 'done',
                  url: GeneralConfig.assetsHostname + '/' + props.event.mainBackground.url,
                },
              ]}
              onUpdate={(files) => setMainBackgroundFiles(files)}
              onRemoveFile={onRemoveFileBackground}
            />
          </Stack>
        )}
        <TextField
          label='Choose Flavour Title'
          value={data?.chooseFlavourTitle}
          errorMessage={errorData?.chooseFlavourTitle}
          onChange={onChangeChooseFlavourTitle}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Choose Flavour Title Color</Label>

          <ColorPicker
            color={data?.chooseFlavourTitleColor}
            onSelectColor={(color: string) => setData({ ...data, chooseFlavourTitleColor: color })}
          />
        </Stack>
        {props.event.welcomeBackground && (
          <Stack>
            <Label>Welcome Background</Label>
            <PicturesWall
              fileList={[
                {
                  uid: props.event.welcomeBackground.id,
                  name: props.event.welcomeBackground.name,
                  status: 'done',
                  url: GeneralConfig.assetsHostname + '/' + props.event.welcomeBackground.url,
                },
              ]}
              onUpdate={(files) => setWelcomeBackgroundFiles(files)}
              onRemoveFile={onRemoveFileBackground}
            />
          </Stack>
        )}
        <TextField
          label='Welcome Title'
          value={data?.welcomeTitle}
          errorMessage={errorData?.welcomeTitle}
          onChange={onChangeWelcomeTitle}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Welcome Title Color</Label>

          <ColorPicker
            color={data?.welcomeTitleColor}
            onSelectColor={(color: string) => setData({ ...data, welcomeTitleColor: color })}
          />
        </Stack>
        <TextField
          label='Welcome Description'
          multiline
          value={data?.welcomeDescription}
          errorMessage={errorData?.welcomeDescription}
          onChange={onChangeWelcomeDescription}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Welcome Description Color</Label>

          <ColorPicker
            color={data?.welcomeDescriptionColor}
            onSelectColor={(color: string) => setData({ ...data, welcomeDescriptionColor: color })}
          />
        </Stack>
        {props.event.thankyouBackground && (
          <Stack>
            <Label>Thank You Background</Label>
            <PicturesWall
              fileList={[
                {
                  uid: props.event.thankyouBackground.id,
                  name: props.event.thankyouBackground.name,
                  status: 'done',
                  url: GeneralConfig.assetsHostname + '/' + props.event.thankyouBackground.url,
                },
              ]}
              onUpdate={(files) => setThankyouBackgroundFiles(files)}
              onRemoveFile={onRemoveFileBackground}
            />
          </Stack>
        )}
        <TextField
          label='Thank You Title'
          value={data?.thankyouTitle}
          errorMessage={errorData?.thankyouTitle}
          onChange={onChangeThankyouTitle}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Thank You Title Color</Label>

          <ColorPicker
            color={data?.thankyouTitleColor}
            onSelectColor={(color: string) => setData({ ...data, thankyouTitleColor: color })}
          />
        </Stack>
        <TextField
          label='Thank You Description'
          value={data?.thankyouDescription}
          multiline
          errorMessage={errorData?.thankyouDescription}
          onChange={onChangeThankyouDescription}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Thank You Description Color</Label>

          <ColorPicker
            color={data?.thankyouDescriptionColor}
            onSelectColor={(color: string) => setData({ ...data, thankyouDescriptionColor: color })}
          />
        </Stack>
        <TextField
          label='Error Title'
          value={data?.errorTitle}
          errorMessage={errorData?.errorTitle}
          onChange={onChangeErrorTitle}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Error Title Color</Label>

          <ColorPicker
            color={data?.errorTitleColor}
            onSelectColor={(color: string) => setData({ ...data, errorTitleColor: color })}
          />
        </Stack>
        <TextField
          label='Error Description'
          multiline
          value={data?.errorDescription}
          errorMessage={errorData?.errorDescription}
          onChange={onChangeErrorDescription}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Error Description Color</Label>

          <ColorPicker
            color={data?.errorDescriptionColor}
            onSelectColor={(color: string) => setData({ ...data, errorDescriptionColor: color })}
          />
        </Stack>
        <TextField
          label='Reedemed Title'
          value={data?.reedemedTitle}
          errorMessage={errorData?.reedemedTitle}
          onChange={onChangeReedemedTitle}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Reedemed Title Color</Label>

          <ColorPicker
            color={data?.reedemedTitleColor}
            onSelectColor={(color: string) => setData({ ...data, reedemedTitleColor: color })}
          />
        </Stack>
        <TextField
          label='Reedemed Description'
          multiline
          value={data?.reedemedDescription}
          errorMessage={errorData?.reedemedDescription}
          onChange={onChangeReedemedDescription}
        />
        <Stack horizontal verticalAlign='center' styles={{ root: { width: '100%' } }}>
          <Label>Reedemed Description Color</Label>

          <ColorPicker
            color={data?.reedemedDescriptionColor}
            onSelectColor={(color: string) => setData({ ...data, reedemedDescriptionColor: color })}
          />
        </Stack>
        <TextField label='Nama' value={data?.name} errorMessage={errorData?.name} onChange={onChangeName} />
        <DatePicker
          label='Tanggal'
          formatDate={(date) => moment(date).format('DD/MM/YYYY')}
          value={data?.date}
          onSelectDate={(date) => date !== null && setData({ ...data, date })}
        />
      </Stack>
    </Panel>
  );
};

export default UpdateEventPanel;
