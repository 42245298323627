import * as React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';

// import ui
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, Text } from '@fluentui/react';

// import services
import FlavourService from '../../../../../services/flavours';

// import interfaces
import { IFlavourResourceShort } from '../../../../../props/flavours';

// import component

// import styles
import styles from './styles.module.scss';
import GeneralConfig from '../../../../../config';
import LoadingComponent from '../../../../../components/loading';

interface ISortFlavourPanelProps {
  closePanel: (refresh?: boolean) => void;
}

interface IUpdateFlavour extends IFlavourResourceShort {
  orderNumber: string;
}

interface ISortFlavourPanelState {
  flavours: IFlavourResourceShort[];
  updateFlavours: IFlavourResourceShort[];
  loaded: boolean;
  submiting: boolean;
}

export default class SortFlavourPanel extends React.Component<
  ISortFlavourPanelProps,
  ISortFlavourPanelState
> {
  constructor(props: ISortFlavourPanelProps) {
    super(props);

    this.state = {
      flavours: [],
      loaded: false,
      submiting: false,
      updateFlavours: [],
    };
  }

  componentDidMount() {
    this.getFlavours();
  }

  private getFlavours = async () => {
    this.setState({ loaded: false });
    try {
      const flavours = await FlavourService.retrieve();
      this.setState({ flavours, loaded: true });
    } catch (error) {}
  };

  public render() {
    const { flavours, loaded } = this.state;
    return (
      <Panel
        headerText='Ubah Urutan Produk'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {loaded && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <section {...provided.droppableProps} ref={provided.innerRef}>
                  {flavours.map((f, index) => (
                    <Draggable key={f.id} draggableId={f.id} index={index}>
                      {(provided, snapshot) => (
                        <section
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            position: 'static',
                            background: '#ffffff',
                          }}
                          className={styles.columnItem}
                        >
                          <Stack
                            key={f.id}
                            className={styles.item}
                            horizontal
                            verticalAlign='center'
                            tokens={{ childrenGap: 10 }}
                          >
                            {f.images.map((i) => (
                              <img
                                key={i.id}
                                src={GeneralConfig.assetsHostname + '/' + i.url}
                                className={styles.image}
                              />
                            ))}
                            <Text>{f.name}</Text>
                          </Stack>
                        </section>
                      )}
                    </Draggable>
                  ))}
                </section>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Panel>
    );
  }

  private onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { flavours: items } = this.state;
    let newNumber: number = 0;

    const lastIndex = items.length - 1;
    if (result.destination) {
      const to = result.destination.index;
      const from = result.source.index;

      if (Number(items[from].orderNumber) > Number(items[to].orderNumber)) {
        if (to - 1 !== lastIndex) {
          if (items[to - 1]) {
            newNumber = (Number(items[to].orderNumber) + Number(items[to - 1].orderNumber)) / 2;
          } else {
            newNumber = Number(items[to].orderNumber) / 2;
          }
        } else {
          newNumber = Number(items[to].orderNumber) - 1;
        }
      } else {
        if (items[to + 1]) {
          newNumber = (Number(items[to].orderNumber) + Number(items[to + 1].orderNumber)) / 2;
        } else {
          newNumber = Number(items[to].orderNumber) + 1;
        }
      }

      Number.isNaN(newNumber) && (newNumber = Math.floor(Math.random() * 10) + 1);

      this.updateColumns(items, from, to, newNumber);

      this.updateOrderNumber(newNumber, items[from]);
    }
  };

  private updateOrderNumber = async (orderNumber: number, flavour: IFlavourResourceShort) => {
    const { updateFlavours } = this.state;
    const findToppings = updateFlavours.findIndex((f) => f.id === flavour.id);
    if (updateFlavours[findToppings]) {
      updateFlavours[findToppings].orderNumber = String(orderNumber);
    } else {
      updateFlavours.push(flavour);
    }

    this.setState({ updateFlavours });
  };

  private updateColumns = (
    flavours: IFlavourResourceShort[],
    from: number,
    to: number,
    newOrderNumber: number
  ) => {
    flavours[from].orderNumber = String(newOrderNumber);
    this.setState({ flavours: this._reorder(flavours, from, to) });
  };

  private _reorder = (list: IFlavourResourceShort[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  private onRenderFooter = () => {
    if (!this.state.submiting) {
      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          {this.state.updateFlavours.length !== 0 && <PrimaryButton text='Save' onClick={this.onSubmit} />}
          <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        </Stack>
      );
    } else {
      return <LoadingComponent label={'Mengubah Urutan Flavour'} />;
    }
  };

  private onSubmit = async () => {
    const { updateFlavours } = this.state;

    const fd = new FormData();
    updateFlavours.forEach((u) => fd.append('flavours[]', JSON.stringify(u)));
    this.setState({ submiting: true });
    try {
      await FlavourService.reorder(fd);
      this.props.closePanel(true);
      this.setState({ submiting: false });
    } catch (error) {
      this.setState({ submiting: false });
    }
  };
}
