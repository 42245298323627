import {
  DatePicker,
  DefaultButton,
  ITag,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TagPicker,
  TextField,
  Toggle,
} from '@fluentui/react';
import moment from 'moment';
import React, { useState } from 'react';
import MessageBarComponent from '../../../../../components/messageBar';
import { EventCodeType, EventType } from '../../../../../props/events';
import { IRenderMessageBarProps } from '../../../../../props/general';
import eventServices from '../../../../../services/events';
import eventCodeServices from '../../../../../services/events/code';
import GeneralService from '../../../../../services/general';
import validationService from '../../../../../services/validation';
import { EventCodeDataType } from './addEventCode';

type UpdateEventCodePanelProps = {
  children?: React.ReactNode;
  closePanel: (refresh?: boolean) => void;
  eventCode: EventCodeType;
};

const UpdateEventCodePanel: React.FC<UpdateEventCodePanelProps> = (props) => {
  const [data, setData] = useState<EventCodeDataType>({
    name: props.eventCode.name,
    isRedeemed: props.eventCode.isRedeemed === 1,
  });
  const [errorData, setErrorData] = useState<EventCodeDataType>();
  const [messageBar, setMessageBar] = useState<IRenderMessageBarProps>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedEvents, setSelectedEvents] = useState<ITag[]>([
    { key: props.eventCode.eventCode, name: props.eventCode.event.name },
  ]);

  const onSubmit = async () => {
    const fd = new FormData();
    if (data?.name && selectedEvents[0]) {
      fd.append('name', data.name);
      data.isRedeemed && fd.append('isRedeemed', data.isRedeemed ? '1' : '0');
      setSubmitting(true);
      try {
        await eventCodeServices.update(fd, selectedEvents[0].key as string, props.eventCode.id);
        props.closePanel(true);
      } catch (error) {
        setMessageBar({
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(error),
        });
        setSubmitting(false);
      }
    }
  };

  const onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        {!submitting && (
          <>
            <DefaultButton text='Cancel' onClick={() => props.closePanel()} />
            <PrimaryButton text='Submit' onClick={onSubmit} />
          </>
        )}
        {submitting && <Spinner />}
      </Stack>
    );
  };

  const onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    setData({ ...data, name: v });
    setErrorData({ ...errorData, name: err });
  };

  const selectEvent = (selectedItem?: ITag | undefined): ITag | PromiseLike<ITag> | null => {
    if (selectedItem) {
      setSelectedEvents([selectedItem]);
    }
    return null;
  };

  const onResolveSuggestions = async (
    keyword: string,
    selectedItems?: ITag[] | undefined
  ): Promise<ITag[]> => {
    try {
      const data = (await eventServices.retrieve(keyword)).map((item) => ({
        key: item.code,
        name: item.name,
      }));
      return data;
    } catch (error) {
      return [];
    }
  };

  return (
    <Panel
      headerText='Ubah Event Code'
      type={PanelType.medium}
      isOpen
      onDismiss={() => props.closePanel()}
      onRenderFooterContent={onRenderFooter}
      isFooterAtBottom={true}
      closeButtonAriaLabel='Close'
    >
      {messageBar ? <MessageBarComponent {...messageBar} /> : null}
      <Stack tokens={{ childrenGap: 10 }}>
        <TextField
          required
          label='Nama'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={onChangeName}
        />
        <Stack.Item>
          <Label required>Event</Label>
          <TagPicker
            selectedItems={selectedEvents}
            removeButtonAriaLabel='Remove'
            itemLimit={1}
            onItemSelected={selectEvent}
            onChange={(items) => {
              items && items.length === 0 && setSelectedEvents([]);
            }}
            onResolveSuggestions={onResolveSuggestions}
          />
        </Stack.Item>
        <Toggle
          label='Ditebus'
          checked={data?.isRedeemed}
          onChange={(e, c) => setData({ ...data, isRedeemed: c })}
        />
      </Stack>
    </Panel>
  );
};

export default UpdateEventCodePanel;
