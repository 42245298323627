import React from 'react';

// impoert ui
import {
  CommandBar,
  CommandBarButton,
  DefaultButton,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles

// import services

// import interfaces
import { IDeliveryMethodResourceShort } from '../../../../props/deliveries';

// import component
import AddDeliveryMethodPanel from './surfaces/panelAddDeliveryMethod';
import UpdateDeliveryMethodPanel from './surfaces/panelUpdateDeliveryMethod';
import SortDeliveyMethodsPanel from './surfaces/sortDeliveryPanel';

// import services
import DeliveryMethodService from '../../../../services/deliveries';
import { ProviderStore } from '../../../../context';
import checkPermission from '../../../../utils/checkPermission';

// import component

interface IDeliveryMethodPageProps {
  context: ProviderStore;
}

interface IDeliveryMethodPageState {
  deliveyMethods: IDeliveryMethodResourceShort[];
  loaded: boolean;
  addDeliveryMethodDialog?: JSX.Element;
  updateDeliveryMethodDialog?: JSX.Element;
  sortDeliveryMethodPanel?: JSX.Element;
}

export default class DeliveryMethodPage extends React.Component<
  IDeliveryMethodPageProps,
  IDeliveryMethodPageState
> {
  constructor(props: IDeliveryMethodPageProps) {
    super(props);

    this.state = {
      deliveyMethods: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getDeliveryMethod();
  }

  private getDeliveryMethod = async () => {
    this.setState({ loaded: false });
    try {
      const deliveyMethods = await DeliveryMethodService.retrieve();
      this.setState({ deliveyMethods, loaded: true });
    } catch (error) {}
  };

  public render() {
    const {
      deliveyMethods,
      loaded,
      addDeliveryMethodDialog,
      updateDeliveryMethodDialog,
      sortDeliveryMethodPanel,
    } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {addDeliveryMethodDialog}
        {updateDeliveryMethodDialog}
        {sortDeliveryMethodPanel}
        <Text variant={'xLarge'}>DeliveryMethod</Text>
        <CommandBar items={this.getItems()} />
        <ShimmeredDetailsList
          selectionMode={SelectionMode.none}
          items={deliveyMethods}
          enableShimmer={!loaded}
          columns={[
            {
              key: 'no',
              name: 'No',
              maxWidth: 20,
              minWidth: 20,
              isMultiline: true,
              onRender: (item: IDeliveryMethodResourceShort, ix) => (
                <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
                  <Text>{(ix || 0) + 1}</Text>
                </Stack>
              ),
            },
            {
              key: 'name',
              name: 'Name',
              maxWidth: 200,
              minWidth: 200,
              isMultiline: true,
              onRender: (item: IDeliveryMethodResourceShort) => (
                <Stack
                  horizontal
                  styles={{ root: { width: '100%' } }}
                  verticalAlign='center'
                  horizontalAlign='space-between'
                >
                  <Text>{item.name}</Text>
                  <CommandBarButton
                    menuProps={{
                      items: [
                        {
                          key: 'update',
                          text: 'Update',
                          iconProps: { iconName: 'SingleColumnEdit' },
                          onClick: () => {
                            this.setState({
                              updateDeliveryMethodDialog: (
                                <UpdateDeliveryMethodPanel
                                  closePanel={(f) => {
                                    this.setState({ updateDeliveryMethodDialog: undefined });
                                    if (f) {
                                      this.getDeliveryMethod();
                                    }
                                  }}
                                  deliveryMethod={item}
                                />
                              ),
                            });
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'update_delivery',
                          ]),
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'Delete' },
                          onClick: () => {
                            this.deleteDeliveryMethod(item);
                          },
                          disabled: !checkPermission(this.props.context.globalState.myPermissionKeys, [
                            'delete_delivery',
                          ]),
                        },
                      ],
                    }}
                  />
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    );
  }

  private getItems = () => {
    const { loaded, deliveyMethods } = this.state;
    return [
      {
        key: 'search',
        text: 'Search',
        onRender: () => {
          return <SearchBox disabled={!loaded} />;
        },
      },
      {
        key: 'add',
        text: 'Add',
        onRender: () => {
          return (
            <PrimaryButton
              disabled={!loaded}
              iconProps={{ iconName: 'Add' }}
              text='Add DeliveryMethod'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() => {
                this.setState({
                  addDeliveryMethodDialog: (
                    <AddDeliveryMethodPanel
                      orderNumber={
                        deliveyMethods.length !== 0
                          ? Number(deliveyMethods[deliveyMethods.length - 1].orderNumber) + 1
                          : 1
                      }
                      closePanel={(f) => {
                        this.setState({ addDeliveryMethodDialog: undefined });
                        if (f) {
                          this.getDeliveryMethod();
                        }
                      }}
                    />
                  ),
                });
              }}
            />
          );
        },
      },
      {
        key: 'sort_toppings',
        text: 'Sort Delivery',
        onRender: () => {
          return (
            <DefaultButton
              disabled={!loaded}
              iconProps={{ iconName: 'ArrowDownUp' }}
              text='Ubah Urutan Metode Pengiriman'
              styles={{ root: { marginLeft: 10 } }}
              onClick={() =>
                this.setState({
                  sortDeliveryMethodPanel: (
                    <SortDeliveyMethodsPanel
                      closePanel={(r) => {
                        this.setState({ sortDeliveryMethodPanel: undefined });
                        if (r) {
                          this.getDeliveryMethod();
                        }
                      }}
                    />
                  ),
                })
              }
            />
          );
        },
      },
    ];
  };

  private deleteDeliveryMethod = async (item: IDeliveryMethodResourceShort) => {
    if (window.confirm('Delete Packaging')) {
      try {
        await DeliveryMethodService.delete(item.id);
        this.getDeliveryMethod();
      } catch (error) {}
    }
  };
}
