import React from 'react';

// import manifests

// import configs

// import stylings

// import props
import { IRenderMessageBarProps } from '../../props/general';

// import services

// import components

// import antd component
import { MessageBar, Text } from '@fluentui/react';

export interface IMessageBarComponentProps extends IRenderMessageBarProps {
    onDismiss?(): void;
}

export default class MessageBarComponent extends React.Component<IMessageBarComponentProps, {}> {

    constructor(props: IMessageBarComponentProps) {
        super(props);
        this.state = {};
    }

    public render() {
        return <MessageBar messageBarType={this.props.type} isMultiline={true} onDismiss={this.props.onDismiss}>
            {this.props.text ? <Text variant='small'>{this.props.text}</Text> : null}
        </MessageBar>
    }
}