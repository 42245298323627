import Request from './../request';
import { IProductResourceShort } from '../../props/products';

// import interfaces

const ProductService = {
  retrieve: async (): Promise<IProductResourceShort[]> => {
    try {
      const results = await Request.get(`/api/products`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IProductResourceShort[]> => {
    try {
      const results = await Request.post(`/api/products`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, id: string): Promise<IProductResourceShort[]> => {
    try {
      const results = await Request.post(`/api/products/${id}?_method=PUT`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<IProductResourceShort[]> => {
    try {
      const results = await Request.delete(`/api/products/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  reorder: async (data: FormData) => {
    try {
      const result = await Request.post(`/api/reorderProducts`, data, 'multipart/form-data');
      return result;
    } catch (error) {
      throw error;
    }
  },
  packaging: {
    create: async (productId: string, data: FormData): Promise<IProductResourceShort[]> => {
      try {
        const results = await Request.post(
          `/api/products/${productId}/packagings`,
          data,
          'multipart/form-data'
        );
        return results;
      } catch (e) {
        throw e;
      }
    },
  },
};

export default ProductService;
