import Request from './../request';
import { IToppingImagesResourceShort } from '../../props/toppings';

// import interfaces

const ToppingImageService = {
  create: async (closuredateId: string, data: FormData): Promise<IToppingImagesResourceShort[]> => {
    try {
      const results = await Request.post(
        `/api/toppings/${closuredateId}/images`,
        data,
        'multipart/form-data'
      );
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (closuredateId: string, id: string): Promise<IToppingImagesResourceShort> => {
    try {
      const results = await Request.delete(`/api/toppings/${closuredateId}/images/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default ToppingImageService;
