import * as React from 'react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';

// import ui
import {
  DatePicker,
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';
import ProductService from '../../../../../services/products';

// import component

// import interfaces
import { IProductResourceShort } from '../../../../../props/products';

// import config
import GeneralConfig from '../../../../../config';
import ProductImageService from '../../../../../services/products/images';
import { ProductDataType } from './panelAddExpenses';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import MessageBarComponent from '../../../../../components/messageBar';
import PicturesWall from '../../../../../components/picturesWall';
import { IExpensesResourceShort } from '../../../../../props/expenses';
import ExpensesImageService from '../../../../../services/deliveries/images';
import moment from 'moment';

interface IUpdateExpensesPanelProps {
  closePanel: (r?: boolean) => void;
  expenses: IExpensesResourceShort;
}

interface IUpdateExpensesPanelState {
  expenses: IExpensesResourceShort;
  data?: ProductDataType;
  errorData?: ProductDataType;
  files: UploadFile<any>[];
  loaded: boolean;
  messageBar?: IRenderMessageBarProps;
}

export default class UpdateExpensesPanel extends React.Component<
  IUpdateExpensesPanelProps,
  IUpdateExpensesPanelState
> {
  constructor(props: IUpdateExpensesPanelProps) {
    super(props);

    this.state = {
      files: [],
      expenses: props.expenses,
      data: {
        name: props.expenses.name,
        description: props.expenses.description,
        amount: props.expenses.amount,
        date: props.expenses?.date ? new Date(props.expenses?.date) : undefined,
      },
      loaded: true,
    };
  }

  public render() {
    const { data, errorData, expenses, loaded, messageBar } = this.state;
    return (
      <Panel
        headerText='Update'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <Stack styles={{ root: { marginTop: 10 } }}>
          <Label>Gambar</Label>
          <PicturesWall
            fileList={expenses.images.map((f) => ({
              uid: f.id,
              name: f.name,
              status: 'done',
              url: GeneralConfig.assetsHostname + '/' + f.url,
            }))}
            onUpdate={(files) => this.setState({ files })}
            onRemoveFile={this.onRemoveFile}
            onAddFile={this.onAddFile}
          />
        </Stack>
        <TextField
          label='Name'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={this.onChangeName}
          disabled={!loaded}
        />
        <TextField
          value={data?.description}
          errorMessage={errorData?.description}
          label='Detail / Catatan Tambahan'
          onChange={this.onChengeDescription}
          disabled={!loaded}
        />
        <TextField
          value={data?.amount}
          errorMessage={errorData?.amount}
          label='Total'
          onChange={this.onChengePrice}
          disabled={!loaded}
          prefix={'Rp'}
        />
        <DatePicker
          label='Tanggal'
          value={data?.date}
          onSelectDate={this.onChangeDate}
          formatDate={(d) => moment(d).format('DD/MM/YYYY')}
        />
      </Panel>
    );
  }

  private onChangeDate = (date: Date | null | undefined) => {
    if (date) {
      this.setState({ data: { ...this.state.data, date: date } });
    }
  };

  private onAddFile = async (file: RcFile) => {
    let fd = new FormData();
    fd.append('file', file);
    try {
      await ExpensesImageService.create(this.props.expenses.id, fd);
    } catch (error) {}
  };

  private onRemoveFile = async (file: UploadFile<any>) => {
    try {
      await ExpensesImageService.delete(this.props.expenses.id, file.uid);
    } catch (error) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(error),
        },
      });
    }
  };

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, errorData } = this.state;
    const fd = new FormData();
    data?.name && fd.append('name', data?.name);
    data?.amount && fd.append('amount', data?.amount);
    data?.description && fd.append('description', data?.description);
    data?.date && fd.append('date', data?.date.toISOString());
    try {
      await ProductService.update(fd, this.props.expenses.id);
      this.props.closePanel(true);
    } catch (error) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(error),
        },
      });
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChengeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 1000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };

  private onChengePrice = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'number'], { maxChars: 10 }).message;
    this.setState({
      data: { ...this.state.data, amount: v },
      errorData: { ...this.state.errorData, amount: err },
    });
  };
}
