import { Component } from 'react';

// import ui
import {
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Text,
  MessageBar,
} from '@fluentui/react';
import { IUserResourceShortProps } from '../../../../../props/user';
import validationService from '../../../../../services/validation';
import UserService from '../../../../../services/auth/user';
import { IMessageBarContentProps } from '../../../../../props/general';

type Props = {
  onDismiss: (user?: IUserResourceShortProps) => void;
};

type State = {
  passwordConfirmation?: string;
  errorPasswordConfirmation?: string;
  oldPassword?: string;
  newPassword?: string;
  errorNewPassword?: string;
  errorOldPassword?: string;
  messageBar?: IMessageBarContentProps;
};

export default class PanelUpdatePassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      oldPassword,
      errorOldPassword,
      newPassword,
      errorNewPassword,
      passwordConfirmation,
      errorPasswordConfirmation,
    } = this.state;
    return (
      <Panel
        headerText='Ubah Email'
        type={PanelType.medium}
        isOpen
        onDismiss={() => {
          this.props.onDismiss();
        }}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {this.state.messageBar ? (
          <Stack styles={{ root: { marginTop: 20 } }}>
            <MessageBar
              messageBarType={this.state.messageBar.type}
              isMultiline={true}
              onDismiss={() => {
                this.setState({ messageBar: undefined });
              }}
            >
              {this.state.messageBar.content}
            </MessageBar>
          </Stack>
        ) : null}
        <TextField
          label='Password Lama'
          type='password'
          value={oldPassword}
          onChange={(e, v) => {
            const err = validationService.combination(v, ['limit', 'required'], { maxChars: 255 }).message;
            this.setState({ oldPassword: v, errorOldPassword: err });
          }}
          errorMessage={errorOldPassword}
        />
        <TextField
          label='Password Baru'
          type='password'
          value={newPassword}
          onChange={(e, v) => {
            const err = validationService.combination(v, ['limit', 'required'], { maxChars: 255 }).message;
            this.setState({ newPassword: v, errorNewPassword: err });
          }}
          errorMessage={errorNewPassword}
        />
        <TextField
          label='Konfirmasi'
          type='password'
          value={passwordConfirmation}
          onChange={(e, v) => {
            const err = validationService.combination(v, ['limit', 'required'], { maxChars: 255 }).message;
            this.setState({ passwordConfirmation: v, errorPasswordConfirmation: err });
          }}
          errorMessage={errorPasswordConfirmation}
        />
      </Panel>
    );
  }

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
        <DefaultButton text='Cancel' onClick={() => this.props.onDismiss()} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { newPassword, passwordConfirmation, oldPassword } = this.state;
    if (newPassword && newPassword.trim() !== '' && oldPassword && oldPassword.trim() !== '') {
      if (newPassword === passwordConfirmation) {
        const fd = new FormData();
        fd.append('newPassword', newPassword);
        fd.append('oldPassword', oldPassword);
        try {
          const res = await UserService.updatePassword(fd);
          this.props.onDismiss(res);
        } catch (error) {
          this.setState({
            messageBar: {
              content: <Text variant={'small'}>{(error as any).message}</Text>,
              type: MessageBarType.error,
            },
          });
        }
      } else {
        this.setState({ errorPasswordConfirmation: 'konfirmasi password salah' });
      }
    }
  };
}
