import * as React from 'react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import {
  DatePicker,
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import validationService from '../../../../../services/validation';
import ProductService from '../../../../../services/products';
import GeneralConfig from '../../../../../config';
import { IRenderMessageBarProps } from '../../../../../props/general';
import GeneralService from '../../../../../services/general';
import MessageBarComponent from '../../../../../components/messageBar';
import PicturesWall from '../../../../../components/picturesWall';
import FaqsImageService from '../../../../../services/deliveries/images';
import moment from 'moment';
import { IFaqResourceShort } from '../../../../../props/faqs';
import { FaqDataType } from './panelAddExpenses';

interface IUpdateFaqsPanelProps {
  closePanel: (r?: boolean) => void;
  faqs: IFaqResourceShort;
}

interface IUpdateFaqsPanelState {
  faqs: IFaqResourceShort;
  data: FaqDataType;
  errorData?: FaqDataType;
  loaded: boolean;
  messageBar?: IRenderMessageBarProps;
  submitting: boolean;
}

export default class UpdateFaqsPanel extends React.Component<IUpdateFaqsPanelProps, IUpdateFaqsPanelState> {
  constructor(props: IUpdateFaqsPanelProps) {
    super(props);

    this.state = {
      faqs: props.faqs,
      data: {
        answer: props.faqs.answer,
        question: props.faqs.question,
      },
      loaded: true,
      submitting: false,
    };
  }

  public render() {
    const { data, errorData, faqs, loaded, messageBar } = this.state;
    return (
      <Panel
        headerText='Update'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <TextField
          label='Pertanyaan'
          value={data?.question}
          errorMessage={errorData?.question}
          onChange={this.onChangeQuestion}
          disabled={!loaded}
        />
        <TextField
          value={data?.answer}
          errorMessage={errorData?.answer}
          label='Jawaban'
          onChange={this.onChengeAnswer}
          disabled={!loaded}
        />
      </Panel>
    );
  }

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data } = this.state;
    const fd = new FormData();
    data.answer && fd.append('answer', data.answer);
    data.question && fd.append('question', data.question);
    this.setState({ submitting: true });
    try {
      await ProductService.update(fd, this.props.faqs.id);
      this.props.closePanel(true);
    } catch (error) {
      this.setState({
        messageBar: {
          type: MessageBarType.error,
          text: GeneralService.getErrorMessage(error),
        },
        submitting: false,
      });
    }
  };

  private onChangeQuestion = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, question: v },
      errorData: { ...this.state.errorData, question: err },
    });
  };

  private onChengeAnswer = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'required'], { maxChars: 1000 }).message;
    this.setState({
      data: { ...this.state.data, answer: v },
      errorData: { ...this.state.errorData, answer: err },
    });
  };
}
