import * as React from 'react';

import {
  DatePicker,
  DefaultButton,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';
import GeneralService from '../../../../../services/general';

// import component
import { UploadFile } from 'antd/lib/upload/interface';
import MessageBarComponent from '../../../../../components/messageBar';
import PicturesWall from '../../../../../components/picturesWall';

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';
import FaqsService from '../../../../../services/faqs';
import moment from 'moment';

interface IAddFaqPanelProps {
  closePanel: (r?: boolean) => void;
}

export type FaqDataType = {
  question?: string;
  answer?: string;
};

interface IAddFaqPanelState {
  data?: FaqDataType;
  errorData?: FaqDataType;
  loaded: boolean;
  messageBar?: IRenderMessageBarProps;
  submitting: boolean;
}

export default class AddFaqPanel extends React.Component<IAddFaqPanelProps, IAddFaqPanelState> {
  constructor(props: IAddFaqPanelProps) {
    super(props);

    this.state = {
      loaded: true,
      submitting: false,
    };
  }

  public render() {
    const { data, errorData, loaded, messageBar } = this.state;
    return (
      <Panel
        headerText='Tambah Faqs'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <Stack tokens={{ childrenGap: 10 }}>
          <TextField
            label='Pertanyaan'
            value={data?.question}
            errorMessage={errorData?.question}
            onChange={this.onChangeQuestion}
            disabled={!loaded}
          />
          <TextField
            value={data?.answer}
            errorMessage={errorData?.answer}
            label='Jawaban'
            onChange={this.onChengeAnswer}
            disabled={!loaded}
          />
        </Stack>
      </Panel>
    );
  }

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton
          disabled={this.state.submitting}
          text='Cancel'
          onClick={() => this.props.closePanel()}
        />
        <PrimaryButton disabled={this.state.submitting} text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, errorData } = this.state;
    if (
      data?.answer &&
      data?.answer.trim() !== '' &&
      !errorData?.answer &&
      data?.question &&
      data?.question.trim() !== '' &&
      !errorData?.question
    ) {
      this.setState({ submitting: true });
      const fd = new FormData();
      fd.append('answer', data.answer);
      fd.append('question', data.question);
      try {
        await FaqsService.create(fd);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
          submitting: false,
        });
      }
    }
  };

  private onChangeQuestion = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, question: v },
      errorData: { ...this.state.errorData, question: err },
    });
  };

  private onChengeAnswer = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit', 'required'], { maxChars: 1000 }).message;
    this.setState({
      data: { ...this.state.data, answer: v },
      errorData: { ...this.state.errorData, answer: err },
    });
  };
}
