import React from 'react';
import {NavLink} from 'react-router-dom';

// import styles
import styles from './style.module.scss';

// import fabric ui components
import { Breadcrumb, IBreadcrumbItem, Text, Stack } from '@fluentui/react';

interface IBreadCrumbProps {
    items: IBreadcrumbItem[];
}

interface IBreadCrumbState {}

export default class BreadCrumb extends React.Component<IBreadCrumbProps, IBreadCrumbState> {
    private _onRenderItem = (item?:IBreadcrumbItem) => {
        if (item && item.isCurrentItem) {
            return <Text className={`${styles.breadCrumbItem} ${styles.currentItem}`}>{item.text}</Text>
        } else if (item) {
            return <NavLink className={styles.breadCrumbItem} to={item.href || ""}>{item.text}</NavLink>
        } else {return null;}
    }

    public render() {
        let items = this.props.items;
        items[items.length - 1].isCurrentItem = true;

        return (
            <Stack styles={{root: {marginLeft: -10}}}>
                <Breadcrumb
                    styles={{root: {padding: 0}}}
                    items={items}
                    onRenderItem={this._onRenderItem}
                    maxDisplayedItems={4}
                    ariaLabel="Breadcrumb with items rendered as buttons"
                    overflowAriaLabel="More links" />
            </Stack>
        );
    }
}