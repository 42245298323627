import React, { Component } from 'react';

// import ui
import {
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Text,
  IMessageBarProps,
  MessageBar,
} from '@fluentui/react';
import UserService from '../../../../../services/auth/user';
import validationService from '../../../../../services/validation';
import OTPCodeInput from '../component/otpCode';
import { IUserResourceShortProps } from '../../../../../props/user';
import { IMessageBarContentProps, IRenderMessageBarProps } from '../../../../../props/general';

type Props = {
  onDismiss: (user?: IUserResourceShortProps) => void;
  otpId?: string;
};

type State = {
  email: string;
  errorEmail?: string;
  otpId?: string;
  submiting: boolean;
  messageBar?: IMessageBarContentProps;
};

export default class PanelUpdateEmail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      submiting: false,
      otpId: props.otpId,
    };
  }
  render() {
    const { email, errorEmail, otpId, submiting } = this.state;
    return (
      <Panel
        headerText='Ubah Email'
        type={PanelType.medium}
        isOpen
        onDismiss={() => {
          this.props.onDismiss();
        }}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {this.state.messageBar ? (
          <Stack styles={{ root: { marginTop: 20 } }}>
            <MessageBar
              messageBarType={this.state.messageBar.type}
              isMultiline={true}
              onDismiss={() => {
                this.setState({ messageBar: undefined });
              }}
            >
              {this.state.messageBar.content}
            </MessageBar>
          </Stack>
        ) : null}
        {otpId && <OTPCodeInput onSubmit={this.onSubmitOTP} onResendOTP={this.onSubmit} />}
        {!otpId && (
          <TextField
            label='New Email'
            value={email}
            onChange={this.onChangeEmail}
            errorMessage={errorEmail}
            disabled={submiting}
          />
        )}
      </Panel>
    );
  }

  private onSubmitOTP = async (otp: string) => {
    const { email, otpId } = this.state;
    const fd = new FormData();
    if (email.trim() !== '' || this.props.otpId) {
      fd.append('email', email);
      fd.append('otp', otp);
      fd.append('otpId', otpId || '');
      this.setState({ submiting: true });
      try {
        const res = await UserService.updateEmail(fd);
        this.props.onDismiss(res.user);
        this.setState({ submiting: false });
      } catch (error) {
        this.setState({
          messageBar: {
            content: <Text variant={'small'}>{(error as any).message}</Text>,
            type: MessageBarType.error,
          },
        });
        this.setState({ submiting: false });
      }
    }
  };

  private onChangeEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const err = validationService.combination(newValue || '', ['limit', 'required', 'email'], {
      maxChars: 255,
    }).message;
    this.setState({ errorEmail: err, email: newValue || '' });
  };

  private onSubmit = async () => {
    const { email } = this.state;
    const fd = new FormData();
    if (email.trim() !== '') {
      fd.append('email', email);
      this.setState({ submiting: true });
      try {
        const res = await UserService.updateEmail(fd);
        this.setState({ otpId: res.otpId, submiting: false });
        return res;
      } catch (error) {
        this.setState({
          messageBar: {
            content: <Text variant={'small'}>{(error as any).message}</Text>,
            type: MessageBarType.error,
          },
        });
        this.setState({ submiting: false });
      }
    }
  };

  private onRenderFooter = () => {
    const { submiting } = this.state;
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <PrimaryButton text='Submit' onClick={this.onSubmit} disabled={submiting} />
        <DefaultButton
          text='Cancel'
          onClick={() => {
            this.props.onDismiss();
          }}
          disabled={submiting}
        />
      </Stack>
    );
  };
}
