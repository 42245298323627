import Request from './../request';
import { IProductImagesResourceShort } from '../../props/products';

// import interfaces

const ProductImageService = {
  create: async (flavouId: string, data: FormData): Promise<IProductImagesResourceShort[]> => {
    try {
      const results = await Request.post(`/api/products/${flavouId}/images`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (flavouId: string, id: string): Promise<IProductImagesResourceShort> => {
    try {
      const results = await Request.delete(`/api/products/${flavouId}/images/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default ProductImageService;
