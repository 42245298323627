import * as React from 'react';

import {
  DatePicker,
  DefaultButton,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';

// import services
import validationService from '../../../../../services/validation';
import GeneralService from '../../../../../services/general';

// import interfaces
import { IRenderMessageBarProps } from '../../../../../props/general';
import { PermissionKeyType } from '../../../../../props/roles';

// import component
import MessageBarComponent from '../../../../../components/messageBar';
import RolePermissionComponent from '../components/permission';
import RoleServices from '../../../../../services/roles';

// import services

interface IAddRolePanelProps {
  closePanel: (r?: boolean) => void;
}

export type DataInputRoleType = {
  key?: string;
  name?: string;
  description?: string;
};

interface IAddRolePanelState {
  data?: DataInputRoleType;
  errorData?: DataInputRoleType;
  messageBar?: IRenderMessageBarProps;
  permissionKeys: PermissionKeyType[];
}

export default class AddRolePanel extends React.Component<IAddRolePanelProps, IAddRolePanelState> {
  constructor(props: IAddRolePanelProps) {
    super(props);

    this.state = { permissionKeys: [] };
  }

  public render() {
    const { data, errorData, messageBar } = this.state;
    return (
      <Panel
        headerText='Add Role'
        type={PanelType.medium}
        isOpen
        onDismiss={() => this.props.closePanel()}
        onRenderFooterContent={this.onRenderFooter}
        isFooterAtBottom={true}
        closeButtonAriaLabel='Close'
      >
        {messageBar ? <MessageBarComponent {...messageBar} /> : null}
        <TextField label='key' value={data?.key} errorMessage={errorData?.key} onChange={this.onChangeKey} />
        <TextField
          label='Nama'
          value={data?.name}
          errorMessage={errorData?.name}
          onChange={this.onChangeName}
        />
        <TextField
          label='Detail / Catatan Tambahan'
          value={data?.description}
          errorMessage={errorData?.description}
          onChange={this.onChangeDescription}
          multiline
          rows={3}
          resizable={false}
        />
        <RolePermissionComponent
          onUpdate={(permissionKeys: PermissionKeyType[]) => this.setState({ permissionKeys })}
          permissionKeys={[]}
        />
      </Panel>
    );
  }

  private onRenderFooter = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton text='Cancel' onClick={() => this.props.closePanel()} />
        <PrimaryButton text='Submit' onClick={this.onSubmit} />
      </Stack>
    );
  };

  private onSubmit = async () => {
    const { data, errorData, permissionKeys } = this.state;
    if (data?.name && !errorData?.name && data?.key && !errorData?.key) {
      const fd = new FormData();
      try {
        fd.append('name', data.name);
        fd.append('key', data.key);
        data.description && fd.append('description', data.description);
        permissionKeys.map((p) => fd.append('permissionKeys[]', p));
        await RoleServices.create(fd);
        this.props.closePanel(true);
      } catch (error) {
        this.setState({
          messageBar: {
            type: MessageBarType.error,
            text: GeneralService.getErrorMessage(error),
          },
        });
      }
    }
  };

  private onChangeName = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, name: v },
      errorData: { ...this.state.errorData, name: err },
    });
  };

  private onChangeKey = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...this.state.data, key: v },
      errorData: { ...this.state.errorData, key: err },
    });
  };

  private onChangeDescription = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    v: string | undefined
  ) => {
    const err = validationService.combination(v, ['limit'], { maxChars: 2000 }).message;
    this.setState({
      data: { ...this.state.data, description: v },
      errorData: { ...this.state.errorData, description: err },
    });
  };
}
