import { EventCodeType, EventFlavourType, EventType, FileType } from '../../props/events';
import Request from './../request';

// import interfaces

const eventServices = {
  retrieve: async (keyword?: string): Promise<EventType[]> => {
    const queries: string[] = [];
    if (keyword && keyword.trim() !== '') {
      queries.push(`keyword=${keyword}`);
    }
    try {
      const results = await Request.get(`/api/events?${queries || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  getCodes: async (eventId: string): Promise<EventCodeType[]> => {
    try {
      const results = await Request.get(`/api/events/${eventId}/getCodes`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  getFalvours: async (eventId: string): Promise<EventFlavourType[]> => {
    try {
      const results = await Request.get(`/api/events/${eventId}/getFalvours`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<EventType> => {
    try {
      const results = await Request.get(`/api/events/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<EventType[]> => {
    try {
      const results = await Request.post(`/api/events`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, id: string): Promise<EventType[]> => {
    try {
      const results = await Request.post(`/api/events/${id}?_method=PUT`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<EventType[]> => {
    try {
      const results = await Request.delete(`/api/events/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  logos: {
    create: async (eventId: string, data: FormData): Promise<FileType[]> => {
      try {
        const results = await Request.post(`/api/events/${eventId}/logos`, data, 'multipart/form-data');
        return results;
      } catch (e) {
        throw e;
      }
    },
    delete: async (eventId: string, id: string): Promise<FileType> => {
      try {
        const results = await Request.delete(`/api/events/${eventId}/logos/${id}`);
        return results;
      } catch (e) {
        throw e;
      }
    },
  },
  cardTemplates: {
    create: async (eventId: string, data: FormData): Promise<FileType[]> => {
      try {
        const results = await Request.post(
          `/api/events/${eventId}/cardTemplates`,
          data,
          'multipart/form-data'
        );
        return results;
      } catch (e) {
        throw e;
      }
    },
    delete: async (eventId: string, id: string): Promise<FileType> => {
      try {
        const results = await Request.delete(`/api/events/${eventId}/cardTemplates/${id}`);
        return results;
      } catch (e) {
        throw e;
      }
    },
  },
  backgrounds: {
    delete: async (id: string) => {
      try {
        const results = await Request.delete(`/api/backgrounds/${id}`);
        return results;
      } catch (e) {
        throw e;
      }
    },
  },
};

export default eventServices;
