import Request from './../request';
import moment from 'moment';
import Cookies from 'js-cookie';
import { IUserResourceShortProps } from '../../props/user';

// import interfaces

const UserService = {
  login: async (username: string, password: string, remember?: boolean): Promise<any> => {
    try {
      const result = await Request.post(
        '/api/auth/login',
        {
          username,
          password,
        },
        undefined,
        true
      );
      const expires = moment()
        .add(result.token.expires_in - 60, 'seconds')
        .toISOString();
      Cookies.set('token', result.token.access_token, {
        expires: 7,
      });
      Cookies.set('refresh', result.token.refresh_token, {
        expires: 7,
      });
      Cookies.set('expiry', expires, { expires: 7 });
      result.user && Cookies.set('user', { expires: 7 });
      return result;
    } catch (e) {
      throw e;
    }
  },
  logout: async () => {
    try {
      await Request.post('/api/auth/logout');
      Cookies.remove('token');
      Cookies.remove('refresh');
      Cookies.remove('expiry');
      Cookies.remove('user');
      return {};
    } catch (e) {
      throw e;
    }
  },
  currentUser: async (): Promise<IUserResourceShortProps> => {
    try {
      const results = await Request.get(`/api/me`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  retrieve: async (): Promise<IUserResourceShortProps[]> => {
    try {
      const results = await Request.get(`/api/users`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  updateEmail: async (data: FormData): Promise<{ user: IUserResourceShortProps; otpId?: string }> => {
    try {
      const results = await Request.post(`/api/updateEmail`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
  updatePassword: async (data: FormData): Promise<IUserResourceShortProps> => {
    try {
      const results = await Request.post(`/api/updatePassword`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IUserResourceShortProps> => {
    try {
      const results = await Request.post(`/api/users`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
  saveToken: async (username: string, token: string) => {
    const fd = new FormData();
    fd.append('username', username);
    fd.append('token', token);
    try {
      const results = await Request.post(`/api/staffToken`, fd, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default UserService;
