import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Callout, Icon, IconButton, Stack, Text, TextField } from '@fluentui/react';

// import interfaces
import { IFlavourTagResourceShort } from '../../../../../props/flavours';
import ColorOptions from '../../../../../data/colors';

export interface IFlavourTagsProps {
  tags: IFlavourTagResourceShort[];
  onUpdate: (tags: IFlavourTagResourceShort[]) => void;
}
export interface IFlavourTagsState {
  tags: IFlavourTagResourceShort[];
  calloutTarget?: number;
}

export default class FlavourTags extends React.Component<IFlavourTagsProps, IFlavourTagsState> {
  constructor(props: IFlavourTagsProps) {
    super(props);

    this.state = {
      tags: props.tags,
    };
  }

  public render() {
    const { tags, calloutTarget } = this.state;
    return (
      <>
        {calloutTarget !== undefined && this.openCalloutSelectColor(calloutTarget, tags)}
        <Stack tokens={{ childrenGap: 20 }}>
          <IconButton iconProps={{ iconName: 'Add' }} onClick={this.onAddTag} />
          <Stack tokens={{ childrenGap: 15 }}>
            {tags.map((t, i) => (
              <Stack
                styles={{ root: { border: '1px solid black' } }}
                key={t.id}
                horizontal
                verticalAlign='center'
                wrap
                tokens={{ childrenGap: 10 }}
                horizontalAlign='space-between'
              >
                <Stack horizontal verticalAlign='center' wrap tokens={{ childrenGap: 10 }}>
                  <Stack
                    id={`calloutColor${i}`}
                    onClick={() => this.setState({ calloutTarget: i })}
                    styles={{
                      root: {
                        background: t.color,
                        width: 20,
                        height: 20,
                        borderRadius: 50,
                        cursor: 'pointer',
                        marginLeft: 10,
                      },
                    }}
                  />
                  <Stack.Item>
                    <TextField borderless value={t.name} onChange={(e, v) => this.onChangeTagName(i, v)} />
                  </Stack.Item>
                </Stack>
                <Icon
                  iconName='Trash'
                  styles={{ root: { margin: 10, cursor: 'pointer' } }}
                  onClick={() => this.onDeleteTag(t.id)}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
      </>
    );
  }

  private onDeleteTag = (id: string) =>
    this.setState({ tags: this.state.tags.filter((t) => t.id !== id) }, this.onUpdate);

  private onChangeTagName = (ix: number, v?: string) => {
    const { tags, calloutTarget } = this.state;
    if (v && v.length < 255) {
      tags[ix].name = v;
    }
    this.setState({ tags }, this.onUpdate);
  };

  private openCalloutSelectColor = (calloutTarget: number, tags: IFlavourTagResourceShort[]): JSX.Element => (
    <Callout
      styles={{ root: { padding: 10 } }}
      target={`#calloutColor${calloutTarget}`}
      onDismiss={() => this.setState({ calloutTarget: undefined })}
    >
      <Stack horizontal wrap styles={{ root: { maxWidth: 200 } }} tokens={{ childrenGap: 10 }}>
        {ColorOptions.map((color, i) => {
          const selected = tags[calloutTarget].color === color.key;
          return (
            <Stack
              key={i}
              styles={{
                root: {
                  height: 24,
                  width: 24,
                  background: color.key,
                  borderRadius: 100,
                  cursor: 'pointer',
                },
              }}
              horizontalAlign='center'
              verticalAlign='center'
              onClick={() => this.onSelectColor(color.key)}
            >
              {selected && <Icon iconName={'CheckMark'} styles={{ root: { color: '#ffffff' } }} />}
            </Stack>
          );
        })}
      </Stack>
    </Callout>
  );

  private onAddTag = () => {
    const { tags } = this.state;
    tags.push({
      id: uuidv4(),
      color: '#605e5c',
      name: 'Tag',
      flavourId: '',
    });
    this.setState({ tags }, this.onUpdate);
  };

  private onSelectColor = (key: string) => {
    const { tags, calloutTarget } = this.state;
    if (calloutTarget !== undefined) {
      tags[calloutTarget].color = key;
    }
    this.setState({ tags, calloutTarget: undefined }, this.onUpdate);
  };

  private onUpdate = () => {
    const { onUpdate } = this.props;
    const { tags } = this.state;
    onUpdate(tags);
  };
}
