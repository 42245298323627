import React, { Component } from 'react';

// import ui
import {
  CommandBarButton,
  DefaultButton,
  DetailsList,
  Dialog,
  Label,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';
import moment from 'moment';

// import interface
import { IOrderResourceShort } from '../../../../../props/orders';
import DetailsOrderPanel from '../component/details';
import { ProviderStore } from '../../../../../context';
import checkPermission from '../../../../../utils/checkPermission';
import GeneralService from '../../../../../services/general';

import styles from './styles.module.scss';
import TagComponent from '../../../../../components/tag';
import OrderService from '../../../../../services/orders/indes';
import LoadingComponent from '../../../../../components/loading';

type Props = {
  orders: IOrderResourceShort[];
  context: ProviderStore;
  onReload(): void;
};

type State = {
  orders: IOrderResourceShort[];
  panelOrderDetail?: JSX.Element;
  loadingDialogText?: string;
};

export default class ActiveOrder extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orders: props.orders,
    };
  }

  render() {
    const { orders, panelOrderDetail, loadingDialogText } = this.state;
    return (
      <Stack>
        {loadingDialogText ? <Dialog hidden={false} modalProps={{isBlocking: true}}>
          <LoadingComponent label={loadingDialogText || "Harap tunggu ..."} />
        </Dialog> : null}
        {panelOrderDetail}
        <DetailsList selectionMode={SelectionMode.none} items={orders} columns={this.columns()} />
      </Stack>
    );
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return {
      orders: props.orders,
    };
  }

  private updatePaymentStatus = async (id: string, status: string) => {
    let statusText = status === 'pending' ? 'menunggu pembayaran' : 'lunas';
    if (window.confirm("Apakah anda yakin untuk mengubah status pembayaran menjadi " + statusText + "?")) {
      this.setState({loadingDialogText: 'Mengubah status pembayaran menjadi "' + statusText + '"'})
      const fd = new FormData();
      fd.append("paymentStatus", status);
      await OrderService.update(fd, id);
      this.props.onReload();
      this.setState({loadingDialogText: undefined});
    }
  }

  private updateStatus = async (id: string, status: string) => {
    if (window.confirm("Apakah pesanan ini sudah selesai dan diterima oleh pelanggan?")) {
      this.setState({loadingDialogText: 'Menyelesaikan pesanan'});
      const fd = new FormData();
      fd.append("status", status);
      await OrderService.update(fd, id);
      this.props.onReload();
      this.setState({loadingDialogText: undefined});
    }
  }

  private columns = () => {
    return [
      {
        key: 'no',
        name: 'No',
        maxWidth: 25,
        minWidth: 25,
        onRender: (item: IOrderResourceShort, idx: any) => (
          <Stack>
            <Text variant='small'>{(idx || 0) + 1}</Text>
          </Stack>
        ),
      },
      {
        key: 'detailOrder',
        name: 'Detail',
        maxWidth: 200,
        minWidth: 200,
        multiline: true,
        onRender: (item: IOrderResourceShort, idx: any) => {
          let paymentStatus = "Belum Lunas";
          if (item.paymentStatus === 'paid') {
            paymentStatus = 'Lunas';
          }

          let orderStatus = 'Dalam Proses';
          if (item.status === 'completed') {
            orderStatus = 'Selesai';
          }

          return <Stack tokens={{childrenGap: 15}}>
            <Stack tokens={{childrenGap: 4}}>
              <Text variant='mediumPlus' style={{fontWeight: '600'}}>#{item.orderNumber.split('.')[2]}</Text>
              <Stack verticalAlign='center' horizontal tokens={{childrenGap: 3}}>
                <Text variant='small' style={{fontWeight: '600'}}>Status Pembayaran: </Text>
                <TagComponent text={paymentStatus} type={item.paymentStatus === 'pending' ? 'error' : 'success'} />
              </Stack>
              <Stack verticalAlign='center' horizontal tokens={{childrenGap: 3}}>
                <Text variant='small' style={{fontWeight: '600'}}>Status Pesanan: </Text>
                <TagComponent text={orderStatus} type={item.status === 'new' ? 'warning' : 'success'} />
              </Stack>
            </Stack>
            <Stack styles={{root: {borderTop: '1px solid #bbb'}}}> </Stack>
            <Stack tokens={{childrenGap: 10}}>
              {item.paymentStatus === 'pending' ? <DefaultButton text={"Bayar Pesanan"} onClick={() => this.updatePaymentStatus(item.id, 'paid')} /> : null}
              {item.paymentStatus === 'paid' ? <DefaultButton text={"Batalkan Pembayaran"} onClick={() => this.updatePaymentStatus(item.id, 'pending')} /> : null}
              <DefaultButton text={"Selesaikan Pesanan"} onClick={() => this.updateStatus(item.id, 'completed')} />
            </Stack>
          </Stack>
        },
      },
      {
        key: 'order',
        name: 'Pesanan',
        minWidth: 200,
        isMultiline: true,
        onRender: (item: IOrderResourceShort, idx: any) => {
          return <Stack tokens={{ childrenGap: 10 }}>
            <Stack verticalAlign='center' horizontal tokens={{childrenGap: 3}}>
              <Text>Kirim Tanggal: </Text>
              <Text style={{fontWeight: '600'}}>{moment(item.deliveryDate).format("DD/MM/YYYY")}</Text>
            </Stack>
            <Stack tokens={{childrenGap: 20}}>
              {item.products.map((product) => {
                const deliveryDetails = product.deliveryDetails || item.deliveryDetails || undefined;

                return <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.productCard}>
                  <img src={GeneralService.getFileUrl(product.product.images[0]?.url)} className={styles.productImage} />
                  <Stack className={styles.flavourContainer}>
                    <Text variant='large' style={{ fontWeight: 600 }}>{product.product.name}</Text>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                      <Text variant='small' style={{ fontWeight: 600 }}>Packaging:</Text>
                      <Text variant='small'>{product.packaging.name}</Text>
                    </Stack>
                    <Stack>
                      <Text variant='small' style={{ fontWeight: 600 }}>Flavours:</Text>
                      {product.flavours.map((flavour) => {
                        return <Stack horizontal tokens={{ childrenGap: 5 }}>
                          <Text variant='small'>- {flavour.flavour.name}</Text>
                          <Text variant='small' style={{ fontWeight: 600 }}>(x{flavour.quantity})</Text>
                        </Stack>;
                      })}
                    </Stack>
                    {
                      product.toppings && product.toppings.length > 0 ? <Stack>
                        <Text variant='small' style={{ fontWeight: 600 }}>Toppings:</Text>
                        {product.toppings.map((topping) => {
                          return <Stack horizontal tokens={{ childrenGap: 5 }}>
                            <Text variant='small'>- {topping.topping.name}</Text>
                            <Text variant='small' style={{ fontWeight: 600 }}>(x{topping.quantity})</Text>
                          </Stack>;
                        })}
                      </Stack> : null
                    }
                  </Stack>

                  {deliveryDetails ? <Stack className={styles.deliveryDetailsContainer}>
                    <Text variant='large' style={{ fontWeight: 600 }}> </Text>
                    <Text variant='small' style={{ fontWeight: 600 }}>Delivery Details</Text>
                    <Stack wrap horizontal tokens={{ childrenGap: 3 }}>
                      <Text variant='small' style={{ fontWeight: 600 }}>Method:</Text>
                      <Text variant='small'>{deliveryDetails.deliveryMethod.name}{deliveryDetails.deliveryTime ? ' - ' + moment(deliveryDetails.deliveryTime, 'HH:mm').format("HH A") : ''}</Text>
                    </Stack>
                    <Stack wrap horizontal tokens={{ childrenGap: 3 }}>
                      <Text variant='small' style={{ fontWeight: 600 }}>Sender:</Text>
                      <Text variant='small'>{deliveryDetails.senderName} - {deliveryDetails.senderPhone}</Text>
                    </Stack>
                    <Stack wrap horizontal tokens={{ childrenGap: 3 }}>
                      <Text variant='small' style={{ fontWeight: 600 }}>Recipient:</Text>
                      <Text variant='small'>{deliveryDetails.recipientName} - {deliveryDetails.recipientPhone}</Text>
                    </Stack>
                    <Stack wrap horizontal tokens={{ childrenGap: 3 }}>
                      <Text variant='small' style={{ fontWeight: 600 }}>Address:</Text>
                      <Text variant='small'>{deliveryDetails.recipientAddress}</Text>
                    </Stack>
                    <Stack wrap horizontal tokens={{ childrenGap: 3 }}>
                      <Text variant='small' style={{ fontWeight: 600 }}>Gift card?</Text>
                      <Text variant='small'>{deliveryDetails.giftCard ? 'Yes' : 'No'}</Text>
                    </Stack>
                    {deliveryDetails.message ? <Stack wrap horizontal tokens={{ childrenGap: 3 }}>
                      <Text variant='small' style={{ fontWeight: 600 }}>Message:</Text>
                      <Text variant='small'>{deliveryDetails.message}</Text>
                    </Stack> : null}
                  </Stack> : null}
                </Stack>;
              })}
            </Stack>
          </Stack>;
        }
      }
    ];
  };
}
